export class SortHelper {
    // In place of a SortHelper class

    // sortOptions = {
    //     attribute: String, // Used to access the property on the iterable item
    //     sortType: ['alphanumeric', 'date', 'boolean'], // type of sort function to use
    //     sortDirection: ['asc', 'dsc'], // Used to determine which direction sort occurs, not required for 'boolean' sort
    // }
    static sort(items, sortOptions) {
        switch (sortOptions.sortType) {
            case 'alphabetic':
                return alphabeticSort(items, sortOptions)
            case 'numeric':
                return numericSort(items, sortOptions)
            case 'date':
                return dateSort(items, sortOptions)
            case 'boolean':
                return booleanSort(items, sortOptions)
            case 'custom':
                return customSort(items, sortOptions)
            default:
                console.error('Sort Option not found')
                break
        }
    }
}

function alphabeticSort(items, sortOptions) {
    const attr = sortOptions.attribute
    const sortParity = sortOptions.sortDirection === 'dsc' ? -1 : 1
    const sortedItems = items.sort((a, b) => {
        const valueA = a[attr] ? a[attr].toString().toLowerCase() : ''
        const valueB = b[attr] ? b[attr].toString().toLowerCase() : ''
        const trimmedValueA = valueA.startsWith(' ') ? valueA.slice(1) : valueA
        const trimmedValueB = valueB.startsWith(' ') ? valueB.slice(1) : valueB

        return trimmedValueA.localeCompare(trimmedValueB) * sortParity
    })
    return sortedItems
}

function numericSort(items, sortOptions) {
    const attr = sortOptions.attribute
    const sortParity = sortOptions.sortDirection === 'asc' ? 1 : -1
    const sortedItems = items.sort((a, b) => (a[attr] - b[attr]) * sortParity)
    return sortedItems
}

function dateSort(items, sortOptions) {
    const attr = sortOptions.attribute
    const sortParity = sortOptions.sortDirection === 'asc' ? 1 : -1
    const sortedItems = items.sort(
        (a, b) => (new Date(a[attr]) - new Date(b[attr])) * sortParity
    )
    return sortedItems
}

function booleanSort(items, sortOptions) {
    const attr = sortOptions.attribute
    const sortedItems = items.sort((a, b) => a[attr] - b[attr])
    return sortedItems
}

/**
 * Sorts an array of items based on a custom order defined by the `sortKeyArray`.
 */
function customSort(items, sortOptions) {
    const { attribute: attr, sortKeyArray } = sortOptions
    const sortOrderMap = new Map(
        sortKeyArray.map((value, index) => [value, index])
    )
    const sortedItems = items.slice().sort((a, b) => {
        const sortOrderA = sortOrderMap.get(a[attr])
        const sortOrderB = sortOrderMap.get(b[attr])
        return sortOrderA - sortOrderB
    })
    return sortedItems
}
