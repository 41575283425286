export default {
    namespaced: true,
    actions: {
        view: detailView,
        changeOrderStart: changeOrderStart,
        changeOrderComplete: changeOrderComplete
    }
}

function detailView(context, data) {
    window.adobeDataLayer.push({
        event: 'order:view',
        delivery: {
            number: data.delivery.number,
            date: data.delivery.date
        }
    })
}

async function changeOrderStart(context, data){
    await this.dispatch('product/fetchProductAttributes', {
        materialCodes: data.products.map((item) => item.SKU).join(','),
        fields: 'businesses,businessgroups,categories'
    })
    
    for (const product of data.products) {
        product.productCategories =
            context.rootGetters['product/getCategories'][product.SKU]
        product.business =
            context.rootGetters['product/getBusinesses'][product.SKU]
        product.businessGroup =
            context.rootGetters['product/getBusinessGroups'][
                product.SKU
            ]
    }
 
    for(const orderChangeDetail of data.orderChangeDetails){
        orderChangeDetail.oldDeliveryDate = formatDate(orderChangeDetail.oldDeliveryDate)
    }

    window.adobeDataLayer.push({
        event: 'order:changeStart',
        orderChangeDetails: data.orderChangeDetails,
        productListItems: data.products
    })
}

async function changeOrderComplete(context, data){
    await this.dispatch('product/fetchProductAttributes', {
        materialCodes: data.products.map((item) => item.SKU).join(','),
        fields: 'businesses,businessgroups,categories'
    })
 
    for (const product of data.products) {
        product.productCategories =
            context.rootGetters['product/getCategories'][product.SKU]
        product.business =
            context.rootGetters['product/getBusinesses'][product.SKU]
        product.businessGroup =
            context.rootGetters['product/getBusinessGroups'][
                product.SKU
            ]
    }
 
    for(const orderChangeDetail of data.orderChangeDetails){
        orderChangeDetail.oldDeliveryDate = formatDate(orderChangeDetail.oldDeliveryDate)
        orderChangeDetail.newDeliveryDate = formatDate(orderChangeDetail.newDeliveryDate)
    }

    window.adobeDataLayer.push({
        event: 'order:changeComplete',
        orderChangeDetails: data.orderChangeDetails,
        productListItems: data.products
    })
}

function formatDate(input) {
    if (!input) return ''

    const date = new Date(input)
    if (Number.isNaN(date.getTime())) return input

    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString().split('T')[0]
}