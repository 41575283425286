export const MILESTONE_INTERVAL = 25
export const STATUS_UNSTARTED = 'unstarted'
export const STATUS_STARTED = 'started'
export const STATUS_PAUSED = 'paused'
export const STATUS_RESUMED = 'resumed'
export const STATUS_FINISHED = 'finished'

export default {

    data() {
        return {
            started: false,
            ended: false,
            status: STATUS_UNSTARTED,
            milestone: MILESTONE_INTERVAL,
            currentTime: 0,
            duration: undefined
        }
    },

    methods: {
        createEmbed(iFrameSrcProvider, videoId, videoThumbnailModal, cmpEl) {
            const iFrame = document.createElement('iframe')
            const iFrameSrc = iFrameSrcProvider(videoId)

            //add class and attributes to new iframe
            iFrame.classList.add('cmp-embed__embeddable-video-iframe')
            iFrame.setAttribute('allow', 'autoplay')
            iFrame.setAttribute('allowfullscreen', true)
            iFrame.setAttribute('autoplay', true)
            iFrame.setAttribute('frameborder', '0')
            iFrame.addEventListener('load', () => {
                if (typeof this.configureApi == 'function')
                    this.configureApi(iFrame, videoId)
            })

            this.setupListeners(videoThumbnailModal, cmpEl, (modalContent) => {
                iFrame.src = iFrameSrc
                modalContent.appendChild(iFrame)
            },
            () => {
                iFrame.src = ''
            },
            (inlineVideo) => {
                iFrame.src = iFrameSrc
                inlineVideo.appendChild(iFrame)
            })
        },
        setupListeners(videoThumbnailModal, cmpEl, onThumbnailModalClick, onCloseIconClick, onThumbnailInlineClick) {
            const videoThumbnailInline = cmpEl.querySelector('.inline')
            const modal = cmpEl.querySelector('.cmp-embed__modal')
            const closeIcon = cmpEl.querySelector('.cmp-embed__close-icon')
            const modalContent = cmpEl.querySelector(
                '.cmp-embed__modal-content'
            )
            const inlineVideo = cmpEl.querySelector('.inline-iframe')

            if (videoThumbnailModal && modalContent) {
                ;['click', 'keypress'].forEach((event) => {
                    videoThumbnailModal.addEventListener(event, (e) => {
                        if (event == 'click' || e.keyCode == 13) {
                            videoThumbnailModal.classList.add('cmp-embed__modal-hidden')
                            modal.classList.remove('cmp-embed__modal-hidden')
                            onThumbnailModalClick(modalContent)
                        }
                    })
                })
            }

            if (closeIcon && modal) {
                ;['click', 'keypress'].forEach((event) => {
                    closeIcon.addEventListener(event, (e) => {
                        if (event == 'click' || e.keyCode == 13) {
                            modal.classList.add('cmp-embed__modal-hidden')
                            videoThumbnailModal.classList.remove('cmp-embed__modal-hidden')
                            onCloseIconClick()
                        }
                    })
                })
            }

            if (videoThumbnailInline) {
                ;['click', 'keypress'].forEach((event) => {
                    videoThumbnailInline.addEventListener(event, (e) => {
                        if (event == 'click' || e.keyCode == 13) {
                            videoThumbnailInline.classList.add(
                                'cmp-embed__modal-hidden'
                            )
                            onThumbnailInlineClick(inlineVideo)
                        }
                    })
                })
            }
        }
    },

    watch: {
        currentTime(currentTime) {
            if (currentTime && !this.started) this.status = STATUS_STARTED
            const milestone = this.duration * (this.milestone / 100)
            if (currentTime > milestone) {
                this.$store.dispatch('assets/videoMilestone', {
                    cmpId: this.cmpId,
                    milestone: this.milestone
                })

                this.milestone = this.milestone + MILESTONE_INTERVAL
            }
        },
        status(status) {
            if (status === STATUS_STARTED) this.started = true
            if (status === STATUS_FINISHED) this.ended = true

            this.$store.dispatch('assets/videoInteraction', {
                cmpId: this.cmpId,
                status
            })
        }
    }
}
