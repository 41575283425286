import CoreComponent from '../../core/CoreComponent.vue';
import LoginForm from '../../ui/site-login/LoginForm.vue';
import ConfirmRegistration from '../../ui/site-login/ConfirmRegistration.vue';
import store from '@/store';
import { trapFocusToModal } from '@/utils/accessibility';
export default {
  extends: CoreComponent,
  components: {
    LoginForm,
    ConfirmRegistration
  },
  store: store,
  data() {
    return {
      showForm: true,
      userConfirmRegistration: false,
      userConfirmTerms: false,
      loginModalTriggers: document.querySelectorAll('a[href="#dc-login-modal"]'),
      loginModalWindow: document.getElementById('dc-login-modal'),
      loginCloseModal: document.querySelector('#dc-login-modal .dc-modal__close'),
      loginModalContent: document.querySelector('#dc-login-modal .dc-modal__window')
    };
  },
  computed: {
    reauthorizationRequired() {
      return this.$store.state?.user?.reauthorizationRequired;
    },
    termsAccepted() {
      return this.$store.state.user.termsAccepted;
    },
    isModal() {
      return document.getElementById(this.cmpId).dataset.ismodal;
    },
    selectorLogin() {
      return `#${this.cmpId} [data-login-form]`;
    },
    selectorRegistration() {
      return `#${this.cmpId} [data-confirm-registration]`;
    },
    selectorTerms() {
      return `#${this.cmpId} [data-confirm-terms]`;
    },
    isAuthenticated() {
      return this.$store.state?.user?.isAuthenticated;
    },
    isRedirecting() {
      return this.$store.state?.user?.redirecting;
    },
    registrationUrl() {
      //            pass url params to simple registration page
      return `/${this.$store.state.browser.languageCode}${this.$store.state.settings.SIMPLE_REGISTRATION}` + window.location.search;
    }
  },
  methods: {
    openLoginModal(event) {
      event.preventDefault();
      this.loginModalContent.tabIndex = 0;
      this.loginModalWindow.classList.add('active');
      this.loginModalContent.querySelector('input')?.focus();
    },
    closeLoginModal(event) {
      event.preventDefault();
      this.loginModalContent.tabIndex = -1;
      this.loginModalWindow.classList.remove('active');
    },
    setupCreateAccountURL() {
      const urls = document.querySelectorAll('[data-create-account]');
      if (urls.length) {
        urls.forEach(url => {
          url.setAttribute('href', this.registrationUrl);
        });
      }
    },
    trapFocusToModal
  },
  created() {
    this.setupCreateAccountURL();
    if (this.loginModalTriggers.length) {
      this.loginModalTriggers.forEach(element => {
        element.addEventListener('click', this.openLoginModal);
      });
    }
    if (this.loginCloseModal) {
      this.loginCloseModal.addEventListener('click', this.closeLoginModal);
    }
    if (this.loginModalContent) {
      this.loginModalContent.addEventListener('keydown', element => {
        this.trapFocusToModal(element, this.loginModalContent);
      });
    }
  },
  watch: {
    isAuthenticated(newValue, oldValue) {
      if (newValue === true && oldValue === false && this.isModal && !this.redirecting) {
        this.loginModalContent.tabIndex = -1;
        this.loginModalWindow.classList.remove('active');
        // window.location.reload()
      }
    },
    reauthorizationRequired(newValue, oldValue) {
      if (newValue === true && oldValue === false && this.isModal) {
        this.loginModalWindow.classList.remove('active');
      }
    },
    termsAccepted(newValue, oldValue) {
      if (newValue === false && this.isModal) {
        this.loginModalWindow.classList.remove('active');
      }
    }
  }
};