export const TruckIcon = {
    template: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.091" viewBox="0 0 18 15.091">
        <g id="Icon_feather-truck" data-name="Icon feather-truck" transform="translate(1 1)">
          <path id="Path_17" data-name="Path 17" d="M1.5,4.5H12.409v9.455H1.5Z" transform="translate(-1.5 -4.5)" fill="none" stroke="#e80c33" stroke-width="2"/>
          <path id="Path_18" data-name="Path 18" d="M24,12h2.909l2.182,2.182v3.636H24Z" transform="translate(-13.091 -8.364)" fill="none" stroke="#e80c33" stroke-width="2"/>
          <path id="Path_19" data-name="Path 19" d="M8.136,25.818A1.818,1.818,0,1,1,6.318,24a1.818,1.818,0,0,1,1.818,1.818Z" transform="translate(-3.045 -14.545)" fill="none" stroke="#e80c33" stroke-width="2"/>
          <path id="Path_20" data-name="Path 20" d="M27.636,25.818A1.818,1.818,0,1,1,25.818,24,1.818,1.818,0,0,1,27.636,25.818Z" transform="translate(-13.091 -14.545)" fill="none" stroke="#e80c33" stroke-width="2"/>
        </g>
      </svg>
		`
}
