import i18nHelper from './i18n-helper'
export default class AddressTypeHelper {
    static addressTypeDict = {
        WE: 'delivery.address.Title',
        AG: 'company.title',
        ZU: 'addressBuyer.component.forwardingAgent',
        RG: 'payer',
        CRR: 'deliveryDetails.page.carier'
    }

    static returnReceiptAddressTypeDict = {
        WE: 'return.details.page.title.returned.by',
        RG: 'return.details.page.title.credit.to'
    }

    static addressTypeMessage = {
        WE: this.getTypeMessage(
            'delivery.address.Title',
            'addressBuyer.component.deliveryAddressInfo'
        ),
        AG: this.getTypeMessage(
            'company.title',
            'addressBuyer.component.companyInfo'
        ),
        ZU: this.getTypeMessage(
            'addressBuyer.component.forwardingAgent',
            'addressBuyer.component.forwardingAgentInfo'
        ),
        CRR: this.getTypeMessage(
            'deliveryDetails.page.carier',
            'addressBuyer.component.carrierInfo'
        ),
        RG: this.getTypeMessage('payer', 'addressBuyer.component.payerInfo')
    }

    static getTypeMessage(titleKey, messageKey) {
        const titleValue = i18nHelper.getI18nMessage(titleKey)
        const messageValue = i18nHelper.getI18nMessage(messageKey)
        return `<strong>${titleValue}:</strong> ${messageValue}`
    }

    static getAddressTypeTag(addressType) {
        return i18nHelper.getI18nMessage(this.addressTypeDict[addressType])
    }

    static getReturnReceiptAddressTypeTag(addressType) {
        return i18nHelper.getI18nMessage(
            this.returnReceiptAddressTypeDict[addressType]
        )
    }

    static getAddressFromPartnerAddress(address) {
        return {
            addressType: address.addressType,
            partnerNo: address.partnerNo,
            name: address.name1,
            address: address.address1,
            city: address.city, 
            postalDetails: `${address.stateName}, ${address.postalCode}`,
            fullAddress: [
                address.address1,
                address.city,
                address.stateName,
                address.postalCode,
                address.countryName,
                address.country
            ].filter(Boolean).join(', '),
            country: address.countryName,
            countryCode: address.country
        }
    }

    static getCarrierInfo(carrier) {
        return {
            addressType: 'CRR',
            id: carrier.carrierId,
            name: carrier.carrierName,
            trackingNo: carrier.trackingNo,
            trackingUrl: carrier.trackingUrl
        }
    }

    static getAddressFromInternationalAddress(address) {
        return {
            addressType: address.addressType,
            partnerNo: null,
            name: address.companyName,
            address: address.line1,
            postalDetails: address.line2,
            country: address.line3
        }
    }

    static formatAddressInfoMessage(tabs) {
        let addressTypeMessages = []
        tabs.forEach((tab) =>
            addressTypeMessages.push(this.addressTypeMessage[tab.addressType])
        )
        return addressTypeMessages.join(`\n`)
    }

    static sortTabs(tabs) {
        let list = []
        Object.entries(this.addressTypeDict).forEach((key) => {
            var tab = tabs.find((x) => x.addressType === key[0])
            if (tab) {
                list.push(tab)
            }
        })
        return list
    }

    static sortReturnReceiptTabs(tabs) {
        let list = []
        Object.entries(this.returnReceiptAddressTypeDict).forEach((key) => {
            var tab = tabs.find((x) => x.addressType === key[0])
            if (tab) {
                list.push(tab)
            }
        })
        return list
    }
}
