import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const dropdownButton = this.cmpEl.querySelectorAll('.cmp-pdf-download-dropdown__dropdown-button');
    const listItems = this.cmpEl.querySelectorAll('.cmp-pdf-download-dropdown__items');
    const dropdownList = this.cmpEl.querySelectorAll('.dropdown-list');
    const buttonText = this.cmpEl.querySelectorAll('.cmp-pdf-download-dropdown__dropdown-buttonText');
    if (dropdownButton) {
      ;
      ['click', 'keypress'].forEach(event => {
        dropdownButton[0].addEventListener(event, () => {
          if (dropdownList[0].classList.contains('dropdown-list-open')) {
            dropdownList[0].classList.remove('dropdown-list-open');
          } else {
            dropdownList[0].classList.add('dropdown-list-open');
          }
        });
      });
    }
    if (listItems) {
      listItems.forEach(item => {
        item.addEventListener('click', () => {
          dropdownList[0].classList.remove('dropdown-list-open');
          dropdownButton[0].classList.add('cmp-pdf-download-dropdown__dropdown-button-active');
          const itemText = item.innerHTML;
          buttonText[0].innerHTML = '';
          buttonText[0].innerHTML = itemText;
        });
      });
    }
  }
};