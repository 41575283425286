import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Navigation, Keyboard, Thumbs, EffectFade } from 'swiper';
import 'swiper/css';
import Common from '@platform/dow-platform/components/core/embed/embeddable/common';
import Dtv from '@platform/dow-platform/components/core/embed/embeddable/diamondtv/diamondtv.vue';
export default {
  extends: CoreComponent,
  mixins: [Common],
  created() {
    Dtv.created();
  },
  mounted() {
    const videoPlayerThumbs = new Swiper(this.cmpEl.querySelector('.cmp-videoplayer__carousel-thumbs'), {
      spaceBetween: 40,
      slidesPerView: 1.3,
      modules: [Keyboard, Pagination, Navigation],
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      pagination: {
        el: this.cmpEl.querySelector('.cmp-videoplayer__carousel-pagination'),
        clickable: true
      },
      navigation: {
        nextEl: this.cmpEl.querySelector('.cmp-videoplayer__carousel-next'),
        prevEl: this.cmpEl.querySelector('.cmp-videoplayer__carousel-prev')
      },
      breakpoints: {
        640: {
          slidesPerView: 2.6
        },
        1024: {
          slidesPerView: 3.7
        }
      }
    });
    const videoPlayerSlide = new Swiper(this.cmpEl.querySelector('.cmp-videoplayer__carousel-slider'), {
      // effect: 'fade',
      modules: [Keyboard, Thumbs, EffectFade],
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      thumbs: {
        swiper: videoPlayerThumbs
      },
      on: {
        slideChange: () => {
          this.stopPlayingVideos();
        }
      }
    });
    this.cmpEl.querySelectorAll('button.cmp-videoplayer__video-play, img[data-trigger-modal]').forEach(el => this.initPlayer(el));
  },
  methods: {
    initPlayer(el) {
      const videoUrl = el.dataset.playVideo || null;
      const playMode = el.dataset.playMode || 'modal';
      if (!videoUrl) return;
      if (playMode === 'inline') {
        this.initInlinePlayer(el, videoUrl);
      } else {
        this.initModalPlayer(el, videoUrl);
      }
    },
    initInlinePlayer(el, videoUrl) {
      el.addEventListener('click', e => {
        e.preventDefault();
        this.stopPlayingVideos();
        const playerContent = el.parentElement.querySelector('.cmp-videoplayer__placeholder');
        let iframe = playerContent.querySelector('iframe');
        el.style.display = 'none';
        if (!iframe) {
          const poster = playerContent.querySelector('.cmp-videoplayer__poster');
          const youtubeVideo = videoUrl.split('.');
          if (youtubeVideo.includes('youtube')) {
            videoUrl = videoUrl + '&autoplay=1&mute=1';
          }
          console.log("videoUrl", videoUrl);
          console.log("youtubeVideo", youtubeVideo);
          playerContent.classList.add('loading');
          iframe = document.createElement('iframe');
          iframe.classList.add('cmp-videoplayer__iframe');
          iframe.src = videoUrl;
          iframe.addEventListener('load', () => {
            poster.style.display = 'none';
            playerContent.classList.remove('loading');
            Dtv.methods.configureApi.call(this, iframe);
          });
          playerContent.appendChild(iframe);
        }
      });
    },
    initModalPlayer(el, videoUrl) {
      const modal = this.cmpEl.querySelector('.cmp-videoplayer__modal');
      if (!modal) return;
      el.addEventListener('click', e => {
        e.preventDefault();
        const modalContent = modal.querySelector('.cmp-videoplayer__modal-body');
        modalContent.innerHTML = '';
        const iframe = document.createElement('iframe');
        iframe.classList.add('cmp-videoplayer__iframe');
        iframe.src = videoUrl;
        iframe.addEventListener('load', () => {
          modal.classList.remove('loading');
          Dtv.methods.configureApi.call(this, iframe);
        });
        modalContent.appendChild(iframe);
        modal.classList.add('loading');
        modal.classList.remove('cmp-videoplayer__modal--hidden');
        if (!modal.dataset.initialized) {
          const closeButton = modal.querySelector('.cmp-videoplayer__modal-close');
          modal.dataset.initialized = 'true';
          closeButton.addEventListener('click', evt => {
            evt.preventDefault();
            modalContent.innerHTML = '';
            modal.classList.add('cmp-videoplayer__modal--hidden');
          });
        }
      });
    },
    stopPlayingVideos() {
      document.querySelectorAll('iframe.cmp-videoplayer__iframe').forEach(iframe => {
        const playerContent = iframe.parentElement;
        playerContent.querySelector('.cmp-videoplayer__poster').style.display = 'block';
        playerContent.parentNode.querySelector('button.cmp-videoplayer__video-play').style.display = 'flex';
        iframe.remove();
      });
    }
  }
};