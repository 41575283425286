import axios from 'axios'
export default {
    namespaced: true,
    state: {
        loading: false,
        drawerLoading: false
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setDrawerLoading(state, payload) {
            state.drawerLoading = payload
        }
    }
}
