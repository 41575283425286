import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const cards = this.cmpEl.querySelectorAll('.cmp-interactive__card');
    const hotspotButtons = this.cmpEl.querySelectorAll('.cmp-interactive__hotspot');
    const closeIcon = this.cmpEl.querySelectorAll('.cmp-interactive__close-icon');
    if (cards) {
      hotspotButtons.forEach((btn, index) => {
        btn.addEventListener('click', () => {
          cards.forEach(card => {
            card.classList.remove('show-card');
          });
          cards[index].classList.add('show-card');
        });
      });
    }
    if (closeIcon && cards) {
      closeIcon.forEach((btn, index) => {
        btn.addEventListener('click', () => {
          cards[index].classList.remove('show-card');
        });
      });
    }
  }
};