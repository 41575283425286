import CoreComponent from '../../core/CoreComponent.vue';
import ArrowRightLarge from '../../commerce/blocks/svg-elements/arrow-right-large/ArrowRightLarge.vue';
export default {
  extends: CoreComponent,
  data() {
    return {
      showSubNavigationChildren: false,
      sortedCrossLinks: [],
      crossLinkArr: []
    };
  },
  components: {
    ArrowRightLarge
  },
  mounted() {
    this.crossLinkArr = window.crosslinks;
    this.sortedCrossLinks = this.sortCrossLinks();
    if (document.getElementsByClassName('cmp-hero-sub-navigation__crosslinks')) {
      document.getElementsByClassName('cmp-hero-sub-navigation__crosslinks')[0].querySelector('button').addEventListener('click', this.toggleSubNavigation);
    }
    document.addEventListener('click', event => {
      if (this.cmpEl.querySelector('.cmp-hero-sub-navigation__crosslinks-wrapper') && !event.target.closest('.cmp-hero-sub-navigation__crosslinks-wrapper') && !event.target.closest('.cmp-hero-sub-navigation__crosslinks')) {
        this.showSubNavigationChildren = false;
      }
    });
  },
  watch: {
    showSubNavigationChildren(status) {
      if (status) {
        document.getElementsByClassName('cmp-hero-sub-navigation__crosslinks-wrapper')[0].classList.add('cmp-hero-sub-navigation__crosslinks-open');
        this.cmpEl.onkeydown = function (e) {
          const dropDown = document.getElementsByClassName('cmp-hero-sub-navigation__crosslinks-wrapper')[0];
          const listItems = document.getElementsByClassName('listItem');
          let selected = -1;
          for (let i = 0; i < listItems.length; i++) {
            if (listItems[i] === document.activeElement) {
              selected = i;
              break;
            }
          }
          let next = selected + 1;
          let pre = selected - 1;
          if (pre < 0) {
            pre = 0;
          }
          if (next >= listItems.length) {
            next = listItems.length - 1;
          }
          if (e.code === 'ArrowUp') {
            listItems[pre].focus();
          }
          if (e.code === 'ArrowDown') {
            if (selected < 2) {
              e.preventDefault();
              listItems[next].focus();
            }
            listItems[next].focus();
          }
          if (e.code === 'Tab') {
            dropDown.classList.remove('cmp-hero-sub-navigation__crosslinks-open');
          }
          if (e.code === 'Escape') {
            dropDown.classList.remove('cmp-hero-sub-navigation__crosslinks-open');
          }
          document.activeElement = e.target == document ? null : e.target;
          e.preventDefault();
        };
        return;
      } else {
        document.getElementsByClassName('cmp-hero-sub-navigation__crosslinks-wrapper')[0].classList.remove('cmp-hero-sub-navigation__crosslinks-open');
      }
    }
  },
  methods: {
    toggleSubNavigation() {
      this.showSubNavigationChildren = !this.showSubNavigationChildren;
    },
    sortCrossLinks() {
      return this.crossLinkArr.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      }).map(element => {
        let link = element.url;
        let parts = link.split('/');
        if (parts.length > 5 && parts[1] === 'content' && parts[2] === 'dc' && parts[5] === "category") {
          let country = parts[3];
          let language = parts[4];
          let locale = language + '-' + country;
          link = '/' + locale + '/category' + link.split('category')[1];
        }
        return {
          ...element,
          url: link
        };
      });
    }
  }
};