import { getCookiebyName, removeBrowserCookie } from './cookies'

export function getEloquaParameters(queryString) {
    var paramValues = queryString.split('&')
    var elqFields = []

    for (var i = 0; i < paramValues.length; i++) {
        var paramName = paramValues[i].split('=')

        if (paramName[0] == 'cid') {
            var elqField = {
                name: 'refUrl',
                value: paramName[1]
            }
            var cidValues = paramName[1].split(':')
            var elqField1 = {
                name: 'cid',
                value: cidValues[2]
            }
            var elqField2 = {
                name: 'crmid',
                value: cidValues[13]
            }
            var elqField3 = {
                name: 'channel',
                value: cidValues[0]
            }
            var elqField4 = {
                name: 'source',
                value: cidValues[1]
            }
            elqFields.push(elqField)
            elqFields.push(elqField1)
            elqFields.push(elqField2)
            elqFields.push(elqField3)
            elqFields.push(elqField4)
        }
    }

    return elqFields
}

export function getCIDFields(fromCrop=false) {
    let elqFields, refUrl, cid, crmid, source, channel, queryString
    if (fromCrop){
        queryString = getCookiebyName('cropElqQueryString')
        removeBrowserCookie('cropElqQueryString')
    } else {
        queryString = getCookiebyName('elqQueryString')
    }
    if (queryString != null && queryString != '') {
        elqFields = getEloquaParameters(queryString)
        if (elqFields != null) {
            for (let i = 0; i < elqFields.length; i++) {
                switch (elqFields[i].name) {
                    case 'refUrl':
                        refUrl = elqFields[i].value
                        break
                    case 'cid':
                        cid = elqFields[i].value
                        break
                    case 'crmid':
                        crmid = elqFields[i].value
                        break
                    case 'source':
                        source = elqFields[i].value
                        break
                    case 'channel':
                        channel = elqFields[i].value
                        break
                    default:
                        console.log(
                            'Error retrieving eloqua value: ' +
                                elqFields[i].name
                        )
                }
            }
        }
    }

    return { refUrl, cid, crmid, source, channel }
}
