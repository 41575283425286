import CoreComponent from '../CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const wcmode = this.cmpEl.getAttribute('data-wcm-mode');
    if (wcmode === 'EDIT') {
      return;
    }
    const singleExpansion = this.cmpEl.hasAttribute('data-cmp-single-expansion');
    const accordion = this.cmpEl;
    var observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // Check for the specific changes you are interested in
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (mutation.target.classList.contains('cmp-accordion__panel--hidden')) {
            const panel = mutation.target;
            panel.setAttribute('hidden', 'until-found');
          } else if (mutation.target.classList.contains('cmp-accordion__panel--expanded')) {
            const panel = mutation.target;
            if (panel.getAttribute('hidden') === 'until-found') {
              panel.removeAttribute('hidden');
              if (singleExpansion) {
                accordion.querySelectorAll('.cmp-accordion__panel--expanded').forEach(expandedPanel => {
                  if (expandedPanel !== panel) {
                    // Operations on other panels
                    expandedPanel.classList.add('cmp-accordion__panel--hidden');
                    expandedPanel.classList.remove('cmp-accordion__panel--expanded');
                    expandedPanel.setAttribute('hidden', 'until-found');
                    // Operations on other buttons
                    expandedPanel.previousElementSibling.querySelector('.cmp-accordion__button').classList.remove('cmp-accordion__button--expanded');
                    expandedPanel.previousElementSibling.querySelector('.cmp-accordion__button').setAttribute('aria-expanded', 'false');
                    // Operations on other panel and button containers
                    expandedPanel.parentNode.setAttribute('data-cmp-expanded', false);
                  }
                });
              }
            }
          }
        }
      });
    });

    // Configuration of the observer
    var config = {
      attributes: true,
      childList: true,
      subtree: true
    };

    // Start observing the target node
    observer.observe(this.cmpEl, config);
    this.cmpEl.querySelectorAll('.cmp-accordion__panel').forEach(panel => {
      panel.onbeforematch = () => {
        // Operations on panel element
        panel.classList.remove('cmp-accordion__panel--hidden');
        panel.classList.add('cmp-accordion__panel--expanded');
        panel.setAttribute('aria-hidden', 'false');

        // Operations on button element
        panel.previousElementSibling.querySelector('.cmp-accordion__button').classList.add('cmp-accordion__button--expanded');
        panel.previousElementSibling.querySelector('.cmp-accordion__button').setAttribute('aria-expanded', 'true');

        // Operations on panel and button container
        panel.parentNode.setAttribute('data-cmp-expanded', true);

        // Operations on entire accordion
        panel.parentNode.parentNode.setAttribute('data-cmp-expanded', true);
      };
    });
  }
};