import CoreComponentVue from '../CoreComponent.vue';
export default {
  extends: CoreComponentVue,
  mounted() {
    const lastItem = this.cmpEl.querySelector('.cmp-breadcrumb__list > li:last-child');
    const lastCarrot = this.cmpEl.querySelector('.cmp-breadcrumb__list > li:last-child .cmp-breadcrumb__item-icon');
    if (lastItem) {
      lastItem.style.fontWeight = 'bold';
    }
    if (lastCarrot) {
      lastCarrot.style.display = 'none';
    }
    const lastItemTitle = lastItem.querySelector('span[itemprop="name"]');
    const title = this.cmpEl.getAttribute('data-title');
    const navTitle = this.cmpEl.getAttribute('data-nav-title');
    if (lastItemTitle) {
      if (navTitle) {
        lastItemTitle.textContent = navTitle;
      } else {
        lastItemTitle.textContent = title;
      }
    }
  }
};