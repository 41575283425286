import FilterDefaultValueHelper from './filter-default-value-helper'
import { UserHelper } from '../../../../../../helpers/user-helper'

export default class CaseFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            dateRange: super.getDateRange(90),
            addresses: super.getDefaultAddress(),
            contactEmail: this.getEmailValue()
        }
    }

    static getEmailValue() {
        return !UserHelper.isInternalUser()
            ? UserHelper.getUserData().email
            : null
    }
}
