// import { Template } from 'webpack'
import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    this.addCheckboxes();
    this.addLinkArrows();
  },
  methods: {
    addCheckboxes() {
      var tableRows = this.cmpEl.querySelectorAll('tr td:first-child');
      tableRows.forEach(function (element) {
        element.classList.add('check-flex');
        const checkbox = document.createElement(`input`);
        checkbox.type = `checkbox`;
        const checkboxContainer = document.createElement('div');
        checkboxContainer.className = 'checkbox_container';
        checkboxContainer.appendChild(checkbox);
        element.insertBefore(checkboxContainer, element.firstChild);
      });
    },
    addLinkArrows() {
      if (this.cmpEl.parentElement.classList.contains('cmp-table-link')) {
        var links = this.cmpEl.querySelectorAll('a');
        links.forEach(function (element) {
          const div = document.createElement(`div`);
          div.className = 'arrow_container';
          div.innerHTML = `<svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.29288 11.7071L7.99998 6.00001L2.29288 0.292908L0.878662 1.70712L5.17156 6.00001L0.878662 10.2929L2.29288 11.7071Z"
                        fill="#E80033"
                    />
                </svg>`;
          element.appendChild(div);
        });
      }
    }
  }
};