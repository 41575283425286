import CoreComponent from '../../../CoreComponent.vue';
import { default as Common, STATUS_STARTED, STATUS_FINISHED, STATUS_RESUMED, STATUS_PAUSED } from '../common.js';
let apiReady = new Promise((resolve, reject) => {
  if (window.YT) {
    resolve(window.YT);
  }
  window.onYouTubeIframeAPIReady = function () {
    resolve(window.YT);
  };
  window.onYouTubeIframeAPIFailure = function () {
    reject('YouTube API Failed to Load');
  };
});
export default {
  extends: CoreComponent,
  mixins: [Common],
  data() {
    return {
      player: undefined
    };
  },
  mounted() {
    //get the id of youtube video
    const compData = JSON.parse(this.cmpDataLayer);
    const videoId = compData.embeddableProperties.youtubeVideoId;

    //create iframe
    const videoThumbnailModal = document.getElementById(videoId);
    const container = document.createElement('div');
    container.id = `${this.cmpId}-player`;
    this.setupListeners(videoThumbnailModal, this.cmpEl, modalContent => {
      container.style.height = '100%';
      container.style.width = '100%';
      modalContent.appendChild(container);
      this.configureApi(container, videoId);
    }, () => {
      this.player.destroy();
      window.removeEventListener('message', this.updateCurrentTime);
    }, inlineVideo => {
      inlineVideo.appendChild(container);
      this.configureApi(container, videoId);
    });
  },
  created() {
    if (!window.YT) {
      this.$store.dispatch('browser/script', 'https://www.youtube.com/iframe_api').catch(window.onYouTubeIframeAPIFailure);
    }
  },
  methods: {
    configureApi(container, videoId) {
      if (videoId.indexOf('=') >= 0) {
        videoId = videoId.split('=')[1];
      }
      const initPlayer = YT => {
        const host = this.$store.getters['user/acceptedPerformanceCookies'] ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com';
        const player = new YT.Player(container.id, {
          videoId,
          host,
          playerVars: {
            autoplay: 1,
            enablejsapi: 1
          },
          events: {
            onReady: () => {
              this.cmpUpdate({
                embeddableProperties: {
                  videoTitle: player.getVideoData().title,
                  videoURL: player.getVideoUrl()
                }
              });
            },
            onStateChange: ({
              data: status
            }) => {
              status = this.started && status === YT.PlayerState.PLAYING && STATUS_RESUMED || status === YT.PlayerState.PLAYING && STATUS_STARTED || status === YT.PlayerState.ENDED && STATUS_FINISHED || status === YT.PlayerState.PAUSED && STATUS_PAUSED;
              if (status) this.status = status;
            }
          }
        });
        this.player = player;
        window.addEventListener('message', this.updateCurrentTime);
      };
      if (window.YT) {
        initPlayer(window.YT);
      } else {
        apiReady.then(YT => initPlayer(YT));
      }
    },
    updateCurrentTime(event) {
      if (event.source === this.player.getIframe().contentWindow) {
        const data = JSON.parse(event.data);
        if (data.event === 'infoDelivery' && data.info?.currentTime) {
          this.currentTime = data.info.currentTime;
          this.duration = this.duration || data.info.duration;
        }
      }
    }
  }
};