import CoreComponent from '../../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const ctaButton = document.querySelector('.cmp-microsite-hero__cta > button');
    if (ctaButton) {
      const firstComponentAfterHero = document.querySelector('.micrositeHero + *');
      if (firstComponentAfterHero) {
        ctaButton.addEventListener('click', () => {
          const y = firstComponentAfterHero.getBoundingClientRect().top + window.scrollY;
          window.scroll({
            top: y,
            behavior: 'smooth'
          });
        });
      }
    }
  }
};