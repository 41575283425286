import CoreComponent from '../../CoreComponent.vue';
import { default as Common, STATUS_STARTED, STATUS_FINISHED, STATUS_RESUMED, STATUS_PAUSED } from '../embeddable/common.js';
let apiReady = new Promise((resolve, reject) => {
  window.onYouTubeIframeAPIReady = function () {
    resolve(window.YT);
  };
  window.onYouTubeIframeAPIFailure = function () {
    reject('YouTube API Failed to Load');
  };
});
export default {
  extends: CoreComponent,
  mixins: [Common],
  data() {
    return {
      player: undefined
    };
  },
  mounted() {
    const compData = JSON.parse(this.cmpDataLayer);
    const videoId = compData.embeddableProperties.youtubeVideoId;
    const container = this.cmpEl.querySelector('iframe');
    container.id = `${this.cmpId}-player`;
    this.configureApi(videoId);
  },
  created() {
    this.$store.dispatch('browser/script', 'https://www.youtube.com/iframe_api').catch(window.onYouTubeIframeAPIFailure);
  },
  beforeUnmount() {
    this.player.destroy();
    window.removeEventListener('message', this.updateCurrentTime);
  },
  methods: {
    configureApi(videoId) {
      if (videoId.indexOf('=') >= 0) {
        videoId = videoId.split('=')[1];
      }
      apiReady.then(YT => {
        const host = this.$store.getters['user/acceptedPerformanceCookies'] ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com';
        const player = new YT.Player(this.cmpId, {
          videoId,
          host,
          playerVars: {
            enablejsapi: 1
          },
          events: {
            onReady: () => {
              this.cmpUpdate({
                embeddableProperties: {
                  videoTitle: player.getVideoData().title,
                  videoURL: player.getVideoUrl()
                }
              });
            },
            onStateChange: ({
              data: status
            }) => {
              status = this.started && status === YT.PlayerState.PLAYING && STATUS_RESUMED || status === YT.PlayerState.PLAYING && STATUS_STARTED || status === YT.PlayerState.ENDED && STATUS_FINISHED || status === YT.PlayerState.PAUSED && STATUS_PAUSED;
              if (status) this.status = status;
            }
          }
        });
        this.player = player;
        window.addEventListener('message', this.updateCurrentTime);
      });
    },
    updateCurrentTime(event) {
      if (event.source === this.player.getIframe().contentWindow) {
        const data = JSON.parse(event.data);
        if (data.event === 'infoDelivery' && data.info?.currentTime) {
          this.currentTime = data.info.currentTime;
          this.duration = this.duration || data.info.duration;
        }
      }
    }
  }
};