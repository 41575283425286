export const LoadingDots = {
    name: 'LoadingDots',
    template: `
        <div class="component-loader">
            <div class="loader-child loader-bounce1"></div>
            <div class="loader-child loader-bounce2"></div>
            <div class="loader-child loader-bounce3"></div>
        </div>
        `
}
