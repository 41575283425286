import "core-js/modules/es.array.push.js";
export default {
  props: {
    value: {
      type: String
    },
    modelValue: {
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      } else if (typeof this.modelValue == 'string') {
        return this.modelValue == this.value;
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
    isCheckBox() {
      if (typeof this.modelValue == 'string') {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('update:modelValue', newValue);
      } else if (typeof this.modelValue == 'string') {
        let newValue = this.modelValue;
        if (isChecked) {
          newValue = this.value;
        } else {
          newValue = '';
        }
        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue);
      }
    },
    onEnter(event) {
      event.preventDefault();
      var input = event.target.previousSibling;
      if (input) {
        let isChecked = !input.checked;
        if (this.modelValue instanceof Array) {
          let newValue = [...this.modelValue];
          if (isChecked) {
            newValue.push(input.value);
          } else {
            newValue.splice(newValue.indexOf(input.value), 1);
          }
          this.$emit('update:modelValue', newValue);
        } else if (typeof this.modelValue == 'string') {
          let newValue = this.modelValue;
          if (isChecked) {
            newValue = input.value;
          } else {
            newValue = '';
          }
          this.$emit('update:modelValue', newValue);
        } else {
          this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue);
        }
      }
    }
  }
};