import i18nHelper from '@/dow-platform/components/commerce/myAccount/helper/i18n-helper';
import { mapState } from 'vuex';
const sampleNumber = document.querySelectorAll('.cmp--header_cart-modal_cart-quantity');
const buyNumber = document.querySelectorAll('.cmp--header_cart-modal_cart_buy');
export default {
  components: {},
  data() {
    return {
      isButtonHovered: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    dccUserInfoTest() {
      return this.$store.state.user;
    }
  },
  computed: {
    purchaseCartText() {
      return i18nHelper.getI18nMessage('purchase.cart');
    },
    checkOutText() {
      return i18nHelper.getI18nMessage('check.out');
    },
    sampleCartText() {
      return i18nHelper.getI18nMessage('sample.cart.title');
    },
    completeRequestText() {
      return i18nHelper.getI18nMessage('view.cart');
    },
    isBuyingUser() {
      return this.dccUserInfo?.data?.baseRole?.includes('addtocart') ?? false;
    },
    cartUrl() {
      return this.isBuyingUser ? '/dccstorefront/cart' : 'javascript:;';
    },
    purchaseQuantity() {
      return this.dccUserInfo?.data?.numberOfCartItems;
    },
    sampleQuantity() {
      return this.isAuthenticated ? this.dccUserInfo?.data?.numberOfSampleCartItems : this.anonSampleCartCount;
    },
    totalQuantity() {
      return this.purchaseQuantity + this.sampleQuantity;
    },
    sampleCartLink() {
      return '/' + this.languageCode + '/sample-cart.html';
    },
    hasSampleAdded() {
      return this.sampleQuantity > 0;
    },
    hasPurchaseItems() {
      return this.purchaseQuantity > 0;
    },
    languageCode() {
      return this.$store.state.browser.cookies['languageCode'] || 'en-us';
    },
    ...mapState('user', ['dccUserInfo', 'anonSampleCartCount', 'isAuthenticated'])
  }
};