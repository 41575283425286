import { getCookiebyName } from './cookies'
import ApiClient from '@platform/plugins/AxiosPlugin'

export function hashCode(strIn) {
    var hash = 0,
        i,
        chr
    if (strIn.length === 0) return hash
    for (i = 0; i < strIn.length; i++) {
        chr = strIn.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export function makeUniqueId(name, length) {
    let result = ''
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
        counter += 1
    }
    return `${name}-${result}`
}

export function isMobile() {
    return matchMedia('(max-width:767px)').matches ? true : false
}

export function isTablet() {
    return matchMedia('(min-width:768px) and (max-width: 1024px)').matches
        ? true
        : false
}

export function isDesktop() {
    return matchMedia('(min-width: 1140px)').matches ? true : false
}

export function getParameter(param) {
    let url = window.location.search.substring(1),
        paramValues = url.split('&'),
        paramName,
        i

    for (i = 0; i < paramValues.length; i++) {
        paramName = paramValues[i].split('=')

        if (paramName[0] === param && param === 'q') {
            return paramName[1] === undefined
                ? true
                : decodeURIComponent(paramName[1].replace(/\+/g, ' '))
        }
        if (paramName[0] === param) {
            return paramName[1] === undefined
                ? true
                : decodeURIComponent(paramName[1])
        }
    }

    return ''
}

export function findObj(obj, propName, propValue) {
    if (!$.isEmptyObject(obj)) {
        for (var i = 0; i < obj.length; i++) {
            if (obj[i][propName] == propValue) {
                return obj[i]
            }
        }
    }
    return false
}

window.refresh_hsession = function refresh_Hsession() {
    console.log('authentication-status : logged in')
    $.ajax({
        url: '/dccstorefront/dcc/en/_s/refresh',
        type: 'GET',
        success: function (res, status, xhr) {
            console.log(res)
            if (xhr.getResponseHeader('sessionTimeout')) {
                var locale = getCookiebyName('languageCode') || 'en-us'
                ApiClient.post('/.dow.commerce.logout.json', new FormData(), {
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                })
                    .then(function (res) {
                        localStorage.setItem('logout', 'true')
                        window.location.href = '/' + locale + '.html'
                    })
                    .catch(function (res) {
                        console.log('Error logging out')
                    })
            } else {
                console.log('refresh hybris session success')
            }
        },
        error: function (res) {
            console.log('refresh hybris session failure')
        }
    })
}

export function hsession_interval() {
    var intervaltime = 3 * 60 * 1000
    setInterval(window.refresh_hsession, intervaltime)
    window.refresh_hsession()
}

window.refresh_session = function refresh_Session() {
    console.log('Session Updated')
    const userInfoUrl = `/.dow.dcc.user.info.json`
    ApiClient.get(userInfoUrl)
}

window.refresh_jsession = function jsession_interval() {
    var intervaltime = 5 * 60 * 1000
    setInterval(window.refresh_session, intervaltime)
    window.refresh_session()
}
