import FilterDefaultValueHelper from './filter-default-value-helper'

export default class MyPartNumbersFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            addresses: super.getDefaultAddress()
        }
    }

    static filterSearchOption(retArr, filterValues) {
        if (filterValues.attribute == 'materialName') {
            return retArr.filter(
                (el) =>
                    el['dowMaterialName']
                        ?.toLowerCase()
                        .includes(filterValues.value.toLowerCase())||
                        (el['myDescription']
                            ? el['myDescription']
                                  .toLowerCase()
                                  .includes(filterValues.value.toLowerCase())
                            : false)
            )
        } else if (filterValues.attribute == 'materialNumber') {
            return retArr.filter(
                (el) => el['dowMaterialNumber'].includes(filterValues.value)||
                (el['myPartNumber']
                    ? el['myPartNumber']
                          .toLowerCase()
                          .includes(filterValues.value.toLowerCase())
                    : false)
            )
        } else return null
    }
}
