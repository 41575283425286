import { makeUniqueId } from '@/utils';
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: null,
    value: {
      type: [String, Boolean]
    },
    checked: {
      type: [String, Boolean]
    },
    helpText: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: new Array()
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  computed: {
    randomId() {
      return makeUniqueId(this.name, 6);
    }
  }
};