import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Navigation, Keyboard } from 'swiper';
import 'swiper/css';
import { trackTabView } from '../ACDLHandler';
export default {
  extends: CoreComponent,
  mounted() {
    // Scrollable tab list
    this.initializeTabListScroll();
    window.addEventListener('resize', this.initializeTabListScroll);
    const wcmode = this.cmpEl.getAttribute('data-wcm-mode');
    if (wcmode === 'EDIT') {
      return;
    }
    this.cmpEl.querySelectorAll('.cmp-tabs__tab').forEach(tab => {
      tab.addEventListener('click', () => {
        this.toggleTabVisibility(tab.querySelector('.cmp-tabs__tab-button'));
      });
    });
    this.cmpEl.querySelectorAll('.cmp-tabs__tabpanel').forEach(tabPanel => {
      tabPanel.onbeforematch = () => {
        const correspondingTab = this.cmpEl.querySelector(`[aria-controls="${tabPanel.id}"]`);
        this.toggleTabVisibility(correspondingTab.querySelector('.cmp-tabs__tab-button'));
      };
    });
    this.setActiveByHash();
    const outhisdeThis = this;
    this.cmpEl.onkeyup = function (e) {
      if (e.code === 'Tab') {
        if (document.activeElement.classList.contains('cmp-tabs__tab-button')) {
          window.location.hash = document.activeElement.closest('.cmp-tabs__tab').id;
          outhisdeThis.toggleTabVisibility(document.activeElement);
          setTimeout(() => {
            document.activeElement.closest('.cmp-tabs__tab').querySelector('.cmp-tabs__tab-button').focus();
          }, 0);
          outhisdeThis.cmpEl.querySelectorAll('.cmp-tabs__tabpanel').forEach(panel => {
            panel.setAttribute('tabindex', '-1');
          });
          outhisdeThis.cmpEl.querySelector('.cmp-tabs__tabpanel--active').setAttribute('tabindex', '0');
        }
      }
      if (e.code === 'ArrowRight' || e.code === 'ArrowLeft') {
        if (document.activeElement.closest('.cmp-tabs__tab') && !document.activeElement.classList.contains('cmp-tabs__tab-button')) {
          outhisdeThis.toggleTabVisibility(document.activeElement.querySelector('.cmp-tabs__tab-button'));
          document.activeElement.querySelector('.cmp-tabs__tab-button').focus();
        }
      }
      if (e.code === 'Enter') {
        if (document.activeElement.classList.contains('cmp-tabs__tab--active')) {
          outhisdeThis.focusActiveContainer(e, document.activeElement);
        }
      }
    };
    // check non support for onbeforematch
    if (!('onbeforematch' in document.body)) {
      document.body.classList.add('non-support-onbeforematch');
    }
  },
  methods: {
    toggleTabVisibility(clickedTabButton) {
      this.cmpEl.querySelectorAll('.cmp-tabs__tab').forEach(tab => {
        tab.classList.remove('cmp-tabs__tab--active');
        tab.setAttribute('aria-selected', 'false');
      });
      this.cmpEl.querySelectorAll('.cmp-tabs__tabpanel').forEach(panel => {
        panel.classList.remove('cmp-tabs__tabpanel--active');
        panel.setAttribute('aria-hidden', 'true');
      });
      const parentTab = clickedTabButton.closest('.cmp-tabs__tab');
      const controlledPanelId = parentTab.getAttribute('aria-controls');
      const controlledPanel = this.cmpEl.querySelector(`#${controlledPanelId}`);
      parentTab.classList.add('cmp-tabs__tab--active');
      parentTab.setAttribute('aria-selected', 'true');
      if (controlledPanel) {
        controlledPanel.classList.add('cmp-tabs__tabpanel--active');
        controlledPanel.removeAttribute('hidden');
        controlledPanel.setAttribute('aria-hidden', 'false');
      }
    },
    focusActiveContainer(e, clickedTabButton) {
      const parentTab = clickedTabButton.closest('.cmp-tabs__tab');
      const controlledPanelId = parentTab.getAttribute('aria-controls');
      const controlledPanel = this.cmpEl.querySelector(`#${controlledPanelId}`);
      if (controlledPanel) {
        const focusableSelector = 'button:not([disabled]), input, select, textarea, a, [tabindex]:not([tabindex="-1"])';
        let focusableContent = controlledPanel.querySelectorAll(focusableSelector);
        if (focusableContent[0]) {
          let firstFocusableElement = focusableContent[0];
          firstFocusableElement.focus();
        }
      }
    },
    setActiveByHash() {
      const hash = window.location.hash;
      if (hash) {
        const tabToActivate = this.cmpEl.querySelector(`[aria-controls="${hash.substring(1)}panel"]`);
        if (tabToActivate) {
          this.toggleTabVisibility(tabToActivate.querySelector('.cmp-tabs__tab-button'));
          this.cmpUpdate({
            shownItems: [tabToActivate.id.replace(/\-tab$/, '')]
          });
          return;
        }
      }
      const defaultActiveTab = this.cmpEl.querySelector('.cmp-tabs__tab--active .cmp-tabs__tab-button');
      if (defaultActiveTab) {
        this.toggleTabVisibility(defaultActiveTab);
      }
    },
    initializeTabListScroll() {
      const tabListScrollable = this.cmpEl.querySelector('.cmp-tabs__tablist-scrollable');
      const tabListWrapper = this.cmpEl.querySelector('.cmp-tabs__tablist-wrapper');
      const nextArrow = this.cmpEl.querySelector('.cmp-tabs__tab-arrow-next');
      const prevArrow = this.cmpEl.querySelector('.cmp-tabs__tab-arrow-prev');
      if (tabListWrapper && tabListScrollable && tabListWrapper.offsetWidth > tabListScrollable.offsetWidth) {
        new Swiper(tabListScrollable, {
          wrapperClass: 'cmp-tabs__tablist-wrapper',
          slideClass: 'cmp-tabs__tab',
          slidesPerView: 'auto',
          spaceBetween: 0,
          modules: [Navigation, Keyboard],
          navigation: {
            nextEl: nextArrow,
            prevEl: prevArrow
          },
          keyboard: {
            enabled: true
          }
        });
      }
    },
    trackTabView
  }
};