import FilterDefaultValueHelper from './filter-default-value-helper'

export default class InvoicesFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            dateRange: super.getDateRange(30),
            payerAddress: super.getDefaultPayerAddressId()
        }
    }
}
