const storeMaker = (state) => {
    Object.keys(state).map((key) => {
      // 判断类型获取本地存储数据
      if (typeof state[key] === 'number') {
        if (sessionStorage.getItem(key) && parseInt(sessionStorage.getItem(key))) {
          state[key] = parseInt(sessionStorage.getItem(key))
        }
      } else {
        if (sessionStorage.getItem(key)) {
          state[key] = sessionStorage.getItem(key)
        }
      }
    })
  
    // 重写set处理
    if(typeof window.Proxy == "function"){
      return new Proxy(state, {
        set: function (target, key, value) {
          let temp = value
          if (typeof temp === 'object') {
            temp = JSON.stringify(temp)
          }
          sessionStorage.setItem(key, temp)
          return Reflect.set(target, key, value)
        }
      })
    }
  }
  module.exports = {
    namespaced: true,
    state: storeMaker({
      allStartData: [],
      startData: {},
      subMarketData: {},
      applicationData:{}
    }),
    mutations: {
      setAllStartData: function (state, payload) {
        state.allStartData = payload;
      },
      setStartData: function (state, payload) {
        state.startData = payload;
      },
      setSubMarketData: function (state, payload) {
        state.subMarketData = payload;
      },
      setApplicationData: function (state, payload) {
        state.applicationData = payload;
      }
    },
    actions: {
      fetchAllStartData(context, payload) {
        fetchAllStartData(context, payload);
      }
    },
    getters: {
      getAllStartData: state => state.allStartData,
      getStartData: state => state.startData,
      getSubMarketData: state => state.subMarketData,
      getApplicationData: state => state.applicationData
    }
  }
  function fetchAllStartData(context, payload) {
    context.dispatch('beautyCare/setAllStartData', payload)
  }
  