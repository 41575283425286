import CoreComponent from '../../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data: function () {
    return {
      numInit: 4,
      numTotal: -1,
      fullLoaded: false,
      el: {
        items: null,
        button: {
          main: null,
          initial: null,
          secondary: null
        },
        toggle: null,
        nav: null,
        numShown: null,
        numTotal: null
      }
    };
  },
  methods: {
    setElements,
    onButtonClick,
    componentReady,
    showAll,
    showLess
  },
  created() {
    this.setElements();
    this.onButtonClick();
    this.showLess();
    this.componentReady();
  }
};
function setElements() {
  this.el = {
    items: this.cmpEl.querySelectorAll('.cmp-navigation__item'),
    button: {
      main: this.cmpEl.querySelector('.heroSubPage__button'),
      initial: this.cmpEl.querySelector('.button-initial-text'),
      secondary: this.cmpEl.querySelector('.button-secondary-text')
    },
    nav: this.cmpEl.querySelector('.cmp-navigation'),
    numShown: this.cmpEl.querySelector('.heroSubPage__num-shown'),
    numTotal: this.cmpEl.querySelector('.heroSubPage__num-total')
  };
  this.numTotal = this.el.items.length;
  if (this.el.numShown) {
    this.numInit = parseInt(this.el.numShown.innerHTML, 10);
    this.el.numShown.innerHTML = this.numInit;
    this.el.numTotal.innerHTML = this.numTotal;
  } else {
    this.numInit = this.numTotal;
  }
}
function onButtonClick() {
  const toggleSpans = () => {
    this.el.button.initial.classList.toggle('hidden');
    this.el.button.secondary.classList.toggle('hidden');
    this.el.numShown.innerHTML = this.el.button.initial.classList.contains('hidden') ? this.numTotal : this.numInit;
    if (this.el.button.initial.classList.contains('hidden')) {
      this.showAll();
    } else {
      this.showLess();
    }
  };
  if (this.el.button && this.el.button.main) {
    this.el.button.main.addEventListener('click', toggleSpans);
    this.el.button.main.addEventListener('keydown', event => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        toggleSpans();
      }
    });
  }
}
function componentReady() {
  this.el.nav.classList.remove('component-not-ready');
}
function showAll() {
  for (const item of this.el.items) {
    item.classList.remove('hidden');
    //        full page image loading
    let imgTag = item.querySelector('.heroSubPage__img');
    if (imgTag) {
      let imgSrc = imgTag.getAttribute('data-src');
      if (!this.fullLoaded && imgSrc && imgTag.getAttribute('src') == '/') {
        imgTag.setAttribute('src', imgSrc);
      }
    }
  }
  this.fullLoaded = true;
  const focusTarget = this.cmpEl.querySelector('.cmp-navigation__item-focus-target .heroSubPage__item-link');
  if (focusTarget) {
    setTimeout(() => focusTarget.focus(), 0);
  }
}
function showLess() {
  for (const [i, item] of this.el.items.entries()) {
    if (i < this.numInit) item.classList.remove('hidden');else item.classList.add('hidden');
  }
}