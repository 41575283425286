import CoreComponent from '../../../CoreComponent.vue';
import Common, { STATUS_FINISHED, STATUS_PAUSED, STATUS_RESUMED, STATUS_STARTED } from '../common.js';
let apiReady;
export default {
  extends: CoreComponent,
  mixins: [Common],
  mounted() {
    //get the id of video stub video
    const compData = JSON.parse(this.cmpDataLayer);
    const videoURl = compData.embeddableProperties.videoURL.split('/');
    const videoId = videoURl[4];
    //create iframe
    const videoThumbnailModal = document.getElementById(`stub-${this.cmpId}`);

    //update the iframe src with the video id
    const iFrameSrcProvider = videoId => `https://videos.dow.com/view/${videoId}?embed=true&host=widgets&autoscale=true&AppProperties.PlayerShowEmbedButton=true&autoplay=true`;
    this.createEmbed(iFrameSrcProvider, videoId, videoThumbnailModal, this.cmpEl);
  },
  created() {
    apiReady = import( /* webpackChunkName: 'qumu-player-sdk' */'@enghouse-qumu/player-sdk').catch(e => console.log(e));
  },
  methods: {
    configureApi(iFrame) {
      apiReady.then(({
        PlayerSdk
      }) => {
        const player = new PlayerSdk(iFrame);
        player.readyPromise.then(() => {
          player.getDuration().then(duration => this.duration = duration);
          player.addEventListener('play', event => {
            if (this.started) this.status = STATUS_RESUMED;else this.status = STATUS_STARTED;
          });
          player.addEventListener('pause', event => this.status = STATUS_PAUSED);
          player.addEventListener('ended', event => this.status = STATUS_FINISHED);
          player.addEventListener('timeupdate', currentTime => this.currentTime = currentTime);
        });
      });
    }
  }
};