import ApiClient from '@/plugins/AxiosPlugin'
import store from '@/store'

export default class ServletHelper {
    static async makeServletCall(url, params, headers) {
        store.commit('loading/setLoading', true, { root: true })
        return new Promise((resolve, reject) => {
            ApiClient.get(url, {
                params,
                headers
            })
                .then(function (res) {
                    resolve(res.data)
                })
                .catch(function (error) {
                    console.log(`Failed to load Servlet for -> ` + url, error)
                    reject()
                    store.commit('loading/setLoading', false, { root: true })
                })
        })
    }
    static async makePostServletCall(postUrl, postParams, postHeaders) {
        return new Promise((resolve, reject) => {
            ApiClient.post(postUrl, postParams, {
                postHeaders
            })
                .then(function (res) {
                    resolve(res.data)
                })
                .catch(function (error) {
                    console.log(
                        `Failed to load Servlet for -> ` + postUrl,
                        error
                    )
                    reject()
                    store.commit('loading/setLoading', false, { root: true })
                })
        })
    }
}
