import axios from 'axios'
const ApiClient = axios.create()

if (!window.Granite?.author) {
    ApiClient.get('/libs/granite/csrf/token.json')
        .then(function (response) {
            ApiClient.defaults.headers.common['CSRF-Token'] =
                response.data.token
        })
        .catch(function (error) {
            console.log(error)
        })
}

export default ApiClient
