export default {
  mounted() {
    var table = $("#packagingCode").DataTable({
      dom: '<"top"f>rt<"bottom"lp><"clear">',
      ajax: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2] + decodeURIComponent("${properties.jsonUpload @ context='scriptString'}"),
      search: {
        smart: true,
        regex: true
      },
      autoWidth: true,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
      bInfo: false,
      language: {
        zeroRecords: "No results found. Please <a href='https://www.dow.com/en-us/contact-us.html'>contact us</a> in case you need any further information."
      },
      columnDefs: [{
        width: "17%",
        name: "Dow Material Number",
        title: "Dow Material Number",
        targets: [0],
        visible: true,
        searchable: true
      }, {
        name: "Material Description",
        title: "Material Description",
        targets: [1],
        visible: true,
        searchable: true
      }, {
        width: "15%",
        name: "Dow Packaging ID",
        title: "Dow Packaging ID",
        targets: [2],
        visible: true,
        searchable: false
      }, {
        name: "Packaging Type",
        title: "Packaging Type",
        targets: [3],
        visible: true,
        searchable: false
      }, {
        name: "Packaging Code",
        title: "Packaging Code",
        targets: [4],
        visible: true,
        searchable: false
      }]
    });
    console.log(table, "this is the table");
    $("#packagingCode_wrapper").hide();
    $(".dataTables_filter").hide();
    $("#packagingCodeSearch").keyup(function () {
      $("#packagingCode_wrapper").show();
      const searchTerm = this.value.toLowerCase().replace(/,/g, "|");
      const regex = "\\b(" + searchTerm + ")\\b";
      table.rows().search(regex, true, false).draw();
      if ($("#packagingCodeSearch").val().length == 0) {
        $("#packagingCode_wrapper").hide();
      }
    });
    var url = window.location.href;
    var urlConv = url.replace(/%2C/g, ",");
    var arr = urlConv.split("?q=");
    if (arr.length > 1 && arr[1] !== "") {
      $("#packagingCode_wrapper").show();
      $("#packagingCodeSearch").val(arr[1]);
      const searchTerm = $("#packagingCodeSearch").val().toLowerCase().replace(/,/g, "|");
      const regex = "\\b(" + searchTerm + ")\\b";
      table.rows().search(regex, true, false).draw();
    }
  }
};