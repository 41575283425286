import ApiClient from '@platform/plugins/AxiosPlugin'
const PRODUCT_VIEW = 'product:view'

export default {
    namespaced: true,
    state: {
        webID: '',
        markets: [],
        marketCode: [],
        subMarkets: [],
        subMarketCode: [],
        technologies: [],
        technologiesCode: [],
        compareTool: null,
        groups: [],
        groupCode: [],
        applications: [],
        applicationCode: [],
        productViewDidFire: false,
        pageViewDidComplete: false,
        CMIR: '',
        productAttributes: []
    },
    mutations: {
        webID: setWebID,
        prodViewFired: prodViewFired,
        pageViewFired: pageViewFired,
        setCMIR: setCMIR,
        setCompareTool: setCompareTool,
        setProductAttributes: setProductAttributes
    },
    actions: {
        productCompareTracking: productCompareTracking,
        view: productView,
        pageViewCompleted: { root: true, handler: pageViewComplete },
        fetchCMIR: fetchCMIR,
        [PRODUCT_VIEW]: { root: true, handler() {} },
        fetchProductAttributes: fetchProductAttributes
    },
    getters: {
        getBusinesses: function (state) {
            return getBusinesses(state)
        },
        getBusinessGroups: function (state) {
            return getBusinessGroups(state)
        },
        getCategories: function (state) {
            return getCategories(state)
        },
        getProductAttributes(state) {
            return id => {
                const attrs = state.productAttributes.find(product => product.materialCode == id || product.productCode == id)
                if (attrs.categories) attrs.categories = getProductCategories(attrs.categories)
                return attrs
            }
        }
    }
}

function setWebID(state, product) {
    state.webID = product.webID
    if (product.productCategories)
        state.productCategories = product.productCategories
}

function prodViewFired(state) {
    state.productViewDidFire = true
}

function pageViewFired(state) {
    state.pageViewDidComplete = true
}

async function productCompareTracking (context, products) {
    await fetchProductAttributes(context, {
          productCodes: products.map((item) => item.gmid).join(','),
          fields: 'businesses,businessgroups,categories'
    })
 
    for (const product of products) {
        product.productCategories = getCategories(context.state)[product.SKU]
        product.business = getBusinesses(context.state)[product.SKU]
        product.businessGroup = getBusinessGroups(context.state)[product.SKU]
    }
 
    const compareTool = sessionStorage.getItem("compareTool")

    window.adobeDataLayer.push({
        event: 'product:compare',
        compareTool: compareTool,
        productListItems: products
    })

    sessionStorage.removeItem("compareTool")
}

function productView(context, product) {
    product.webID && context.commit('webID', product)
    if (
        context.state.webID &&
        !context.state.productViewDidFire // &&
        //context.state.pageViewDidComplete
    ) {
        window.adobeDataLayer = window.adobeDataLayer || []
        window.adobeDataLayer.push({
            event: PRODUCT_VIEW,
            step: 'product',
            productFindingMethod: 'browse',
            productListItems: [
                // keep as array for compatibility with Launch rule
                {
                    name: product.name,
                    SKU: product.SKU,
                    productAddMethod: product.productAddMethod,
                    productCategories: context.state.productCategories,
                    business: context.getters['getBusinesses'][product.SKU],
                    businessGroup:
                        context.getters['getBusinessGroups'][product.SKU],
                    marketingStatus: context.state.productAttributes[0].marketingStatus
                }
            ]
        })

        context.commit('prodViewFired')
    }
}

function pageViewComplete(context) {
    context.commit('pageViewFired')
    context.dispatch('view', { id: context.state.webID })
}

function setCMIR(state, query) {
    state.CMIR = query
}

function setProductAttributes(state, payload) {
    state.productAttributes = payload
}

function setCompareTool(state, compareTool) {
    state.compareTool = compareTool;
}

function fetchCMIR(context, query) {
    return ApiClient.get(store.state.settings.cmir_servlet, {
        params: {
            query: query
        }
    })
        .then(function (res) {
            context.commit('setCMIR', res.data)
        })
        .catch(function (error) {
            context.commit('setCMIR', '')
            console.log('Failed to load CMIR.')
        })
}

//makes API call to fetch required product attributes for AA tracking
function fetchProductAttributes(context, payload) {
    return ApiClient.get(store.state.settings.product_attributes_servlet, {
        params: payload
    })
        .then(function (res) {
            if (res.data && res.data.productAttributes) {
                console.log('Product Attributes Fetched!')
                context.commit(
                    'setProductAttributes',
                    res.data.productAttributes
                )
            } else {
                context.commit('setProductAttributes', [])
            }
        })
        .catch(function (error) {
            context.commit('setProductAttributes', [])
            console.log('Failed to fetch Product Attributes.', error)
        })
}

function getBusinesses(state) {
    return state.productAttributes.reduce((businesses, product) => {
        businesses[product.productCode || product.materialCode] =
            product.businesses
        return businesses
    }, {})
}

function getBusinessGroups(state) {
    return state.productAttributes.reduce((businessGroups, product) => {
        businessGroups[product.productCode || product.materialCode] =
            product.businessGroups?.[0]
        return businessGroups
    }, {})
}

function getCategories(state) {
    return state.productAttributes.reduce((categories, product) => {
        categories[product.productCode || product.materialCode] =
            getProductCategories(product.categories)
        return categories
    }, {})
}

function getProductCategories(categories) {
    return (
        categories?.map((category) => ({
            categoryID: category.code,
            categoryName: category.name,
            categoryPath: category.url
        })) || []
    )
}