import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  methods: {
    smoothScroll(event) {
      event.preventDefault();
      var linkDestination = document.querySelector(event.target.parentElement.getAttribute('href'));
      var anchorLinkOffset = event.target.parentElement.parentElement.parentElement.offsetHeight;
      if (linkDestination == null) {
        linkDestination = document.querySelector(event.target.parentElement.parentElement.parentElement.parentElement.getAttribute('href'));
        anchorLinkOffset = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight;
      }
      if (linkDestination == null) {
        linkDestination = document.querySelector(event.target.getAttribute('href'));
        anchorLinkOffset = event.target.parentElement.parentElement.offsetHeight;
      }
      if (linkDestination == null) {
        linkDestination = document.querySelector(event.target.parentElement.parentElement.getAttribute('href'));
        anchorLinkOffset = event.target.parentElement.parentElement.parentElement.parentElement.offsetHeight;
      }
      var destinationPosition = linkDestination.getBoundingClientRect().top;
      var finalPosition = destinationPosition + window.pageYOffset - anchorLinkOffset;
      window.scrollTo({
        top: finalPosition,
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    let buttons = this.cmpEl.querySelectorAll('.cmp-button');
    buttons.forEach(button => {
      button.addEventListener('click', this.smoothScroll);
    });
  }
};