export const featureFlag = {
    namespaced: true,
    state: {},
    mutations: {
        setFlags: function (state, featureFlagMap) {
            Object.keys(featureFlagMap).forEach((flag) => {
                state[flag] = featureFlagMap[flag]
            })
        }
    }
}
