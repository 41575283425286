export default class AccessibilityHelper {

    static generateAriaLabelForBannerButton(cmpEl, titleSelector, buttonSelector) {
        if (cmpEl) {
            const title = cmpEl.querySelector(titleSelector)?.innerText
            const buttonEl = cmpEl.querySelector(buttonSelector)
            if (buttonEl) {
                buttonEl.ariaLabel = buttonEl.innerText.trim() + (title ? (' - ' + title) : '')
            }
        }
    }

}
