import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "terms-intro"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "btn-group"
};
const _hoisted_5 = ["disabled"];
import { ref, onMounted, computed } from 'vue';
import DcModal from '../modal/DcModal.vue';
import DcCheckBox from '../forms/DcCheckBox.vue';
import { useStore } from 'vuex';
export default {
  __name: 'TermsAndConditionsModal',
  props: {
    loginData: null,
    termsSelector: {
      type: String,
      default: 'terms-modal'
    },
    loginCallback: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const termsAgreed = ref(false);
    const showModal = ref(true);
    const labels = ref({
      modalTitle: window.Granite.I18n.get('Confirmation'),
      welcome: window.Granite.I18n.get('terms.conditions.welcome'),
      description: window.Granite.I18n.get('terms.conditions.description'),
      label: window.Granite.I18n.get('terms.conditions.agree.SiteTermsAndConditions'),
      submit: window.Granite.I18n.get('terms.conditions.cta.agree'),
      cancel: window.Granite.I18n.get('terms.conditions.cta.cancel'),
      termsError: window.Granite.I18n.get('terms.conditions.error.message')
    });
    const modal = ref(null);
    const termsModal = ref(null);
    const formData = computed(() => {
      const formData = props.loginData;
      formData.set('terms_conditions_agree_SiteTermsAndConditions', termsAgreed.value);
      return formData;
    });
    const userName = computed(() => {
      return store.state.user?.username;
    });
    const welcomeTextAdjusted = computed(() => {
      if (userName.value !== '' && userName.value) {
        return labels.value.welcome.replace('Name', `${userName.value}!`);
      }
      return labels.value.welcome;
    });
    const reauthorizationDueDateAdjusted = computed(() => {
      const dueDate = store.state?.user?.dccUserInfo?.data?.reauthorizationDueDate || null;
      if (dueDate) {
        const date = new Date(parseInt(dueDate));
        const month = parseInt(date.getUTCMonth());
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedUTCDate = date.getUTCDate() + '-' + months[month] + '-' + date.getUTCFullYear();
        return labels.value.accountRemoval.replace('{{ReauthorizationDueDate}}', formattedUTCDate);
      }
      return labels.value.accountRemoval;
    });
    const userTermsAgreed = computed(() => store.state.user.termsAccepted);
    const termsAgreeDisable = computed(() => {
      return !termsAgreed.value;
    });
    const handleFocus = () => {
      termsModal.value?.addEventListener('keydown', element => {
        let isTabPressed = element.key === 'Tab' || element.keyCode === 9;
        if (!isTabPressed) {
          return;
        }
        if (termsModal.value?.querySelector('.dc-modal__window').hasAttribute("tabIndex")) {
          termsModal.value?.querySelector('.dc-modal__window').removeAttribute("tabIndex");
          termsModal.value?.querySelector('.dc-modal__window').setAttribute("tabIndex", "-1");
        }
      });
      termsModal.value?.addEventListener('click', () => {
        if (termsModal.value?.querySelector('.dc-modal__window').hasAttribute("tabIndex")) {
          termsModal.value?.querySelector('.dc-modal__window').removeAttribute("tabIndex");
          termsModal.value?.querySelector('.dc-modal__window').setAttribute("tabIndex", "-1");
        }
      });
    };
    const logout = () => {
      if (store.state.user.isSSOLogin) {
        store.commit('user/termsAccepted', true);
      } else {
        store.dispatch('user/logoutUser');
        store.commit('user/termsAccepted', false);
      }
    };
    const verifyTerms = async () => {
      const payload = {
        formData: formData.value,
        awaitCallback: props.loginCallback
      };
      if (store.state.user.isSSOLogin) {
        await store.dispatch('user/ssoLoginUser', formData.value);
        store.commit('user/termsAccepted', true);
      } else {
        await store.dispatch('user/loginUser', payload);
        store.commit('user/termsAccepted', true);
      }
    };
    onMounted(() => {
      if (store.state.user.isSSOLogin) {
        handleFocus();
      }
    });
    return (_ctx, _cache) => {
      const _component_DcForm = _resolveComponent("DcForm");
      return _openBlock(), _createBlock(_Teleport, {
        to: __props.termsSelector
      }, [_createElementVNode("div", {
        ref_key: "termsModal",
        ref: termsModal
      }, [!userTermsAgreed.value ? (_openBlock(), _createBlock(DcModal, {
        key: 0,
        onModalClose: _cache[3] || (_cache[3] = $event => logout())
      }, {
        header: _withCtx(() => [_createElementVNode("h4", null, _toDisplayString(labels.value.modalTitle), 1)]),
        body: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("p", {
          innerHTML: welcomeTextAdjusted.value
        }, null, 8, _hoisted_2), _createElementVNode("p", {
          innerHTML: labels.value.description
        }, null, 8, _hoisted_3)]), _createVNode(_component_DcForm, {
          id: "verify-terms",
          onSubmit: _cache[2] || (_cache[2] = $event => verifyTerms())
        }, {
          default: _withCtx(() => [_createVNode(DcCheckBox, {
            name: "termsAgreed",
            type: "checkbox",
            modelValue: termsAgreed.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => termsAgreed.value = $event),
            label: labels.value.label,
            value: true,
            rules: "required",
            errorText: labels.value.termsError
          }, null, 8, ["modelValue", "label", "errorText"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
            type: "submit",
            class: "btn",
            disabled: termsAgreeDisable.value
          }, _toDisplayString(labels.value.submit), 9, _hoisted_5), _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => logout(), ["prevent"])),
            class: "btn--ghost-red",
            type: "button"
          }, _toDisplayString(labels.value.cancel), 1)])]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)], 512)], 8, ["to"]);
    };
  }
};