import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Keyboard } from 'swiper';
import 'swiper/css';
export default {
  extends: CoreComponent,
  mounted() {
    const bulletButtonAriaLabel = window.Granite.I18n.get('cardcontainer.carousel.pagination');
    const contentPanel = this.cmpEl.querySelector('.cmp-contentpanelcontainer__card-image__carousel');
    if (contentPanel) {
      var panelSwiper = new Swiper(contentPanel, {
        slidesPerView: 1,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-contentpanelcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });
      const paginationContainers = this.cmpEl.querySelectorAll('.cmp-contentpanelcontainer--carousel-pagination');
      paginationContainers.forEach(paginationContainer => {
        const paginationBullets = paginationContainer.querySelectorAll('.swiper-pagination-bullet');
        const bulletCount = paginationBullets.length;
        const nextButton = document.createElement('div');
        nextButton.classList.add('swiper-button-next');
        const prevButton = document.createElement('div');
        prevButton.classList.add('swiper-button-prev');
        prevButton.addEventListener('click', () => {
          panelSwiper.slidePrev();
        });
        nextButton.addEventListener('click', () => {
          panelSwiper.slideNext();
        });

        //check if closet parent swiper-button-prev is there
        var swiperInitializedElement = "";
        var prevButtonElement = "";
        var nextButtonElement = "";
        var closetswiperWrapper = "";
        if (bulletCount >= 2) {
          prevButton.style.display = 'display';
          nextButton.style.display = 'display';
          const carouselContainer = paginationContainer.closest('.cmp-contentpanelcontainer--carousel');
          const swiperWrapper = carouselContainer.querySelector('.swiper-wrapper');
          swiperInitializedElement = swiperWrapper.querySelector('.swiper-initialized');
          prevButtonElement = swiperInitializedElement.querySelector('.swiper-button-prev');
          nextButtonElement = swiperInitializedElement.querySelector('.swiper-button-next');
          closetswiperWrapper = swiperInitializedElement.querySelector('.swiper-wrapper');
          if (prevButtonElement == null && nextButtonElement == null) {
            let children = paginationContainer.childNodes;
            // paginationContainer.insertBefore(prevButton,(children[0]));     
            paginationContainer.appendChild(prevButton);
            paginationContainer.appendChild(nextButton);
          }
        }
      });
    }
  }
};