import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data: () => {
    return {
      tabs: null,
      tabPanels: null,
      moreTrigger: null,
      searchInput: null,
      searchButton: false,
      searchEntries: null,
      delayTime: 500,
      searchTimeout: null,
      searchPerformed: false,
      lastSearch: '',
      currentHighlight: -1,
      resultsCount: 0,
      isTyping: false,
      navigation: null
    };
  },
  mounted() {
    this.navigation = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__alphabetnav');
    this.tabs = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__tab');
    this.tabPanels = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__tabpanel');
    this.tabPanels.forEach(panel => {
      panel.addEventListener('beforematch', () => {
        this.deactivateAllTabsAndPanels();
        const tab = this.cmpEl.querySelector(`.cmp-utility-product-brand-index__tab[aria-controls="${panel.id}"]`);
        tab.setAttribute('aria-selected', 'true');
        tab.classList.add('cmp-utility-product-brand-index__tab--active');
        panel.removeAttribute('hidden');
      });
    });
    const activateTab = (tab, resetLetters = false) => {
      this.deactivateAllTabsAndPanels();
      tab.setAttribute('aria-selected', 'true');
      tab.classList.add('cmp-utility-product-brand-index__tab--active');
      var panel = this.cmpEl.querySelector(`#${tab.getAttribute('aria-controls')}`);
      panel.removeAttribute('hidden');
      this.getSearchEntries();
      this.updateResultsCount();
      if (resetLetters) {
        this.removeSelectedClass(letters);
        const firstLetter = panel.querySelector('.cmp-utility-product-brand-index__letter');
        this.addSelectedClass(firstLetter);
      }
      const searchResultBlocks = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-results');
      searchResultBlocks.forEach(block => {
        block.style.display = 'none';
        block.parentNode.classList.remove('cmp-utility-product-brand-index__search--active');
      });
    };
    this.tabs.forEach(tab => {
      tab.addEventListener('click', e => {
        activateTab(tab, true);
      });
    });
    let scrollPositionRem = parseFloat(getComputedStyle(this.cmpEl).getPropertyValue('--scroll-position'));
    let scrollPosition = scrollPositionRem * 16;
    window.addEventListener('scroll', () => {
      this.navigation.forEach(nav => {
        const sticky = nav.parentNode.offsetTop - scrollPosition;
        const bottomOfCmp = this.cmpEl.offsetTop + this.cmpEl.offsetHeight;
        if (window.scrollY > sticky && window.scrollY < bottomOfCmp) {
          nav.classList.add('cmp-utility-product-brand-index__alphabetnav--sticky');
        } else {
          nav.classList.remove('cmp-utility-product-brand-index__alphabetnav--sticky');
        }
      });
    });
    this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__entry-more-trigger').forEach(entry => {
      entry.addEventListener('click', e => {
        e.preventDefault();
        const siblingMore = entry.parentNode.querySelector('.cmp-utility-product-brand-index__entry-more');
        if (siblingMore.style.display === 'none' || siblingMore.style.display === '') {
          siblingMore.style.display = 'block';
          entry.classList.add('cmp-utility-product-brand-index__entry-more-trigger--active');
        } else {
          siblingMore.style.display = 'none';
          entry.classList.remove('cmp-utility-product-brand-index__entry-more-trigger--active');
        }
      });
    });
    const letters = Array.from(document.querySelectorAll('.cmp-utility-product-brand-index__letter'));
    const sectionElements = document.querySelectorAll('.cmp-utility-product-brand-index__index');
    letters.forEach(letter => {
      letter.addEventListener('click', e => {
        e.preventDefault();
        const targetSectionId = e.target.getAttribute('href');
        window.location.hash = targetSectionId;
        if (targetSectionId) {
          this.scrollToSection(targetSectionId);
        }
      });
    });
    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(e => {
        const letter = letters.find(l => {
          const hash = l.href.substr(l.href.indexOf('#'));
          return hash === `#${e.target.id}`;
        });
        if (e.isIntersecting && letter) {
          this.removeSelectedClass(letters);
          this.addSelectedClass(letter);
        }
      });
    }, {
      threshold: 0,
      rootMargin: '-50% 0% -50% 0%'
    });
    sectionElements.forEach(section => intersectionObserver.observe(section));

    // Search functionality
    const searchButtons = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-btn');
    const searchComponents = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search');
    searchButtons.forEach((searchButton, index) => {
      searchButton.addEventListener('click', () => {
        this.searchButtonActive = !this.searchButtonActive;
        const hasActiveClass = searchComponents[index].classList.toggle('cmp-utility-product-brand-index__search--active');
        const searchInput = this.cmpEl.querySelector('.cmp-utility-product-brand-index__search-input');
        const searchResultBlocks = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-results');
        searchResultBlocks.forEach(block => {
          if (hasActiveClass && searchInput.value.trim() !== '') {
            block.style.display = 'flex';
          } else {
            block.style.display = 'none';
          }
        });
      });
    });
    this.searchInput = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-input');
    this.getSearchEntries();
    this.searchInput.forEach(input => input.addEventListener('input', () => this.performSearch(input)));
    this.cmpEl.querySelectorAll('.search-next-btn').forEach(next => next.addEventListener('click', this.nextMatch));
    this.cmpEl.querySelectorAll('.search-prev-btn').forEach(prev => prev.addEventListener('click', this.prevMatch));
    this.cmpEl.querySelectorAll('.search-done-btn').forEach((done, index) => {
      done.addEventListener('click', () => {
        this.searchButtonActive = false;
        searchComponents[index].classList.remove('cmp-utility-product-brand-index__search--active');
        this.doneSearch();
      });
    });

    // activate the proper tab panel
    if (window.location.hash) {
      let hash = window.location.hash;
      if (hash.indexOf('#product-') === 0) {
        this.tabs.forEach(tab => {
          if (tab.getAttribute('aria-controls') == 'product-index') {
            activateTab(tab);
          }
        });
      } else if (hash.indexOf('#brand-') === 0) {
        this.tabs.forEach(tab => {
          if (tab.getAttribute('aria-controls') == 'brand-index') {
            activateTab(tab);
          }
        });
      }
      const targetLetter = letters.find(letter => letter.getAttribute('href') === hash);
      if (targetLetter) {
        this.removeSelectedClass(letters);
        this.addSelectedClass(targetLetter);
        setTimeout(() => this.scrollToSection(hash), 500);
      }
    }

    // Prevent event propagation if anchorLinks starting with #product- and #brand- are clicked
    const allAnchorLinks = Array.from(document.querySelectorAll('a[href^="#product-"], a[href^="#brand-"]'));
    const excludedAnchorLinks = Array.from(document.querySelectorAll('.cmp-utility-product-brand-index__alphabetnav a[href^="#product-"], .cmp-utility-product-brand-index__alphabetnav a[href^="#brand-"], .cmp-utility-product-brand-index__tablist a[href^="#product-"], .cmp-utility-product-brand-index__tablist a[href^="#brand-"]'));
    const anchorLinks = allAnchorLinks.filter(link => !excludedAnchorLinks.includes(link));
    const handler = event => {
      event.stopImmediatePropagation();
    };
    anchorLinks.forEach(link => {
      link.addEventListener('click', handler, true);
    });
  },
  methods: {
    scrollToSection(sectionID) {
      const targetSection = this.cmpEl.querySelector(sectionID);
      if (targetSection) {
        let scrollToPosition = targetSection.offsetTop;
        const box = targetSection.getBoundingClientRect();
        if (box.height < window.innerHeight) {
          scrollToPosition -= window.innerHeight / 2 - box.height / 2;
        } else {
          scrollToPosition -= this.navigation[0].offsetHeight;
        }
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
      }
    },
    removeSelectedClass(letters) {
      letters.forEach(l => l.classList.remove('cmp-utility-product-brand-index__letter--selected'));
    },
    addSelectedClass(el) {
      el.classList.add('cmp-utility-product-brand-index__letter--selected');
    },
    getSearchEntries() {
      if (this.searchEntries) {
        this.searchEntries.forEach(this.resetHighlights);
      }
      this.searchEntries = Array.from(this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__tabpanel:not([hidden="until-found"]) .cmp-utility-product-brand-index__entry'));
      this.searchPerformed = false;
      this.lastSearch = '';
      this.currentHighlight = -1;
      this.searchInput.forEach(i => i.value = '');
    },
    deactivateAllTabsAndPanels() {
      this.tabs.forEach(tab => {
        tab.setAttribute('aria-selected', 'false');
        tab.classList.remove('cmp-utility-product-brand-index__tab--active');
      });
      this.tabPanels.forEach(panel => {
        panel.setAttribute('hidden', 'until-found');
      });
    },
    searchAndHighlight(node, searchText) {
      if (node.nodeType === Node.TEXT_NODE && node.data.toLowerCase().includes(searchText.toLowerCase())) {
        const matchIndex = node.data.toLowerCase().indexOf(searchText.toLowerCase());
        const matchEnd = matchIndex + searchText.length;
        const before = node.data.slice(0, matchIndex);
        const match = node.data.slice(matchIndex, matchEnd);
        const after = node.data.slice(matchEnd);
        const highlight = document.createElement('span');
        highlight.textContent = match;
        highlight.className = 'matched-search';
        const newNodes = document.createDocumentFragment();
        newNodes.append(document.createTextNode(before), highlight, document.createTextNode(after));
        node.parentNode.replaceChild(newNodes, node);
        return true;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        let child = node.firstChild;
        while (child) {
          const nextChild = child.nextSibling;
          this.searchAndHighlight(child, searchText);
          child = nextChild;
        }
      }
      return false;
    },
    resetHighlights(node) {
      const highlights = node.getElementsByClassName('matched-search');
      while (highlights.length) {
        const highlight = highlights[0];
        const textNode = document.createTextNode(highlight.textContent);
        highlight.replaceWith(textNode);
        textNode.parentNode.textContent = textNode.parentNode.innerText;
      }
    },
    getResultsCountText() {
      return this.resultsCount === 1 ? `${this.resultsCount} match` : `${this.resultsCount} matches`;
    },
    updateResultsCount() {
      const highlights = this.cmpEl.getElementsByClassName('matched-search');
      this.resultsCount = highlights.length;
      const resultsCountElements = document.getElementsByClassName('results-count');
      Array.from(resultsCountElements).forEach(element => {
        element.textContent = this.getResultsCountText();
      });
    },
    performSearch(input) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        const searchText = input.value;
        const searchResultBlock = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-results');
        if (!searchText) {
          this.searchEntries.forEach(this.resetHighlights);
          this.currentHighlight = -1;
          this.searchPerformed = false;
          this.isTyping = false;
          searchResultBlock.forEach(block => block.style.display = 'none');
        } else if (searchText !== this.lastSearch) {
          this.searchEntries.forEach(this.resetHighlights);
          this.searchEntries.forEach(entry => this.searchAndHighlight(entry, searchText));
          this.currentHighlight = -1;
          this.lastSearch = searchText;
          this.searchPerformed = true;
          searchResultBlock.forEach(block => block.style.display = 'flex');
          if (this.cmpEl.querySelectorAll('.matched-search').length) {
            this.nextMatch();
          }
        }
        this.updateResultsCount();
      }, this.delayTime);
    },
    nextMatch() {
      if (!this.searchPerformed) {
        return;
      }
      const highlights = document.getElementsByClassName('matched-search');
      if (this.currentHighlight >= 0) {
        highlights[this.currentHighlight].classList.remove('current-match');
      }
      this.currentHighlight = (this.currentHighlight + 1) % highlights.length;
      highlights[this.currentHighlight].classList.add('current-match');
      highlights[this.currentHighlight].scrollIntoView({
        block: 'center'
      });
    },
    prevMatch() {
      if (!this.searchPerformed) {
        return;
      }
      const highlights = document.getElementsByClassName('matched-search');
      if (this.currentHighlight >= 0) {
        highlights[this.currentHighlight].classList.remove('current-match');
      }
      this.currentHighlight = (this.currentHighlight - 1 + highlights.length) % highlights.length;
      highlights[this.currentHighlight].classList.add('current-match');
      highlights[this.currentHighlight].scrollIntoView({
        clock: 'center'
      });
    },
    doneSearch() {
      this.searchEntries.forEach(this.resetHighlights);
      this.searchInput.forEach(input => input.value = '');
      this.lastSearch = '';
      this.currentHighlight = -1;
      this.searchPerformed = false;
      const resultsCountElements = this.cmpEl.querySelectorAll('.results-count');
      resultsCountElements.forEach(element => {
        element.textContent = '';
      });
      const searchResultElements = this.cmpEl.querySelectorAll('.cmp-utility-product-brand-index__search-results');
      searchResultElements.forEach(element => {
        element.style.display = 'none';
      });
    }
  }
};