import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Keyboard, Navigation, Grid } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
export default {
  extends: CoreComponent,
  mounted() {
    var productHighlightComponent = document.querySelector('.cmp-product-highlight#' + this.cmpId);
    if (productHighlightComponent) {
      var allButtonTexts = productHighlightComponent.querySelectorAll('.product-highlight-button-link .cmp-button__text');
      [...allButtonTexts].forEach(element => {
        element.innerText = window.Granite.I18n.get(element.textContent.toLowerCase());
      });
    }
    const contentCarousel = this.cmpEl.querySelector('.cmp-product-highlight--carousel');
    const bulletButtonAriaLabel = window.Granite.I18n.get('cardcontainer.carousel.pagination');
    if (contentCarousel) {
      new Swiper(contentCarousel, {
        slidesPerView: 1,
        spaceBetween: 16,
        slideToClickedSlide: false,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination, Navigation, Grid],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.carousel__product-highlight-cross-sell-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        grid: {
          rows: 2,
          fill: 'row'
        },
        navigation: {
          nextEl: document.querySelector('.swiper-button.next'),
          prevEl: document.querySelector('.swiper-button.prev')
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      });
    }
    $(productHighlightComponent).show();
  },
  beforeUnmount() {},
  methods: {}
};