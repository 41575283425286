import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data: () => {
    return {
      allMegaMenuItems: null,
      megaMenuItems: null,
      navigationDiv: document.querySelector('.cmp-mega-menu-navigation__nav')
    };
  },
  mounted() {
    this.megaMenuLinks = this.cmpEl.querySelectorAll('.cmp-mega-menu__link');
    this.megaMenuItems = this.cmpEl.querySelectorAll('.cmp-mega-menu__item--has-children > a');
    this.megaMenuItems.forEach(menuItem => {
      menuItem.addEventListener('click', e => {
        e.preventDefault();
        this.resetMenu();
        e.target.classList.add('cmp-mega-menu__link-active');
        const sibling = e.target.nextElementSibling;
        if (sibling && sibling.classList.contains('cmp-mega-menu__group')) {
          sibling.classList.add('cmp-mega-menu__group-active');
        }
        this.cmpEl.classList.add('cmp-mega-menu--active-level-1');
      });
    });
    this.megaMenuLinks.forEach((linkItem, index) => {
      linkItem.addEventListener('keydown', e => {
        const items = this.megaMenuLinks;
        var nextIndex = (index + 1) % items.length;
        var prevIndex = (index - 1 + items.length) % items.length;

        // Up arrow
        if (e.keyCode === 38) {
          items[prevIndex].focus();
          e.preventDefault();
        }
        // Down arrow
        else if (e.keyCode === 40) {
          items[nextIndex].focus();
          e.preventDefault();
        }
      });
    });
    const backButtons = this.cmpEl.querySelectorAll('.cmp-mega-menu__back');
    backButtons.forEach(backButton => {
      backButton.addEventListener('click', e => {
        e.preventDefault();
        this.resetMenu();
        if (this.cmpEl.classList.contains('cmp-mega-menu--active-level-1')) {
          this.cmpEl.classList.remove('cmp-mega-menu--active-level-1');
        } else if (this.cmpEl.classList.contains('cmp-mega-menu--active-level-0')) {
          this.closeMenu();
        }
      });
    });
    document.addEventListener('click', event => {
      if (this.cmpEl.classList.contains('cmp-mega-menu--active-level-0') && !this.cmpEl.contains(event.target) && !this.navigationDiv.closest('.mega-menu-navigation').contains(event.target) && !document.querySelector('.header').contains(event.target)) {
        this.closeMenu();
      }
    });
  },
  methods: {
    resetMenu() {
      this.megaMenuItems.forEach(menuItem => {
        menuItem.classList.remove('cmp-mega-menu__link-active');
        let sibling = menuItem.nextElementSibling;
        if (sibling && sibling.classList.contains('cmp-mega-menu__group')) {
          sibling.classList.remove('cmp-mega-menu__group-active');
        }
      });
    },
    closeMenu() {
      document.querySelectorAll('.cmp-mega-menu-navigation__nav-buttons-text').forEach(item => item.classList.remove('nav-buttons-text--active'));
      this.navigationDiv.classList.remove('cmp-mega-menu-navigation__nav-hidden');
      this.cmpEl.classList.remove('cmp-mega-menu--active-level-0');
    }
  }
};