import CoreComponent from '../../core/CoreComponent.vue';
import store from '@/store';
import axios from 'axios';
export default {
  name: 'ListCards',
  extends: CoreComponent,
  store: store,
  mounted() {
    this.$store.dispatch('facetCount/fetchTechnicalLibraryCount', {
      type: 'DowComMainSearch',
      contentType: '*'
    });
  },
  computed: {
    techCounts: function () {
      return this.$store.getters['facetCount/getTechnicalLibraryCounts'];
    }
  }
};