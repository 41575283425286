import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoginForm = _resolveComponent("LoginForm");
  const _component_ConfirmRegistration = _resolveComponent("ConfirmRegistration");
  return _openBlock(), _createElementBlock(_Fragment, null, [$data.showForm ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: $options.selectorLogin
  }, [_createVNode(_component_LoginForm, {
    termsSelector: $options.selectorTerms
  }, null, 8, ["termsSelector"])], 8, ["to"])) : _createCommentVNode("", true), $options.reauthorizationRequired ? (_openBlock(), _createBlock(_Teleport, {
    key: 1,
    to: $options.selectorRegistration
  }, [_createVNode(_component_ConfirmRegistration)], 8, ["to"])) : _createCommentVNode("", true)], 64);
}