import MyProductsDTOService from './my-products-dto-service'
import PreviousPurchasesFiltersDefaultValueHelper from './filter-helpers/previous-purchases-filters-default-value-helper'

export const PreviousPurchasesModuleHelper = {
    namespaced: true,
    state: {
        items: [],
        hasError: false,
        isFromCache: false,
        cartItems: [],
        item: {},
        itemsLoaded: false,
        filters: {},
        filterDefaultValueHelper: PreviousPurchasesFiltersDefaultValueHelper,
        sortOptions: {
            attribute: 'materialName',
            sortType: 'alphabetic',
            sortDirection: 'asc'
        },
        itemsUrl: 'get_previous_purchases',
        itemUrl: '',
        requestType: 'GET',
        resourceName: 'previouslyPurchasedProducts',
        resourceDto: MyProductsDTOService.getPreviousPurchasesDTO,
        clientSideFilters: ['searchOption'],
        modeView: 'list',
        unfilteredResults: []
    }
}
