
export const AF_INTERACTION = 'dow:af:interaction'
export const AF_CONTINUE = 'dow:af:continue'
export const AF_COMPLETE = 'dow:af:complete'
export const AF_ERROR = 'dow:af:error'

if (window !== window.top) {
    const formEl = document.querySelector('[data-form-page-path]')
    const origin = `${window.location.protocol}//${window.location.host}`
    
    if (formEl) {
        const { path, name, id } = adaptiveForm(formEl.dataset.formPagePath)

        addAfInteractionListener(formEl, event => {
            window.top.postMessage({ event: AF_INTERACTION, id }, origin)
        })

        addAfSubmitListener(({ target: response }) => {
            if (response.status >= 200 && response.status < 400) {
                window.top.postMessage({ event: AF_COMPLETE, id }, origin)
            } else {
                window.top.postMessage({ event: AF_ERROR, error: response.statusText, id }, origin)
            }
        })

        addAfValidationErrorListener(formEl, error => {
            window.top.postMessage({ event: AF_ERROR, error, id }, origin)
        })

        addAfNavigationListener(formEl, records => {
            records.forEach(record => {
                window.top.postMessage({
                    event: AF_CONTINUE, 
                    id,
                    title: record.target.title,
                    parent: record.target.dataset.guideParentId
                }, origin)
            });
        })

    }
}

export function adaptiveForm(path = '') {
    const paths = path.match(/.*?(\/content\/forms\/af\/(.*?))(\/jcr:content\/|$).*/)
    return !paths ? {} : {
        path: paths[1],
        name: paths[2],
        id: `form-${paths[2].replace(/\//g, '-')}`
    }
}

export function addAfInteractionListener(el, cb) {
    const form = el.querySelector('form')
    form?.querySelectorAll('input,select,textarea').forEach(field => {
        field.addEventListener('input', cb)
    })
}

export function addAfSubmitListener(callback) {
    const openXMLHttpRequest = XMLHttpRequest.prototype.open
    XMLHttpRequest.prototype.open = function (method, url) {
        if (method == 'POST' && /\.af\.submit\.jsp/.test(url))
            this.addEventListener('loadend', callback)
        openXMLHttpRequest.apply(this, arguments)
    }
}

export function addAfValidationErrorListener(el, callback) {
    const errors = el.querySelectorAll('.guideFieldError')
    errors.forEach(error => {
        const observer = new MutationObserver(mutations => {
            let errorText = ''
            mutations.forEach(mutation => {
                if (mutation.addedNodes.length) {
                    let fieldName = el.querySelector(`[aria-labelledby="${mutation.target.id}"]`)?.getAttribute('aria-label')
                    errorText = (fieldName ? `${fieldName}: ` : '') + mutation.addedNodes[0].data.trim()
                }
            })
            if (errorText) callback(errorText)
        })
        observer.observe(error, { childList: true })
    })
}

export function addAfNavigationListener(el, callback) {
    const navigators = el.querySelectorAll('a[data-guide-id]')
    navigators.forEach(nav => {
        const guideItem = nav.dataset.guideToggle == 'accordion-tab'
            ? el.querySelector(`#${nav.dataset.guideId}_guide-item`)
            : el.querySelector(`#${nav.dataset.guideId}_guide-item-nav`)
        if (guideItem) {
            const observer = new MutationObserver(records => {
                if (guideItem.classList.contains('active') && !guideItem.classList.contains('stepped'))
                    callback(records)
            })
            observer.observe(guideItem, { attributeFilter: ['class'], attributeOldValue: true })
        }
    })
}
