import axios from 'axios'

export default {
    namespaced: true,
    state: {
        marketCounts: null,
        subMarketCounts: null,
        subMarketUrl: null,
        subMarketName: null,
        marketName: null,
        prodTechCounts: null,
        prodGroupCounts: null,
        prodGroupUrl: null,
        prodGroupName: null,
        prodTechName: null,
        technicalLibraryCounts: null
    },
    mutations: {
        setMarketName: function (state, payload) {
            state.marketName = payload
        },
        setSubMarketName: function (state, payload) {
            state.subMarketName = payload
        },
        setProdTechName: function (state, payload) {
            state.prodTechName = payload
        },
        setProdGroupName: function (state, payload) {
            state.prodGroupName = payload
        },
        setMarketCounts: function (state, payload) {
            state.marketCounts = payload
        },
        setSubMarketCounts: function (state, payload) {
            state.subMarketCounts = payload
        },
        setProdTechCounts: function (state, payload) {
            state.prodTechCounts = payload
        },
        setProdGroupCounts: function (state, payload) {
            state.prodGroupCounts = payload
        },
        setTechnicalLibraryCounts: function (state, payload) {
            state.technicalLibraryCounts = payload
        }
    },
    getters: {
        getMarketCounts: function (state) {
            return getMarketCounts(state)
        },
        getSubMarketCounts: function (state) {
            return getSubMarketCounts(state)
        },
        getMarketName: function (state) {
            return state.marketName
        },
        getProdTechName: function (state) {
            return state.prodTechName
        },
        getTechnicalLibraryCounts: function (state) {
            return state.technicalLibraryCounts
        }
    },
    actions: {
        fetchMarketCount: function (state, params) {
            fetchMarketCount(this, params)
        },
        fetchSubMarketCount: function (state, params) {
            fetchSubMarketCount(this, params)
        },
        fetchProdTechCount: function (state, params) {
            fetchProdTechCount(this, params)
        },
        fetchProdGroupCount: function (state, params) {
            fetchProdGroupCount(this, params)
        },
        fetchTechnicalLibraryCount: function (state, params) {
            fetchTechnicalLibraryCount(this, params)
        }
    }
}

function getMarketCounts(state) {
    return state.marketCounts
}

function fetchMarketCount(context, params) {
    var existingData = context.getters['facetCount/getMarketCounts']
    var selectedMarket = context.getters['facetCount/getMarketName']
    if (
        existingData != null &&
        selectedMarket == decodeURIComponent(params.market)
    ) {
        return Promise.resolve()
    } else {
        context.commit('facetCount/setMarketCounts', 0)
        var totalCountsTemplate = {
            __additional: { q: params.market, tab: 'Products' },
            category: 'products'
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
            totalCountsTemplate['org'] = params.org
            totalCountsTemplate['token'] = params.token
            totalCountsTemplate['searchField'] = 'dow_market'
            totalCountsTemplate['returnField'] = 'dow_market'
        }

        const locale = context.state.browser.cookies['languageCode'] || 'en-us'
        var url = '/' + locale + '/.dow.facet.counts.servlet.json?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))

        return axios
            .get(url + encoded)
            .then(function (response) {
                if (
                    response != null &&
                    response.data != null &&
                    response.data.values != null
                ) {
                    var count = 0
                    response.data.values.forEach((m) => {
                        if (m.value == decodeURIComponent(params.market)) {
                            count = m.numberOfResults
                        }
                    })
                    context.commit('facetCount/setMarketCounts', count)
                }
            })
            .catch(function () {})
    }
}

function getSubMarketCounts(state) {
    return state.subMarketCounts
}

function fetchSubMarketCount(context, params) {
    var existingData = context.getters['facetCount/getSubMarketCounts']
    var selectedMarket = context.getters['facetCount/getMarketName']
    if (
        existingData != null &&
        selectedMarket == decodeURIComponent(params.market)
    ) {
        return Promise.resolve()
    } else {
        context.commit('facetCount/setSubMarketCounts', [])
        var totalCountsTemplate = {
            __additional: { q: params.market, tab: 'Products' },
            category: 'products'
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
            totalCountsTemplate['org'] = params.org
            totalCountsTemplate['token'] = params.token
            totalCountsTemplate['searchField'] = 'dow_market'
        }

        const locale = context.state.browser.cookies['languageCode'] || 'en-us'
        var url = '/' + locale + '/.dow.facet.counts.servlet.json?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))

        return axios
            .get(url + encoded)
            .then(function (response) {
                if (
                    response != null &&
                    response.data != null &&
                    response.data.values != null
                ) {
                    context.commit(
                        'facetCount/setSubMarketCounts',
                        response.data.values
                    )
                }
            })
            .catch(function () {})
    }
}

function getProdTechCounts(state) {
    return state.prodTechCounts
}

function fetchProdTechCount(context, params) {
    var existingData = context.getters['facetCount/getProdTechCounts']
    var selectedProdTech = context.getters['facetCount/getProdTechName']
    if (
        existingData != null &&
        selectedProdTech == decodeURIComponent(params.prodTech)
    ) {
        return Promise.resolve()
    } else {
        context.commit('facetCount/setProdTechCounts', 0)
        var totalCountsTemplate = {
            __additional: { q: params.prodTech, tab: 'Products' },
            category: 'products'
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
            totalCountsTemplate['org'] = params.org
            totalCountsTemplate['token'] = params.token
            totalCountsTemplate['searchField'] =
                'dow_producttechnology_hierarchy'
            totalCountsTemplate['returnField'] =
                'dow_producttechnology_hierarchy'
        }

        var url =
            '/' +
            context.state.browser.cookies['languageCode'] +
            '/.dow.facet.counts.servlet.json?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))

        return axios
            .get(url + encoded)
            .then(function (response) {
                if (
                    response != null &&
                    response.data != null &&
                    response.data.values != null
                ) {
                    var count = 0
                    response.data.values.forEach((m) => {
                        if (m.value == decodeURIComponent(params.prodTech)) {
                            count = m.numberOfResults
                        }
                    })
                    context.commit('facetCount/setProdTechCounts', count)
                }
            })
            .catch(function () {})
    }
}

function getProdGroupCounts(state) {
    return state.subMarketCounts
}

function getTechnicalLibraryCounts(state) {
    return state.technicalLibraryCounts
}

function fetchProdGroupCount(context, params) {
    var existingData = context.getters['facetCount/getProdGroupCounts']
    var selectedProdTech = context.getters['facetCount/getProdTechName']
    context.commit(
        'facetCount/setProdTechName',
        params.prodTech != null ? decodeURIComponent(params.prodTech) : ''
    )
    if (
        existingData != null &&
        selectedProdTech == decodeURIComponent(params.prodTech)
    ) {
        return Promise.resolve()
    } else {
        context.commit('facetCount/setProdGroupCounts', [])
        var totalCountsTemplate = {
            __additional: { q: params.prodTech, tab: 'Products' },
            category: 'products'
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
            totalCountsTemplate['org'] = params.org
            totalCountsTemplate['token'] = params.token
            totalCountsTemplate['searchField'] =
                'dow_producttechnology_hierarchy'
        }
        const locale = context.state.browser.cookies['languageCode'] || 'en-us'
        var url = '/' + locale + '/.dow.facet.counts.servlet.json?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))

        return axios
            .get(url + encoded)
            .then(function (response) {
                if (
                    response != null &&
                    response.data != null &&
                    response.data.values != null
                ) {
                    context.commit(
                        'facetCount/setProdGroupCounts',
                        response.data.values
                    )
                }
            })
            .catch(function () {})
    }
}

function fetchTechnicalLibraryCount(context, params) {
    var existingData = context.getters['facetCount/getTechnicalLibraryCounts']
    if (existingData != null) {
        return Promise.resolve()
    } else {
        context.commit('facetCount/setTechnicalLibraryCounts', [])
        var totalCountsTemplate = {
            __additional: { q: params.contentType, tab: 'Support' },
            category: 'products'
        }

        if (params.type != null) {
            totalCountsTemplate['type'] = params.type
            totalCountsTemplate['org'] = params.org
            totalCountsTemplate['token'] = params.token
            totalCountsTemplate['searchField'] = 'dow_contenttype_hierarchy'
        }
        const locale = context.state.browser.cookies['languageCode'] || 'en-us'
        var url = '/' + locale + '/.dow.facet.counts.servlet.json?searchObj='
        var encoded = encodeURIComponent(JSON.stringify(totalCountsTemplate))
        return axios
            .get(url + encoded)
            .then(function (response) {
                if (
                    response != null &&
                    response.data != null &&
                    response.data.values != null
                ) {
                    context.commit(
                        'facetCount/setTechnicalLibraryCounts',
                        response.data.values
                    )
                }
            })
            .catch(function () {})
    }
}
