import ApiClient from '@/plugins/AxiosPlugin'
import AddressFilterHelper from '@/helpers/address-filter-helper'
import store from '.'

export default {
    namespaced: true,
    state: {
        countries: [],
        selectedCountry: window.Granite.I18n.get('country.region'),
        regions: [],
        selectedRegion: window.Granite.I18n.get('state.county.province'),
        postalCode: ''
    },
    mutations: {},
    getters: {},
    actions: {}
}
