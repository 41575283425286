export default class MyProductsDTOService {
    static getMyPartNumbersDTO(item) {
        return {
            partNumber: item.myPartNumber
                ? item.myPartNumber
                : item.materialCode,
            materialName: item.myDescription
                ? item.myDescription
                : item.variantOption?.name,
            myPartNumber: item.myPartNumber,
            myDescription: item.myDescription,
            dowMaterialNumber: item.materialCode,
            dowMaterialName: item.variantOption?.name,
            pdpUrl: item.variantOption?.url,
            isBuyDirect: item.variantOption?.buyDirectFlag,
            baseProductCode: item.baseProductCode
        }
    }

    static getFavoriteBuyingOptionsDTO(item) {
        return {
            partNumber: item.customerMaterialNumber
                ? item.customerMaterialNumber
                : item.materialNumber,
            materialName: item.customerMaterialDescription
                ? item.customerMaterialDescription
                : item.materialName,
            myPartNumber: item.customerMaterialNumber,
            myDescription: item.customerMaterialDescription,
            dowMaterialNumber: item.materialNumber,
            dowMaterialName: item.materialName,
            pdpUrl: item.pdpUrl,
            isBuyDirect: item.isBuyDirectFlag,
            baseProductCode: item.baseProductCode
        }
    }

    static getPreviousPurchasesDTO(item) {
        return {
            partNumber: item.customerMaterialNumber
                ? item.customerMaterialNumber
                : item.materialNumber,
            materialName: item.customerMaterialDescription
                ? item.customerMaterialDescription
                : item.materialDescription,
            myPartNumber: item.customerMaterialNumber,
            myDescription: item.customerMaterialDescription,
            dowMaterialNumber: item.materialNumber,
            dowMaterialName: item.materialDescription,
            pdpUrl: item.pdpUrl,
            isBuyDirect: true,
            baseProductCode: item.baseProductCode
        }
    }

    static getFavoriteProductsDTO(item) {
        return {
            code: item.code,
            productName: item.name,
            productDescription: item.description,
            pdpUrl: item.url,
            authorizeToAccess: item.authorizeToAccess
        }
    }
}
