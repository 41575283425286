import { hashCode } from '@platform/utils'
import axios from 'axios'

const MAX_TDS_ITERATIONS = 2000

export default {
    namespaced: true,
    state: {
        pdpData: {
            buyingSampleOptions: null,
            tradeProducts: [],
            topTechnicalContent: null
        },
        sdsData: {},
        isTDSLoading: false,
        isSDSLoading: false,
        technicalDataSheets: [],
        tradeProductsLoading: true
    },
    mutations: {
        setFDSDocs: function (state, payload) {
            state.fdsDocs = payload
        },
        setTopTechnicalContent: function (state, payload) {
            var contentTypes = []

            payload.values.forEach(function (type) {
                if (type.value.includes('|||')) {
                    var splitTypes = type.value.split('|||')
                    splitTypes.forEach(function (splitType) {
                        var newType = {
                            lookupValue: splitType,
                            numberOfResults: type.numberOfResults,
                            value: splitType
                        }
                        contentTypes.push(newType)
                    })
                } else {
                    contentTypes.push(type)
                }
            })
            //contentTypes = payload.values;

            contentTypes.forEach(function (contentType) {
                contentType['count'] = contentType.numberOfResults
                contentType['link'] =
                    '#q=' +
                    state.pdpData.productCode +
                    '&t=Suport&sort=relevancy&f:@dow_contenttype_hierarchy=[' +
                    contentType.value +
                    ']'
            })

            this.commit('techContent/setTechnicalContentSummary', payload)

            contentTypes = contentTypes.sort(function (contentA, contentB) {
                if (contentA.count > contentB.count) return -1
                if (contentA.count < contentB.count) return 1
                if (contentA.value > contentB.value) return 1
                if (contentA.value < contentB.value) return -1
            })

            state.pdpData.topTechnicalContent = contentTypes.splice(0, 3)
        },
        resetTopTechnicalContent: function (state, payload) {
            state.pdpData.topTechnicalContent = payload
        },
        setTechnicalContentSummary: function (state, payload) {
            var general = {}
            var total = 0
            general = payload.values

            general.forEach(function (contentType) {
                if (contentType.value.includes('|||')) {
                    var splitTypes = contentType.value.split('|||')
                    splitTypes.forEach(function (splitType) {
                        total += contentType.numberOfResults
                    })
                } else {
                    total += contentType.numberOfResults
                }
            })

            general.total = total
            general.current_link =
                '#q=' + state.pdpData.productCode + '&t=Support&sort=relevancy'

            if (!!general) {
                state.pdpData.technicalContentSummary = {
                    count: general.total,
                    link: general.current_link
                }
            }
        },
        resetTechnicalContentSummary: function (state, payload) {
            state.pdpData.technicalContentSummary = payload
        },
        setTradeProducts: function (state, payload) {
            state.pdpData.tradeProducts = payload
        },
        setTradeProductsLoading: function (state, payload) {
            state.tradeProductsLoading = payload
        },
        setTechnicalDataSheets: function (state, payload) {
            state.technicalDataSheets = payload
        },
        setSdsData: function (state, payload) {
            state.sdsData = payload
        }
    },
    getters: {
        getTopTechnicalContent: function (state) {
            return state.pdpData.topTechnicalContent
        },
        getTechnicalContentData: function (state) {
            return getTechnicalContentData(state)
        }
    },
    actions: {
        getSDSData: getSDSData,
        getTechnicalDataSheets: getTechnicalDataSheets,
        fetchAllTechnicalContent: function (state, params) {
            fetchAllTechnicalContent(this, params)
        },
        fetchTradeProducts: function (state, params) {
            fetchTradeProducts(this, params)
        },
        resetTechContent: function (state) {
            resetTechContent(this)
        }
    }
}

function resetTechContent(context) {
    context.commit('techContent/setTradeProducts', [])
    context.commit('techContent/setTechnicalDataSheets', [])
    context.commit('techContent/resetTopTechnicalContent', null)
    context.commit('techContent/resetTechnicalContentSummary', {})
    context.commit('techContent/setSdsData', {})
}

function getTechnicalDataSheets({ state, commit }, payload) {
    var productCode = payload
    if (productCode) {
        state.isTDSLoading = true
        axios
            .get('.dow.tds.json', {
                params: {
                    productId: productCode
                }
            })
            .then(function (response) {
                var technicalDataSheets = []
                for (
                    var i = 0;
                    i < response.data.length && i <= MAX_TDS_ITERATIONS;
                    i++
                ) {
                    var tds = response.data[i]
                    technicalDataSheets.push({
                        title: tds.title ? tds.title : '',
                        pathLink: tds.pathLink
                            ? 'document-viewer.html?docPath=' +
                              tds.pathLink +
                              '&useRequestPath=true'
                            : '',
                        languageCode: tds.languageCode ? tds.languageCode : '',
                        displayLanguage: tds.displayLanguage
                            ? tds.displayLanguage
                            : '',
                        isChecked: false
                    })
                }
                commit('setTechnicalDataSheets', technicalDataSheets)
                state.isTDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting TDS Data')
                state.isTDSLoading = false
            })
    }
}

function fetchAllTechnicalContent(context, params) {
    var existingData = context.getters['techContent/getTopTechnicalContent']

    if (existingData != null) {
        // Data already exists
    } else {
        var technicalContentTemplate = {
            __additional: { q: params.productCode, tab: 'support' }
        }

        if (params.type != null) {
            technicalContentTemplate['type'] = params.type
            technicalContentTemplate['org'] = params.org
            technicalContentTemplate['token'] = params.token
        }

        var url =
            '/' + context.state.browser.cookies['languageCode'] ||
            'en-us' + '/.pdp.search.servlet.json?searchObj=' // call to PdpSearchServlet
        // var url = '/en-us/.dow.pdp.search.servlet.json?searchObj=' // TODO: Fix locale
        var encoded = encodeURIComponent(
            JSON.stringify(technicalContentTemplate)
        )

        axios
            .get(url + encoded)
            .then(function (response) {
                context.commit(
                    'techContent/setTopTechnicalContent',
                    response.data
                )
            })
            .catch(function (error) {
                // Error
            })
    }
}

function getTechnicalContentData(state) {
    return {
        tradeProducts: state.pdpData.tradeProducts,
        websiteLanguages: [], //
        contactUsLink: '', //
        pIDocumentEnabled: true, // ?
        hasRelatedResources: false //
    }
}

function fetchTradeProducts(context, productCode) {
    var existingData = context.getters['techContent/getTradeProducts']

    if (existingData != null) {
        // Data already exists
    } else {
        axios
            .get('.dow.trade.products.servlet.' + productCode + '.json')
            .then(function (response) {
                context.commit('techContent/setTradeProducts', response.data)
                context.commit('techContent/setTradeProductsLoading', false)
            })
            .catch(function (error) {
                console.error('Error fetching the trade products')
                context.commit('techContent/setTradeProductsLoading', false)
            })
    }
}

function getSDSData({ state }, payload) {
    var selectedTradeProduct = payload.selected

    if (selectedTradeProduct && selectedTradeProduct != '-1') {
        state.isSDSLoading = true
        var sdsURL =
            '.dow.sds.doc.json' +
            '?tradeProduct=' +
            selectedTradeProduct +
            '&product=' +
            payload.productCode
        axios
            .get(sdsURL)
            .then(function (response) {
                state.sdsData = response.data.sdsData
                state.isSDSLoading = false
            })
            .catch(function (error) {
                console.log('Error getting SDS Data')
                state.isSDSLoading = false
            })
    }
}
