import ContractDetailsDataHelper from '@/helpers/contract-details-data-helper'
export default class ContractDetailsDTOService {
    static getContractDetailsDTO(contractDetails) {
        return {
            salesOrganization: contractDetails.salesOrganization,
            distributionChannel: contractDetails.distributionChannel,
            division: contractDetails.division,
            contractNumber: contractDetails.contractNumber,
            contractName: contractDetails.contractName,
            soldTo: contractDetails.soldTo,
            shipTo: contractDetails.shipTo,
            shippingConditions: contractDetails.shippingConditions,
            contractCreateDate: contractDetails.contractCreateDate,
            contractStartDate: contractDetails.contractStartDate,
            contractExpirationDate: contractDetails.contractExpirationDate,
            documents: contractDetails.documents
                ? contractDetails.documents
                : [],
            contractItems: contractDetails.contractItems,
            isOrderProcessing: false,
            contractStatus: ContractDetailsDataHelper.getContractStatus(
                contractDetails,
                contractDetails.contractItems?.[0]
            ),
            contractActionText: ContractDetailsDataHelper.getContractActionText(
                contractDetails,
                contractDetails.contractItems?.[0]
            ),
            contractActionUrl: ContractDetailsDataHelper.getContractActionUrl(
                contractDetails,
                contractDetails.contractItems?.[0]
            ),
            canApproveContract:
                ContractDetailsDataHelper.canApproveContract(contractDetails),
            showContractItemInDashboard: 
                ContractDetailsDataHelper.showContractItemInDashboard(contractDetails.contractItems?.[0])
        }
    }
}
