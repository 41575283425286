import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    var textMediaElements = document.querySelectorAll('.cmp-text-media');
    textMediaElements.forEach(function (element) {
      var mediaElement = element.querySelector('.cmp-text-media__media');
      if (mediaElement.classList.contains('cmp-text-media__media__position_top')) {
        element.style.flexDirection = 'column';
      }
    });
    var buttons = this.cmpEl.querySelectorAll("a");
    var title = this.cmpEl.querySelector("div.title")?.innerText;
    buttons.forEach(el => {
      if (!el.ariaLabel) {
        if (title) {
          el.ariaLabel = el.text.trim() + ": " + title;
        } else {
          el.ariaLabel = el.text.trim();
        }
      }
    });
  }
};