import ApiClient from '@/plugins/AxiosPlugin'
import OrderManagementDTOService from '@/dow-platform/components/commerce/myAccount/helper/order-management-dto-service'
import DeliveryFiltersDefaultValueHelper from '@/dow-platform/components/commerce/myAccount/helper/filter-helpers/delivery-filters-default-value-helper'

export const orderManagementExportOptions = {
    namespaced: true,
    modules: {
        order: {
            namespaced: true,
            state: {
                exportResource: 'exportOrderDetail',
                isOrder: true,
                exportOptions: [],
                hasExportError: false
            }
        },
        delivery: {
            namespaced: true,
            state: {
                exportResource: 'exportDeliveryDetail',
                isOrder: false,
                exportOptions: [],
                hasExportError: false
            }
        }, 
        coa: {
            namespaced: true,
            state: {
                exportResource: 'exportCoaDetail',
                isOrder: false,
                exportOptions: [],
                hasExportError: false
            }
        }
    },
    mutations: {
        setExportOptions: function (state, payload) {
            const subModule = payload.subModule
            const exportOptions = payload.exportOptions
            state[subModule].exportOptions = exportOptions
        },
        setHasExportError: function (state, payload) {
            const subModule = payload.subModule
            const value = payload.value
            state[subModule].hasExportError = value
        }
    },
    actions: {
        exportItems: function (context, config) {
            exportItems(
                context,
                config.subModule,
                config.opts,
                config.saveDefault
            )
        }
    }
}

function exportItems(context, subModule, opts, saveDefault) {
    const activeFilters =
        context.rootGetters[`myAccountItems/activeFilters`](subModule)
    const isCustomDateRange =
        context.rootGetters['myAccountItems/isCustomDateRange'](subModule)
    activeFilters.fromSubModule = subModule
    const filters =
        OrderManagementDTOService.getOrderManagementFilterDTO(activeFilters)
    filters.toDate =
        subModule === 'delivery' && !isCustomDateRange
            ? DeliveryFiltersDefaultValueHelper.getDefaultDeliveryToDate()
            : filters.toDate
    const filtersJson = JSON.stringify(filters)
    const exportResource = context.state[subModule].exportResource
    const isOrder = context.state[subModule].isOrder
    const url = context.rootState.settings.export_data
    const exportItemsData = isOrder ? context.rootState.myAccountItems.order.items : context.rootState.myAccountItems.delivery.items;
    const numbers = exportItemsData.map(exportItemsData => exportItemsData.orderNumber || exportItemsData.deliveryNumber); 
    context.commit('loading/setLoading', true, { root: true })
    context.state[subModule].hasExportError = false
    //build form data for submission
    var formData = new FormData()
    formData.append('filters', filtersJson)
    formData.append('exportNumbers', JSON.stringify(numbers))
    formData.append('isOrder', isOrder)
    formData.append('saveDefault', saveDefault)
    if (opts) {
        formData.append('exportKeys', opts)
    }
    ApiClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((res) => {
            const data = res.data
            const exportDetail = data[exportResource]
            if (exportDetail) {
                handleExportData(context, subModule, data)
            } else {
                handleExportError(context, subModule, data)
            }
        })
        .catch(function (error) {
            const errorData = error.data
            handleExportError(context, subModule, errorData)
        })
}

function handleExportData(context, subModule, data) {
    const exportResource = context.state[subModule].exportResource
    const downloadForm = generateDownloadForm(context, data, exportResource)
    triggerFormSubmit(downloadForm)
    context.commit('loading/setLoading', false, { root: true })
}

function generateDownloadForm(context, data, exportResource) {
    const downloadForm = initExportForm(context)
    setDownloadFormFields(downloadForm, data, exportResource)

    return downloadForm
}

function initExportForm(context) {
    const downloadForm = document.createElement('form')
    downloadForm.action = context.rootState.settings.download_export
    downloadForm.method = 'POST'
    downloadForm.style.display = 'none'
    return downloadForm
}

function setDownloadFormFields(downloadForm, data, exportResource) {
    if (data['exportArrayKey']) {
        addFormField(downloadForm, 'exportArrayKey', data['exportArrayKey'])
    }
    addFormField(downloadForm, 'isOrder', JSON.stringify(data['isOrder']))
    addFormField(
        downloadForm,
        exportResource,
        JSON.stringify(data[exportResource])
    )
}

function addFormField(form, key, value) {
    const field = document.createElement('input')
    field.value = value
    field.name = key
    form.appendChild(field)
    field.style.display = 'none'
}

function triggerFormSubmit(downloadForm) {
    document.body.appendChild(downloadForm)
    downloadForm.submit()
}

function handleExportError(context, subModule, data) {
    context.state[subModule].hasExportError = true
    console.error('Error received from export call', data)
    context.commit('loading/setLoading', false, { root: true })
}
