import ApiClient from '../plugins/AxiosPlugin'
import { buildSearchEngine } from '@coveo/headless'
import { buildRecommendationEngine } from '@coveo/headless/recommendation'

const COVEO_SEARCH_EVENT = 'CoveoSearchEvent'

export default {
    namespaced: true,
    state: {
        org: '',
        token: '',
        hub: '',
        sourceType: '',
        engine: null,
        engines: {},
        results: {},
        recommendationEngine: null
    },
    mutations: {
        tokenAndOrg: setTokenAndOrg,
        multiTokenAndOrg: setMultiTokenAndOrg,
        engine: buildEngine,
        recommendationEngine: buildContentRecommendationEngine,
        removeEngine: removeEngine,
        updateResultList: updateResultList
    },
    actions: {
        fetchSearchToken,
        updateEngine,
        getEngine,
        getRecommendationEngine,
        [COVEO_SEARCH_EVENT]: { root: true, handler() {} }
    },
    getters: {}
}

function validateToken(storedToken, params) {
    if(!storedToken)
        return false
    const parsedToken = JSON.parse(storedToken)
    const foundToken = parsedToken?.find((item) => {
        return (item.hub == params.hub && item.customFilter == params.customFilter && item.showAll == params.showAll)
    })
    if(!foundToken)
        return false

    if(foundToken?.resultParams?.token?.indexOf('.') > 0 && foundToken?.resultParams?.token?.split('.').length >= 2){
        try {
            const tokenJson = JSON.parse(atob(foundToken.resultParams.token.split('.')[1]))
            const expiryDate = tokenJson.hasOwnProperty("exp") ? tokenJson.exp : "no-bearer"
            if(expiryDate != "no-bearer" && Date.now() / 1000 >= expiryDate){
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return true
}

function fetchSearchToken(context, params) {
    const storedToken = localStorage.getItem('dowComCoveoToken')
    if(validateToken(storedToken, params)) {
        const parsedToken = JSON.parse(storedToken)
        const foundToken = parsedToken.find((item) => {
            return (item.hub == params.hub && item.customFilter == params.customFilter && item.showAll == params.showAll)
        })
        if (!params.multi) {
            context.commit('tokenAndOrg', foundToken.resultParams)
        } else {
            context.commit('multiTokenAndOrg', foundToken.resultParams)
        }

        return Promise.resolve({
            data: foundToken.resultParams
        })
    } else {
        return ApiClient.get('/.dow.search.token.servlet.json', {
            params: {
                type: params.hub,
                customFilter: params.customFilter,
                showAll: params.showAll
            }
        }).then((result) => {
            var resultParams = {
                org: result.data.org,
                token: result.data.token,
                sourceType: params.sourceType,
                hub: params.hub
            }
            const newToken = {
                ...params,
                resultParams: resultParams
            }
            let storedToken = []
            if(localStorage.getItem('dowComCoveoToken')) {
                storedToken = JSON.parse(localStorage.getItem('dowComCoveoToken'))
                const existingTokenIndex = storedToken.findIndex((item) => {
                    return (item.hub == params.hub && item.customFilter == params.customFilter && item.showAll == params.showAll)
                })
                if(existingTokenIndex == -1)
                    storedToken.push(newToken)
                else
                    storedToken[existingTokenIndex] = newToken
            } else {
                storedToken.push(newToken)
            }
            localStorage.setItem('dowComCoveoToken', JSON.stringify(storedToken))
            if (!params.multi) {
                context.commit('tokenAndOrg', resultParams)
            } else {
                context.commit('multiTokenAndOrg', resultParams)
            }
            return result
        })
    }
}

function setTokenAndOrg(state, params) {
    if (params && Object.getOwnPropertyNames(params)) {
        state.org = params.org
        state.token = params.token
        state.sourceType = params.sourceType
        state.hub = params.hub
    }
}

function setMultiTokenAndOrg(state, params) {
    if (params && Object.getOwnPropertyNames(params)) {
        if (!state.engines[params.hub]) {
            state.engines[params.hub] = {
                token: params.token,
                org: params.org,
                sourceType: params.sourceType
            }
        }
    }
}

function updateEngine(context) {
    var params = {
        org: context.state.org,
        token: context.state.token
    }
    context.commit('engine', params)
}

function removeEngine(state) {
    state.engine = null
}

function buildEngine(state, params) {
    const analyticsClientMiddleware = (eventName, payload) => {
        if (eventName == 'search') {
            eventName = 'CoveoSearchEvent'
        } else if (eventName == 'click') {
            eventName = 'CoveoClickEvent'
        }
        window.adobeDataLayer.push({
            event: eventName,
            coveoAnalyticsEventData: payload
        })
        return payload
    }
    if (!state.engine && !params.multi) {
        const search = {
            searchHub: state.hub
        }
        const headlessEngine = buildSearchEngine({
            configuration: {
                organizationId: params.org,
                accessToken: params.token,
                search: search,
                analytics: {
                    analyticsClientMiddleware
                }
            }
        })

        state.engine = headlessEngine
    } else if (params.multi) {
        if (state.engines[params.hub] && !state.engines[params.hub].engine) {
            const search = {
                searchHub: params.hub
            }
            const headlessEngine = buildSearchEngine({
                configuration: {
                    organizationId: state.engines[params.hub].org,
                    accessToken: state.engines[params.hub].token,
                    search: search,
                    analytics: {
                        analyticsClientMiddleware
                    }
                }
            })

            state.engines[params.hub].engine = headlessEngine
        }
    }
}

function getEngine({ dispatch, state, commit }, { hub }) {
    return dispatch('fetchSearchToken', { hub, multi: true }).then((r) =>
        commit('engine', { multi: true, hub })
    )
}

function updateResultList(state, { cmp, results }) {
    state.results[cmp] = results
}

function buildContentRecommendationEngine(state, params){
    const analyticsClientMiddleware = (eventName, payload) => {
        if (eventName == 'search') {
            eventName = 'CoveoSearchEvent'
        } else if (eventName == 'click') {
            eventName = 'CoveoClickEvent'
        }
        window.adobeDataLayer.push({
            event: eventName,
            coveoAnalyticsEventData: payload
        })
        return payload
    }

    if (state.engines[params.hub] && !state.engines[params.hub].engine) {
        const search = {
            searchHub: params.hub
        }
        const headlessRecommendationEngine = buildRecommendationEngine({
            configuration: {
                organizationId: state.engines[params.hub].org,
                accessToken: state.engines[params.hub].token,
                searchHub: params.hub,
                analytics: {
                    analyticsClientMiddleware
                },
                pipeline: 'Dow.com Content Recommender'
            }
        })

        state.engines[params.hub].engine = headlessRecommendationEngine
    }
}

function getRecommendationEngine({dispatch, commit}, { hub }) {
    return dispatch('fetchSearchToken', { hub, multi:true }).then((r) => 
        commit('recommendationEngine', { hub })
    )
}
