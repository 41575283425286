import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "dc-form__message"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  class: "cmp-site-login__form-actions"
};
const _hoisted_4 = {
  type: "submit",
  class: "btn"
};
const _hoisted_5 = ["href"];
const _hoisted_6 = {
  key: 1
};
import { useStore } from 'vuex';
import DcTextInput from '../forms/DcTextInput.vue';
import CheckBox from '../../commerce/blocks/input-elements/checkbox/CheckBox.vue';
import LoadingDots from '../forms/DcLoadingDots.vue';
import DcAlert from '../forms/DcAlert.vue';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';
import { ref, reactive, computed, watch } from 'vue';

// Store

export default {
  __name: 'LoginForm',
  props: {
    termsSelector: {
      type: String,
      default: 'terms-modal'
    }
  },
  setup(__props) {
    const store = useStore();

    // Remember_me cookie
    const rememberCookie = store.getters['browser/getCookiebyName']({
      name: 'remember_me_user'
    });

    //Props
    const props = __props;

    // Data
    const form = reactive({
      username: rememberCookie ? rememberCookie : '',
      password: '',
      remember: rememberCookie ? true : false
    });
    const labels = ref({
      loginNotificationMessage: window.Granite.I18n.get('timeout.login.notification.message'),
      formUsername: window.Granite.I18n.get('login.username'),
      formPassword: window.Granite.I18n.get('password'),
      formRememberMe: window.Granite.I18n.get('remember.me.Title'),
      formSubmit: window.Granite.I18n.get('button.submit.Title'),
      formForgotPassword: window.Granite.I18n.get('login.forgotPassword'),
      loginRedirectionMessage: window.Granite.I18n.get('login.redirectionMessage'),
      loginErrorTitle: window.Granite.I18n.get('login.failed.Title'),
      loginErrorMessage: window.Granite.I18n.get('login.error.exception.message'),
      loggedInMessage: window.Granite.I18n.get('you.are.logged.in'),
      logout: window.Granite.I18n.get('logout')
    });

    // Computed
    const loginData = computed(() => {
      const formData = new FormData();
      formData.append('j_username', form.username);
      formData.append('j_password', form.password);
      formData.append('remember_me_user', form.remember);
      return formData;
    });
    watch(() => store.state.user.isAuthenticated, (newValue, oldValue) => {
      var createAccountBtn = document.getElementsByClassName('cmp-site-login__info-action');
      if (newValue && createAccountBtn) {
        for (var i = 0; i < createAccountBtn.length; i++) {
          createAccountBtn[i].style.display = 'none';
        }
      } else if (createAccountBtn) {
        for (var i = 0; i < createAccountBtn.length; i++) {
          createAccountBtn[i].style.display = 'block';
        }
      }
    }, {
      deep: true
    });
    const userLoggedOut = computed(() => store.state.user.hasTimedOut);
    const isAuthenticated = computed(() => store.state.user.isAuthenticated);
    const errors = computed(() => store.state.user.authErrors);
    const activeError = computed(() => errors.value.length ? [...errors.value].pop() : null);
    const termsAccepted = computed(() => {
      return store.state.user.termsAccepted;
    });
    const loading = computed(() => {
      return store.state.user.loading;
    });
    const isRedirecting = computed(() => {
      return store.state.user.redirecting;
    });
    const forgetPasswordUrl = computed(() => {
      return `/${store.state.browser.languageCode}${store.state.settings.FORGET_PASSWORD_PAGE}`;
    });

    // Methods
    const rememberMeCallback = async () => {
      if (form.remember == true && isAuthenticated) {
        var date = new Date();
        date.setDate(date.getDate() + 365);
        store.dispatch('browser/setBrowserCookie', {
          key: 'remember_me_user',
          value: form.username,
          attributes: {
            expires: date.toUTCString()
          }
        });
      } else {
        if (rememberCookie) store.dispatch('browser/removeBrowserCookie', {
          name: 'remember_me_user'
        });
      }
    };
    const signIn = async () => {
      const payload = {
        formData: loginData.value,
        awaitCallback: rememberMeCallback
      };
      await store.dispatch('user/loginUser', payload);
      document.querySelector('body').style.removeProperty('overflow');
    };
    return (_ctx, _cache) => {
      const _component_DcForm = _resolveComponent("DcForm");
      return _openBlock(), _createElementBlock(_Fragment, null, [!isAuthenticated.value ? (_openBlock(), _createBlock(_component_DcForm, {
        key: 0,
        class: _normalizeClass(["dc-form", {
          loading: loading.value
        }]),
        onSubmit: signIn
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [userLoggedOut.value ? (_openBlock(), _createBlock(DcAlert, {
          key: 0,
          type: "warning"
        }, {
          message: _withCtx(() => [_createElementVNode("p", null, _toDisplayString(labels.value.loginNotificationMessage), 1)]),
          _: 1
        })) : _createCommentVNode("", true), activeError.value ? (_openBlock(), _createBlock(DcAlert, {
          key: 1,
          type: "error"
        }, {
          message: _withCtx(() => [_createElementVNode("p", null, [_createElementVNode("strong", null, _toDisplayString(activeError.value?.data?.error_type || labels.value.loginErrorTitle) + ":  ", 1), _createElementVNode("span", {
            innerHTML: activeError.value?.data?.message || labels.value.loginErrorMessage
          }, null, 8, _hoisted_2)])]),
          _: 1
        })) : _createCommentVNode("", true)]), _createVNode(DcTextInput, {
          name: "username",
          label: labels.value.formUsername,
          placeholder: labels.value.formUsername,
          type: "text",
          modelValue: form.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.username = $event),
          rules: "required"
        }, null, 8, ["label", "placeholder", "modelValue"]), _createVNode(DcTextInput, {
          name: "password",
          label: labels.value.formPassword,
          placeholder: labels.value.formPassword,
          type: "password",
          modelValue: form.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.password = $event),
          rules: "required"
        }, null, 8, ["label", "placeholder", "modelValue"]), _createVNode(CheckBox, {
          name: "remember",
          classes: 'checkbox',
          label: labels.value.formRememberMe,
          modelValue: form.remember,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.remember = $event)
        }, null, 8, ["label", "modelValue"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", _hoisted_4, _toDisplayString(labels.value.formSubmit), 1), _createElementVNode("a", {
          href: forgetPasswordUrl.value,
          class: "btn--underline-blue"
        }, _toDisplayString(labels.value.formForgotPassword), 9, _hoisted_5)]), loading.value ? (_openBlock(), _createBlock(LoadingDots, {
          key: 0
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["class"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [isRedirecting.value ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("h4", null, _toDisplayString(labels.value.loginRedirectionMessage), 1), _createVNode(LoadingDots)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("h3", null, _toDisplayString(labels.value.loggedInMessage), 1), _createElementVNode("button", {
        class: "btn",
        type: "button",
        onClick: _cache[3] || (_cache[3] = $event => _unref(store).dispatch('user/logoutUser'))
      }, _toDisplayString(labels.value.logout), 1)], 64))])), !termsAccepted.value ? (_openBlock(), _createBlock(TermsAndConditionsModal, {
        key: 2,
        loginData: loginData.value,
        termsSelector: __props.termsSelector,
        loginCallback: rememberMeCallback
      }, null, 8, ["loginData", "termsSelector"])) : _createCommentVNode("", true)], 64);
    };
  }
};