import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dc-form__group"
};
const _hoisted_2 = {
  class: "dc-form__checkbox"
};
const _hoisted_3 = ["type", "value", "id", "checked"];
const _hoisted_4 = ["for", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VeeField = _resolveComponent("VeeField");
  const _component_VeeErrorMessage = _resolveComponent("VeeErrorMessage");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_VeeField, _mergeProps({
    name: $props.name,
    value: $props.value
  }, _ctx.$attrs), {
    default: _withCtx(({
      field,
      errorMessage
    }) => [_createElementVNode("input", _mergeProps({
      type: _ctx.$attrs.type,
      value: $props.value,
      class: {
        invalid: !!errorMessage
      }
    }, field, {
      class: "dc-form__checkbox-input",
      id: $options.randomId,
      checked: $props.checked
    }), null, 16, _hoisted_3), _createElementVNode("span", {
      class: _normalizeClass(["dc-form__checkbox-check", {
        invalid: !!errorMessage
      }])
    }, null, 2), _createElementVNode("label", {
      for: $options.randomId,
      class: "dc-form__checkbox-label",
      innerHTML: $props.label
    }, null, 8, _hoisted_4)]),
    _: 1
  }, 16, ["name", "value"])]), !$props.errorText ? (_openBlock(), _createBlock(_component_VeeErrorMessage, {
    key: 0,
    name: $props.name,
    as: "p",
    class: "dc-form__error"
  }, null, 8, ["name"])) : (_openBlock(), _createBlock(_component_VeeErrorMessage, {
    key: 1,
    name: $props.name,
    as: "p",
    class: "dc-form__error"
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($props.errorText), 1)]),
    _: 1
  }, 8, ["name"]))]);
}