export default class i18nHelper {
    static getI18nMessage(key, params) {
        let label = window.Granite.I18n.get(key)

        if (params) {
            if(typeof(params) === 'string')
                params = [params];
            params.forEach((param, index) => {
                label = label.replace('{' + index.toString() + '}', param)
            })
        }
        return label
    }

    static geti18nDisplayValue(val) {
        const stringKey = `${val.replaceAll(' ', '.').toLowerCase()}.Title`
        const i18nString = window.Granite.I18n.get(stringKey)
        return i18nString === stringKey ? val : i18nString
    }
}
