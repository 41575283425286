import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Truck = _resolveComponent("Truck");
  const _component_SampleCartNotification = _resolveComponent("SampleCartNotification");
  const _component_Cart = _resolveComponent("Cart");
  const _component_NavMenu = _resolveComponent("NavMenu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Truck), !$options.isSearchChecker ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: "#sample-cart-notification"
  }, [_createVNode(_component_SampleCartNotification, {
    "cart-page-url": $options.sampleCartLink
  }, null, 8, ["cart-page-url"])])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.allcardWrappers, (child, i) => {
    return _openBlock(), _createElementBlock("div", {
      key: i
    }, [$options.hasCartItems ? (_openBlock(), _createBlock(_Teleport, {
      key: 0,
      to: child
    }, [_createElementVNode("div", {
      class: _normalizeClass($data.isButtonHovered ? 'visible' : 'hidden')
    }, [_createVNode(_component_Cart)], 2)], 8, ["to"])) : _createCommentVNode("", true)]);
  }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.navItemsWrapper, (child, i) => {
    return _openBlock(), _createElementBlock("div", {
      key: i
    }, [$data.isReady ? (_openBlock(), _createBlock(_Teleport, {
      key: 0,
      to: child
    }, [_createVNode(_component_NavMenu, {
      onReauthRequired: _cache[0] || (_cache[0] = $event => $options.displayReauthModalMyAccount(true)),
      isUserLoggedIn: $options.isLoggedIn
    }, null, 8, ["isUserLoggedIn"])], 8, ["to"])) : _createCommentVNode("", true)]);
  }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.cartWrapper, (child, i) => {
    return _openBlock(), _createElementBlock("div", {
      key: i
    }, [(_openBlock(), _createBlock(_Teleport, {
      to: child
    }, [_createElementVNode("span", null, _toDisplayString($options.totalCartCount || 0), 1)], 8, ["to"]))]);
  }), 128))], 64);
}