import CoreComponent from '../../core/CoreComponent.vue';
import 'swiper/css';
import 'swiper/css/grid';
export default {
  extends: CoreComponent,
  mounted() {
    const smartCrop = this.cmpEl.dataset.smartcrop;
    const cardimages = this.cmpEl.querySelectorAll('.card-image');
    cardimages.forEach(image => {
      let pureUrl = image.src.split('?')[0];
      let originalImageSrc = '';
      let cropedImageSrc = '';
      cropedImageSrc = `${pureUrl}:${smartCrop}?fmt=webp`;
      originalImageSrc = `${image.src}`;
      image.src = cropedImageSrc;
      image.addEventListener('error', function handleError() {
        image.src = `${originalImageSrc}`;
      }, {
        once: true
      });
    });
    const images = this.cmpEl.querySelectorAll('img[src*="scene7"][src*="dynamicmedia.dow.com"]');
    images.forEach(image => {
      s7responsiveImage(image);
    });

    // //File Download Tracking
    // detectFileDownloadLink(this.cmpEl)
  },
  beforeUnmount() {},
  methods: {}
};