export function getParameter(param) {
    var url = window.location.search.substring(1),
        paramValues = url.split('&'),
        paramName,
        i

    for (i = 0; i < paramValues.length; i++) {
        paramName = paramValues[i].split('=')

        if (paramName[0] === param && param === 'q') {
            return paramName[1] === undefined
                ? true
                : decodeURIComponent(paramName[1].replace(/\+/g, ' '))
        }
        if (paramName[0] === param) {
            return paramName[1] === undefined
                ? true
                : decodeURIComponent(paramName[1])
        }
    }

    return ''
}

export function getURLParameter(urlSearch) {
    return new URLSearchParams(urlSearch)
}

export function urlBelongsToApprovedDomains(redirectUrl, domains) {
    //extract the domain name from the redirect URL
    var extractedDomainName = extractDomainName(redirectUrl)
    //if domain matches approved list , return true
    for (var i = 0; i < domains.length; i++) {
        if (extractedDomainName.indexOf(domains[i]) > -1) {
            return true
        }
    }
    return false
}

export function extractDomainName(url) {
    var domainname
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        domainname = url.split('/')[2]
    } else {
        domainname = url.split('/')[0]
    }
    //find & remove port number
    domainname = domainname.split(':')[0]
    //find & remove "?"
    domainname = domainname.split('?')[0]

    //remove www
    domainname = domainname.replace('www.', '').replace('www-' , '');
    return domainname
}

export function documentViewerRedirect(redirectUrl, domains) {
    if (urlBelongsToApprovedDomains(redirectUrl , domains)) {
        window.location.href = redirectUrl
    } 
}

const allowedProtocols = ['http:', 'https:', 'ftp:', 'mailto:']
export function purifyLink(link) {
    link = link.replaceAll('javascript', '')
    let url
    try {
        url = new URL(link)
    } catch (error) {
        console.log(error)
        return '#'
    }
    if (!allowedProtocols.includes(url.protocol)) return '#'
    return url.toString()
}
