import { createStore } from 'vuex'

import leadScoring from './lead-scoring'
import favorite from './favorite'
import loading from './loading'
import components from './components'
import browser from './browser'
import comparison from './comparison'
import user from './user'
// import i18n from './i18n'
// import modal from './modal'
import search from './search'
import address from './address'
import techContent from './techContent'
import { featureFlag } from './feature-flag'
import pdp from './pdp'
import product from './product'
import { myAccountItems } from './my-account-items'
import { orderManagementExportOptions } from './order-management-export-options'
import page from './page'
import settings from './settings'
import assets from './assets'
import deliveryDetails from './delivery-details'
import orderDetails from './order-details'
import sampleCart from './sample-cart'
import beautyCare from './beauty-care'
import distributor from './distributor'
import facetCount from './facet-count'
import form from "./form";

export default createStore({
    actions: {
        dataLayerUpdate({ commit }, { component }) {
            if (component) commit('components/update', component)
        },
        dataLayerEvent({ dispatch }, event) {
            if (event?.event) dispatch(event.event, event)
        },
        trackLink: trackLink,
        trackCmpInteraction
    },
    modules: {
        loading,
        components,
        favorite,
        browser,
        comparison,
        user,
        leadScoring,
        // i18n,
        // modal,
        search,
        address,
        techContent,
        featureFlag,
        pdp,
        product,
        myAccountItems,
        orderManagementExportOptions,
        page,
        settings,
        assets,
        order: orderDetails,
        delivery: deliveryDetails,
        sampleCart,
        beautyCare,
        distributor,
        facetCount,
        form
    }
})

function trackLink(context, link) {
    window.adobeDataLayer = window.adobeDataLayer || []

    window.adobeDataLayer.push({
        event: 'cmp:click',
        eventInfo: {
            path: link.name
        },
        link: {
            linkName: link.name,
            linkPosition: link.position || link.component,
            linkPage: context.state.page.pageInfo.pageName
        }
    })
}

function trackCmpInteraction(context, { event, cmpId, data }) {
    window.adobeDataLayer = window.adobeDataLayer || []
    window.adobeDataLayer.push({
        event,
        eventInfo: {
            path: `component.${cmpId}`
        },
        ...data
    })
}
