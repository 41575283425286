import i18nHelper from './i18n-helper'
export default class InvoiceManagementDTOService {
    static getInvoiceDTO(invoice) {
        return {
            invoiceNo: invoice.invoiceNo,
            invoiceType: invoice.invoiceType,
            invoiceTypeName: invoice.invoiceName,
            invoiceDueDate: invoice.dueDate ? new Date(invoice.dueDate) : null,
            invoiceIssueDate: invoice.issueDate
                ? new Date(invoice.issueDate)
                : null,
            inArrears: invoice.formattedArrearDays
                ? parseInt(invoice.formattedArrearDays)
                : null,
            formattedArrearDays: invoice.formattedArrearDays
                ? invoice.formattedArrearDays +
                  ' ' +
                  i18nHelper.getI18nMessage('searchComponent.lastxxDays.next')
                : null,
            invoiceTerms: invoice.paymentTermName,
            invoiceAmountDue: invoice.formattedAmountDue
                ? invoice.formattedAmountDue
                : invoice.amountDue + ' ' + invoice.currency,
            totalAmountDue: invoice.amountDue ? invoice.amountDue : '0.00',
            currency: invoice.currency,
            documents: invoice.documents
        }
    }

    static getAccountSummaryDTO(accountSummary) {
        return {
            companyName: accountSummary.companyName,
            companyCode: accountSummary.companyCode,
            currentAmountDue: accountSummary.formattedCurrentAmountDue
                ? accountSummary.formattedCurrentAmountDue
                : accountSummary.currentAmountDue,
            pastAmountDue: accountSummary.formattedPastAmountDue
                ? accountSummary.formattedPastAmountDue
                : accountSummary.pastAmountDue,
            totalAmountDue: accountSummary.formattedTotalAmountDue
                ? accountSummary.formattedTotalAmountDue
                : accountSummary.totalAmountDue,
            currency: accountSummary.currency
        }
    }
}
