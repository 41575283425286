import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import VeeValidatePlugin from '@/plugins/VeeValidatePlugin'
import {
    createRouter,
    createWebHashHistory,
    createWebHistory
} from 'vue-router'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import './fake.scss'

// ensure there is a wrapper for the app
let appWrapper = document.getElementById('app')
if (!appWrapper) {
    appWrapper = document.createElement('div')
    appWrapper.id = 'app'
    document.querySelector('body').appendChild(appWrapper)
}

let page = window.location.pathname

const router = createRouter({
    history:
        page.includes('my-account.html') ||
        page.includes('product-compare.html') ||
        page.includes('antifoam.html') ||
        page.includes('industrial-release.html') ||
        page.includes('beauty-care.html')
            ? createWebHashHistory()
            : createWebHistory(),
    routes: []
})

const DcApp = createApp(App)

DcApp.use(store)
DcApp.use(VeeValidatePlugin)
DcApp.use(router)
router.isReady().then(() => DcApp.mount('#app'))
window.store = store

window.updateUserPrivacyPreferences = (categories) =>
    store.dispatch('user/updatePrivacyPreferences', categories)
