import { hashCode } from '@platform/utils'
import {
    setCookie,
    getBrowserCookies,
    getCookiebyName,
    removeBrowserCookie
} from '../utils/cookies'
import { findLocation } from '../utils/searchLocation'

export default {
    namespaced: true,
    state: {
        cookies: getBrowserCookies(),
        resources: {},
        languageCode: getCookiebyName('languageCode') || 'en-us',
        country: getCookiebyName('country') || 'US',
        state: 'MI',
        useDarkMode: false,
        cookieMap: {
            loginFromPwdChangeSuccess: 'C0001',
            remember_me_user: 'C0001',
            languageCode: 'C0001',
            country: 'C0003',
            state: 'C0003',
            useDarkMode: 'C0003',
            elqQueryString: 'C0004'
        }
    },
    mutations: {
        addResource: (state, { hash, result }) =>
            (state.resources[hash] = result),
        rmResource(state, hash) {
            delete state.resources[hash]
            const el = document.getElementById(hash)
            if (el) el.parentElement.removeChild(el)
        },
        setCookie(state, { cookie, cookieAttributes }) {
            let cookieKey = Object.keys(cookie)
            let cookieValue = cookie[cookieKey]
            const consentLevel = state.cookieMap[cookieKey]
            if (consentLevel && window.OptanonActiveGroups) {
                if (window.OptanonActiveGroups.indexOf(consentLevel) >= 0) {
                    setCookie(cookieKey, cookieValue, cookieAttributes)
                }
            }

            for (let c in state.cookies) {
                if (c == cookieKey) {
                    state.cookies[c] = cookieValue
                }
            }
        }
    },
    actions: {
        styleSheet({ commit, state }, href) {
            const hash = hashCode(href)
            if (!state.resources[hash])
                commit('addResource', {
                    hash,
                    result: new Promise((resolve, reject) => {
                        let link = document.createElement('link')
                        link.rel = 'stylesheet'
                        link.type = 'text/css'
                        link.href = href
                        link.id = hash
                        link.onload = resolve
                        link.onerror = reject
                        document.head.appendChild(link)
                    })
                })
            return state.resources[hash]
        },
        script({ commit, state }, src) {
            const hash = hashCode(src)
            if (!state.resources[hash])
                commit('addResource', {
                    hash,
                    result: new Promise((resolve, reject) => {
                        const script = document.createElement('script')
                        script.src = src
                        script.onload = resolve
                        script.onerror = reject
                        document.body.appendChild(script)
                    })
                })
            return state.resources[hash]
        },

        async setLanguageCode(context, langCode) {
            context.state.languageCode = langCode
                ? langCode.toLowerCase()
                : 'en-us'
            if (langCode === 'en') {
                context.state.languageCode = 'en-us'
            }
            context.commit('setCookie', {
                cookie: { languageCode: context.state.languageCode }
            })

            context.state.cookies = getBrowserCookies()
        },
        async setLocation(context) {
            const acceptedFunctionalCookies =
                window.OptanonActiveGroups &&
                window.OptanonActiveGroups.split(',').indexOf('C0003') >= 0
            if (
                getCookiebyName('country') == null &&
                acceptedFunctionalCookies
            ) {
                const locationInfo = await findLocation()

                if (locationInfo) {
                    if (locationInfo.country_code) {
                        context.state.country = locationInfo.country_code
                    }
                    if (locationInfo.region_code) {
                        context.state.state = locationInfo.region_code
                    }
                }

                context.commit('setCookie', {
                    cookie: { country: context.state.country }
                })
                context.commit('setCookie', {
                    cookie: { state: context.state.state }
                })
            }
        },
        async setGeoLocationInSession(context) {
            if(!sessionStorage.getItem('locationContext')){
                const locationInfo = await findLocation()
                if(locationInfo){
                    let locationObject = {
                        city: locationInfo.city,
                        continent: locationInfo.continent,
                        country_code: locationInfo.country_code,
                        region_code: locationInfo.region_code
                    }
                    sessionStorage.setItem('locationContext', JSON.stringify(locationObject))
                }
            }
        },
        inactivityLogout(context) {
            var time
            var lastMove = 0
            var eventThrottle = 10000 // millisecs
            var events = ['click', 'input', 'mousedown', 'load', 'keydown']
            var throttleEvents = ['mousemove', 'scroll']
            throttleEvents.forEach((e) => {
                $(document).on(e, function (e) {
                    var now = Date.now()
                    if (now > lastMove + eventThrottle) {
                        lastMove = now
                        resetTimer()
                    }
                })
            })
            events.forEach((e) => {
                window.addEventListener(e, resetTimer, true)
            })
            function resetTimer() {
                document.cookie =
                    'LastActive=' +
                    Date.now() +
                    '; path=/ ; secure ; samesite=strict ;'
                clearTimeout(time)
                time = setTimeout(checkExpireLogin, 60 * 60 * 1000)
            }
            function checkExpireLogin() {
                var timeout = 60 * 60 * 1000
                var lastActiveTime = getCookiebyName('LastActive') || 0
                if (Date.now() - lastActiveTime > timeout) {
                    removeBrowserCookie('LastActive')
                    if (localStorage.getItem('logout') === 'false') {
                        console.log(time)
                        logoutSession()
                    }
                } else {
                    clearTimeout(time)
                    time = setTimeout(
                        checkExpireLogin,
                        timeout - (Date.now() - lastActiveTime)
                    )
                }
            }
            function logoutSession() {
                console.log('Session timed out!')
                sessionStorage.setItem('hasTimedOut', 1)
                context.dispatch('user/sessionTimedOut', {}, { root: true })
                context.dispatch('user/logoutUser', {}, { root: true })
                context.dispatch('user/reauthPosponed', 'false', {
                    root: true
                })
                events.forEach((e) => {
                    window.removeEventListener(e, resetTimer, true)
                })
                clearTimeout(time)
            }
        },
        removeBrowserCookie(name) {
            removeBrowserCookie(name)
        },
        async setBrowserCookie(context, payload) {
            const cookie = {}
            cookie[payload.key] = payload.value
            context.commit('setCookie', {
                cookie,
                cookieAttributes: payload.attributes
            })
        },
        removeBrowserCookie(context, payload) {
            removeBrowserCookie(payload.name)
        }
    },
    getters: {
        getCookiebyName: function (state) {
            return function (params) {
                return getCookiebyName(params.name)
            }
        },
        getBrowserCookies: function (state) {
            return function () {
                return getBrowserCookies()
            }
        }
    }
}
