import { buildSearchEngine, buildSearchBox } from '@coveo/headless'
import { SearchIcon } from '../../svg-elements/search-icon-new'
import { CloseIcon } from '../../svg-elements/close-icon'
import store from '@/store'

const SearchBox = {
    template: `
        <div id="searchbox-headless" className="search-box" v-if="engineReady">
            <div class="searchbox_container" role="search">
                <input
                    v-model="q"
                    @keydown.enter="submit($event)"
                    @keydown="selectSuggestion($event)"
                    @focus="showSuggestions"
                    @focusout="hideSuggestions($event)"
                    class="searchbox_input"
                    :placeholder="placeholderText"
                    :aria-label="searchLabel"/>
                <CloseIcon v-if="q.length" class="search__close" tabindex="0" type="button" aria-label="clear search" @click="clearSearchTerm"></CloseIcon>
                <div v-if="q.length" class="divider"></div>
                <SearchIcon class="search__icon" tabindex="0" type="button" aria-label="submit search" @click.native="submit($event)"></SearchIcon>
            </div>
            <ul v-if="engineReady && suggestions.length > 0" class="suggestion_list" :class="suggestionClass">
                <li v-for="(suggestion, index) in suggestions"
                    :key="index"
                    class="suggestion"
                    :id="'suggestion' + index"
                    tabindex="0"
                    @click="submit($event, suggestion.rawValue)"
                    @keyup.enter="submit($event,suggestion.rawValue)"
                    v-html="suggestion.highlightedValue">
                </li>
            </ul>
        </div>
  `,
    name: 'SearchBox',
    // store: store,
    props: {
        isGlobalSearch: { Type: String, default: 'true' },
        parentAuthorableElementId: { Type: String, default: '' },
        activeTab: { Type: String, default: '' }
    },
    data: function () {
        return {
            selectedSearchValue: null,
            qTimer: undefined,
            engineReady: false,
            controllerReady: false,
            isLoading: false,
            unsubscribe: () => {},
            suggestions: [],
            q: '',
            isClear: false,
            suggestionIndex: -1,
            authorableAemFields: '',
            controllers: {
                inPageController: null,
                globalController: null
            },
            engine: null
        }
    },
    components: {
        SearchIcon: SearchIcon,
        CloseIcon: CloseIcon
    },
    computed: {
        placeholderText() {
            return Granite.I18n.get('what.are.you.looking.for')
        },
        searchLabel() {
            return Granite.I18n.get('button.search.Title')
        },
        pageName() {
            return store.state.page.pageInfo.pageName
        },
        suggestionClass() {
            if (
                this.pageName == 'technical-content' &&
                window.innerWidth > 767 &&
                this.controllerVar == 'inPageController'
            ) {
                return 'suggestion_list_tech_content'
            } else if (
                this.pageName == 'events' &&
                window.innerWidth > 767 &&
                this.controllerVar == 'inPageController'
            ) {
                return 'suggestion_list_events'
            } else {
                return ''
            }
        },
        controllerVar() {
            var controllerVar = null
            if (this.$el.parentElement.id == 'inPage-search-container') {
                controllerVar = 'inPageController'
            } else {
                controllerVar = 'globalController'
            }
            return controllerVar
        },
        componentSelector() {
            if (this.isGlobalSearch) {
                return document.querySelector('#dowHead-searchbox-headless')
            } else {
                return document.querySelector('#inPage-searchbox-headless')
            }
        }
    },
    methods: {
        submit(e, suggestion) {
            if (suggestion) {
                this.q = suggestion
            } else if (this.suggestionIndex != -1) {
                this.q = this.suggestions[this.suggestionIndex].rawValue
            }
            this.selectedSearchValue = this.q
        },
        selectSuggestion(e) {
            if (e.which === 40) {
                if (this.suggestionIndex != -1) {
                    var previousEl = document.getElementById(
                        'suggestion' + this.suggestionIndex
                    )
                    if (previousEl != null) {
                        previousEl.classList.remove('suggestionHover')
                        this.suggestionIndex++
                    }
                } else {
                    this.suggestionIndex++
                }
                var el = document.getElementById(
                    'suggestion' + this.suggestionIndex
                )
                if (el != null && this.suggestions[this.suggestionIndex]) {
                    el.classList.add('suggestionHover')
                } else if (this.suggestions.length - 1 < this.suggestionIndex) {
                    this.suggestionIndex = 0
                    el = document.getElementById(
                        'suggestion' + this.suggestionIndex
                    )
                    el.classList.add('suggestionHover')
                }
            } else if (e.which === 38) {
                if (this.suggestionIndex != -1) {
                    var previousEl = document.getElementById(
                        'suggestion' + this.suggestionIndex
                    )
                    if (previousEl != null) {
                        previousEl.classList.remove('suggestionHover')
                        this.suggestionIndex--
                    }
                }
                var el = document.getElementById(
                    'suggestion' + this.suggestionIndex
                )
                if (el != null && this.suggestionIndex != -1) {
                    el.classList.add('suggestionHover')
                } else if (this.suggestionIndex == -1) {
                    this.suggestionIndex = this.suggestions.length - 1
                    el = document.getElementById(
                        'suggestion' + this.suggestionIndex
                    )
                    el.classList.add('suggestionHover')
                }
            }
        },
        updateText(q) {
            this.controllers[this.controllerVar].updateText(q)
        },
        showSuggestions() {
            this.suggestionIndex = -1
            this.controllers[this.controllerVar].showSuggestions()
            this.isClear = false
        },
        hideSuggestions(e) {
            setTimeout(() => {
                this.suggestions = []
            }, 300)
        },
        updateState() {
            this.controllerReady = true
            this.isLoading =
                this.controllers[this.controllerVar].state.isLoading
            if (
                !this.controllers[this.controllerVar].state.isLoadingSuggestions
            ) {
                if (!this.isClear) {
                    this.suggestions =
                        this.controllers[this.controllerVar].state.suggestions
                }
            }
        },
        clearSearchTerm() {
            this.isClear = true
            this.suggestions = []
            this.q = ''
        },
        closeAnyModal() {
            document.querySelectorAll('.cmp-mega-menu.cmp-mega-menu--active-level-0')
                .forEach(menu => menu.classList.remove('cmp-mega-menu--active-level-0'))
            document.querySelectorAll('.cmp-mega-menu-navigation__nav-buttons-text.nav-buttons-text--active')
                .forEach(link => link.classList.remove('nav-buttons-text--active'))
            document.querySelectorAll('.cmp--header .cmp--header_language-modal')
                .forEach(modal => modal.style.display = 'none')
            document.querySelectorAll('.cmp--header .cmp--header_language-selector svg.cmp--header_icon-arrow-sm')
                .forEach(icon => icon.style.transform = 'rotate(-90deg)')
            document.querySelectorAll('.cmp--header .cmp--header_my-account-modal')
                .forEach(modal => modal.removeAttribute('style'))
            document.querySelectorAll('.cmp--header_my-account')
                .forEach(link => link.dispatchEvent(new Event('click')))
            document.querySelectorAll('.cmp-mega-menu-navigation__nav.cmp-mega-menu-navigation__nav-open')
                .forEach(nav => nav.classList.remove('cmp-mega-menu-navigation__nav-open'))
            document.querySelectorAll('.cmp-mega-menu-navigation-hamburger.cmp-mega-menu-navigation-hamburger--open')
                .forEach(hamburger => hamburger.classList.remove('cmp-mega-menu-navigation-hamburger--open'))

        }
    },
    watch: {
        engineReady(newVal, oldVal) {
            if (!this.controllers[this.controllerVar]) {
                this.controllers[this.controllerVar] = buildSearchBox(
                    this.engine,
                    {
                        options: {
                            highlightOptions: {
                                exactMatchDelimiters: {
                                    open: '<span class="highlight">',
                                    close: '</span>'
                                }
                            }
                        }
                    }
                )
                this.unsubscribe = this.controllers[
                    this.controllerVar
                ].subscribe(() => this.updateState())
                this.updateState()
            }
        },
        q(newVal, oldVal) {
            if (newVal !== oldVal) {
                clearTimeout(this.qTimer)
                this.qTimer = setTimeout(() => {
                    this.updateText(this.q)
                }, 300)
            }
        },
        selectedSearchValue(oldVal, newVal) {
            this.authorableAemFields = this.parentAuthorableElementId
                ? document.querySelector(this.parentAuthorableElementId)
                : null
            var languageCode = store.state.browser.languageCode
            var searchUrl =
                this.authorableAemFields !== null
                    ? this.authorableAemFields.dataset.searchUrl
                    : 'search.html'
            if (this.activeTab) {
                var searchQueryParam = this.activeTab
            } else {
                var searchQueryParam =
                    this.authorableAemFields !== null
                        ? this.authorableAemFields.dataset.searchQueryParam
                        : '#t=All'
            }
            var url = '/' + languageCode + '/' + searchUrl + searchQueryParam
            let encodedSelectedSearchValue  = encodeURIComponent(this.selectedSearchValue)
            window.location.href = encodedSelectedSearchValue
                ? url + '&q=' + encodedSelectedSearchValue
                : url

            if (this.selectedSearchValue.toLowerCase() === 'jobs' || this.selectedSearchValue.toLowerCase() === 'careers'){
                window.location.replace("https://corporate.dow.com/en-us/careers.html")
            }
            if (this.selectedSearchValue.toLowerCase() === 'retire' || this.selectedSearchValue.toLowerCase() === 'retirement'){
                window.location.replace("https://corporate.dow.com/en-us/benefits/retiree-and-alumni.html")
            }

            this.closeAnyModal()
        }
    },
    created() {
        this.authorableAemFields = this.parentAuthorableElementId
            ? document.querySelector(this.parentAuthorableElementId)
            : null
        var params = {
            hub:
                this.authorableAemFields !== null
                    ? this.authorableAemFields.dataset.searchHub
                    : 'DowComMainSearch',
            customFilter: null
        }
        store.dispatch('search/fetchSearchToken', params).then((result) => {
            if (result === -1) {
                console.log(
                    'Error during building Coveo search engine: Failed to fetch search token'
                )
            } else {
                const search = {
                    searchHub: params.hub
                }
                this.engine = buildSearchEngine({
                    configuration: {
                        organizationId: result.data.org,
                        accessToken: result.data.token,
                        search: search
                    }
                })

                this.engineReady = true
            }
        })
    },
    deactivated() {
        this.controllers['inPageController'] = null
        this.controllers['globalController'] = null
        this.unsubscribe()
    }
}

export default SearchBox
