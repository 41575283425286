import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const button = this.cmpEl.querySelector('.cmp-button');
    const title = this.cmpEl.querySelector('.cmp-title__text')?.innerText;
    if (!button.ariaLabel) {
      if (title) {
        button.ariaLabel = button.text.trim() + ": " + title;
      } else {
        button.ariaLabel = button.text.trim();
      }
    }
  }
};