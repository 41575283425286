import store from '@/store'
export default class DateHelper {
    static store = store

    static formatDate(date) {
        if (!date || Number.isNaN(date)) {
            return ''
        }

        const dateObj = new Date(date)
        if (Number.isNaN(dateObj.getTime())) {
            return ''
        }

        const day = dateObj.toLocaleDateString(
            store.state.browser.cookies['languageCode'] || 'en-us',
            { day: '2-digit' }
        )
        const month = dateObj.toLocaleDateString(
            store.state.browser.cookies['languageCode'] || 'en-us',
            { month: 'short' }
        )
        const year = dateObj.toLocaleDateString(
            store.state.browser.cookies['languageCode'] || 'en-us',
            { year: 'numeric' }
        )
        return `${day} ${DateHelper.capitalizeMonth(month)} ${year}`
    }

    static capitalizeMonth(month) {
        return month.charAt(0).toUpperCase() + month.slice(1)
    }

    static getDateRangeText(module) {
        const filter = DateHelper.store.getters['myAccountItems/appliedValue'](
            module,
            'dateRange'
        )
        if (filter) {
            const [toDate, fromDate] = filter.map((date) => new Date(date))
            const _MS_PER_DAY = 1000 * 60 * 60 * 24
            const utc1 = Date.UTC(
                toDate.getFullYear(),
                toDate.getMonth(),
                toDate.getDate()
            )
            const utc2 = Date.UTC(
                fromDate.getFullYear(),
                fromDate.getMonth(),
                fromDate.getDate()
            )
            const days = Math.floor((utc1 - utc2) / _MS_PER_DAY)

            return days.toString()
        } else {
            return ''
        }
    }

    static getFormattedDates(module) {
        let formattedDates = {}
        const filter = DateHelper.store.getters['myAccountItems/appliedValue'](
            module,
            'dateRange'
        )
        if (filter) {
            const [toDate, fromDate] = filter.map((date) => new Date(date))
            formattedDates = {
                fromDate: this.formatDate(fromDate),
                toDate: this.formatDate(toDate)
            }
        }
        return formattedDates
    }

    static getEpochSeconds(){
        return Math.floor(Date.now()/1000);
    }
}
