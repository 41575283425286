import store from "@/store"
export const SampleCartNotification = {
  name: 'SampleCartNotification',
  template: `<div v-if="notificationStatus" class="sc-notification" :class="{'is-inline': isInline}">
          <div class="sc-notification--title">
            <p>{{labels.title}}</p>
            <button v-if="!isInline" type="button" class="sc-notification--close" @click="close">
              <svg id="nav-close" data-name="nav-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.7 17.7" width="17.7" height="17.7">
              <g id="XMLID_1_">
              <title>nav-close</title><path id="XMLID_2_" class="azst0" fill="currentColor" d="M1 1l15.7 15.7"/>
              <path class="azst0" fill="currentColor" d="M16.7 1L1 16.7"/></g>
              </svg>
            </button>
          </div>
          <div class="sc-notification--content">
            <div class="sc-notification--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="55.407" height="57.05" viewBox="0 0 55.407 57.05">
                <g id="Component_939_15" data-name="Component 939 – 15" transform="translate(1.5)">
                    <g id="Group_2951" data-name="Group 2951" transform="translate(0 3.156)">
                    <path id="Path_425" data-name="Path 425" d="M53.818,34.19l-19.6,19.6a5.467,5.467,0,0,1-7.736,0L3,30.336V3H30.336L53.818,26.482A5.467,5.467,0,0,1,53.818,34.19Z" transform="translate(-3 -3)" fill="none" stroke="#2c2a29" stroke-width="3"/>
                    <path id="Path_426" data-name="Path 426" d="M10.5,10.5h0" transform="translate(3.168 3.168)" fill="none" stroke="#2c2a29" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                    </g>
                    <g id="Group_2917" data-name="Group 2917" transform="translate(31.69)">
                    <circle id="Ellipse_14" data-name="Ellipse 14" cx="11" cy="11" r="11" fill="#e80033"/>
                    <path id="Path_543" data-name="Path 543" d="M378.787,204l3.72,3.72L390,200.226" transform="translate(-373.154 -192.973)" fill="none" stroke="#fff" stroke-width="2"/>
                    </g>
                </g>
              </svg>
            </div>
            <p class="sc-notification--product">{{addedProduct}}</p>
          </div>
          <div class="sc-notification--cta">
            <a :href="cartPageUrl" class="sc-notification--button">{{ labels.button }}</a>
          </div>
      </div>`,
  store: store,
  props: {
    cartPageUrl: {
      type: String,
      default: ''
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labels: {
        title: Granite.I18n.get('add.sample.cart'),
        button: Granite.I18n.get('view.cart'),
      }
    }
  },
  computed: {
    notificationStatus(){
      var notification = this.$store.getters['sampleCart/getNotification']
      return  notification?.show || false
    },
    addedProduct(){
      var notification = this.$store.getters['sampleCart/getNotification']
      return notification?.productName || ''
    }
  },
  methods: {
    close(){
      this.$store.commit('sampleCart/setNotification', false)
    }
  }
}