import { getParameter } from '@/utils/url'
import ApiClient from '@platform/plugins/AxiosPlugin'

const MAX_PRODUCT_ITERATIONS = 10
const MAX_SET_ITERATIONS = 1000
const MAX_FEATURE_ITERATIONS = 5000
const MAX_FEATURE_VALUE_ITERATIONS = 10

export default {
    namespaced: true,
    state: {
        comparisonData: {},
        propertySets: [],
        productsToCompare: [],
        productCodesToCompare: [],
        chartableFeatures: [],
        chartXAxis: null,
        chartYAxis: null,
        radarAxes: [],
        productsElements: [],
        initialProduct: null,
        compareMarket: null,
        compareProductTech: null,
        compareProductGroup: null,
        maxProductsToCompare: false,
        showErrorModalFlag: false,
        errorMessageFlag: false,
        loadingDotsFlag: false
    },
    mutations: {
        setComparisonData: function (state, payload) {
            state.comparisonData = payload
            formatData(this, payload.products)
        },
        setPropertySets: function (state, payload) {
            state.propertySets = payload
        },
        setChartableFeatures: function (state, payload) {
            state.chartableFeatures = payload
        },
        setChartXAxis: function (state, payload) {
            state.chartXAxis = payload
        },
        setChartYAxis: function (state, payload) {
            state.chartYAxis = payload
        },
        setRadarAxes: function (state, payload) {
            // Vue is not reactive unless the array is reinitialized. Potential Vue bug?
            state.radarAxes = []
            state.radarAxes = payload
        },
        setInitialProduct: function (state, payload) {
            state.initialProduct = payload
        },
        setCompareMarket: function (state, payload) {
            state.compareMarket = payload
        },
        setCompareProductTech: function (state, payload) {
            state.compareProductTech = payload
        },
        setCompareProductGroup: function (state, payload) {
            state.compareProductGroup = payload
        },
        unsetCompareCategories: function (state) {
            state.compareMarket = null
            state.compareProductTech = null
            state.compareProductGroup = null
        },
        setShowErrorModalFlag: function (state, payload) {
            state.showErrorModalFlag = payload
        },
        setLoadingDotsFlag: function (state, payload) {
            state.loadingDotsFlag = payload
        },
        setErrorMessageFlag: function (state, payload) {
            state.errorMessageFlag = payload
        },
        setCompareDrawerItem: function (state, payload) {
            var isRemove = false
            state.maxProductsToCompare = false
            for (var i = 0; i < state.productsToCompare.length; i++) {
                if (state.productsToCompare[i].code == payload.code) {
                    state.productsToCompare.splice(i, 1)
                    state.productCodesToCompare.splice(i, 1)
                    isRemove = true
                }
            }
            if (!isRemove && state.productsToCompare.length < 5) {
                state.productsToCompare.push(payload)
                state.productCodesToCompare.push(payload.code)
                state.productsElements.push({
                    code: payload.code,
                    target: payload.currentTarget
                })
            } else if (!isRemove) {
                state.maxProductsToCompare = true
            } else {
                state.productsElements = state.productsElements.filter(
                    (item) => item.code !== payload.code
                )
            }
        },
        removeCompareDrawerItem: function (state, payload) {
            state.productsToCompare = state.productsToCompare.filter(
                (prod) => prod.code !== payload
            )
            state.productCodesToCompare = state.productCodesToCompare.filter(
                (p) => p !== payload
            )
        }
    },
    actions: {
        fetchComparisonData: function ({ state }, params) {
            fetchComparisonData(this, params)
        },
        fetchData: function ({ state, rootState }, params) {
            fetchData(this, rootState, params)
        },
        removeProducts({ state }, code) {
            if (window.compareClick) {
                const currentTarget = state.productsElements.find(
                    (item) => item.code === code
                )
                window.compareClick(currentTarget.target)
            }

            state.productsElements = state.productsElements.filter(
                (item) => item.code !== code
            )
        }
    },
    getters: {
        getProductsElements: function (state) {
            return state.productsElements
        },
        getComparisonData: function (state) {
            return state.comparisonData
        },
        getPropertySets: function (state) {
            return state.propertySets
        },
        getChartableFeatures: function (state) {
            return state.chartableFeatures
        },
        getChartXAxis: function (state) {
            return state.chartXAxis
        },
        getChartYAxis: function (state) {
            return state.chartYAxis
        },
        getRadarAxes: function (state) {
            return state.radarAxes
        },
        getProductsToCompare: function (state) {
            return state.productsToCompare
        },
        getProductCodesToCompare: function (state) {
            return state.productCodesToCompare
        },
        getMaxProductsToCompare: function (state) {
            return state.maxProductsToCompare
        },
        getCompareMarket: function (state) {
            return state.compareMarket
        },
        getCompareProductTech: function (state) {
            return state.compareProductTech
        },
        getCompareProductGroup: function (state) {
            return state.compareProductGroup
        },
        getErrorMessageFlag: function (state) {
            return state.errorMessageFlag
        },
        getLoadingDotsFlag: function (state) {
            return state.loadingDotsFlag
        }
    }
}

function fetchData(state, rootState, params) {
    var jparams = {}
    jparams = params.map((item) => ({ code: item }))
    var compareParams = {
        productCodes: jparams,
        mktName: 'Agriculture, Feed and Animal Care',
        productCompareUrl: '/.dow.product.compareProducts.json'
    }
    delete rootState.comparison.comparisonData.products
    fetchComparisonData(state, compareParams, rootState)
}

async function fetchComparisonData(state, params, rootState) {
    state.commit('comparison/setLoadingDotsFlag', true)
    var existingData = state.getters['comparison/getComparisonData']

    if (!existingData.hasOwnProperty('products')) {
        params.mktName = params.mktName || getParameter('mktName')
        params.productTech = params.productTech || getParameter('productTech')
        params.productGroup =
            params.productGroup || getParameter('productGroup')
        params.parent = params.parent || getParameter('parent')

        var data = new FormData()
        data.append('products', JSON.stringify(params.productCodes))
        data.append('market', params.mktName)
        data.append('productTech', params.productTech)
        data.append('productGroup', params.productGroup)
        data.append('parent', params.parent)

            const response = await ApiClient.post(
                params.productCompareUrl,
                data,
                {
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }
            ).then((response) => {
                if (response != null && response.data != null) {
                    var data = response.data
                    let jsonResponse =
                        typeof data == 'object' ? data : JSON.parse(data)
                    state.commit(
                        'comparison/setComparisonData',
                        jsonResponse.compareProducts
                    )
                    formatData(state, jsonResponse.compareProducts.products)
                    
                    state.commit('comparison/setLoadingDotsFlag', false)
                } else {
                    console.log('No compare data available')
                }
            }).catch((err) => {
                state.commit('comparison/setLoadingDotsFlag', false)
                state.commit('comparison/setErrorMessageFlag', true)
                console.log(err.response)
                return Promise.reject(err)
            })
    } else {
        // Data already exists
    }
}

function formatData({ state, commit }, products) {
    if (products != null) {
        var colors = [
            '#E80033', // red
            '#1A62D6', // blue
            '#00B2A9', // green
            '#D6D6D6', // light grey
            '#415364', // slate grey
            '#FEBB12' // gold
        ]

        var propertySets = []
        var productCount = products.length
        var colorIndex = 0
        var chartableProducts = products.map((product) => ({
            name: product.name,
            code: product.code,
            features: [],
            // Grab the next color, but limit it to the last color
            color:
                colorIndex < colors.length
                    ? colors[colorIndex++]
                    : colors[colors.length - 1]
        }))
        for (var i = 0; i < productCount && i <= MAX_PRODUCT_ITERATIONS; i++) {
            var chartableProduct = chartableProducts[i]

            var sets = products[i].propertiesClassifications
            if (sets != null) {
                var setCount = sets.length
                for (var j = 0; j < setCount && j <= MAX_SET_ITERATIONS; j++) {
                    var existingSet = propertySets.filter(function (obj) {
                        return (
                            obj.name === window.Granite.I18n.get(sets[j].name)
                        )
                    })[0]
                    var propertySet = {
                        name: '',
                        featureNames: [],
                        features: [],
                        order: null
                    }
                    if (existingSet === null || existingSet === undefined) {
                        propertySet = {
                            name: window.Granite.I18n.get(sets[j].name),
                            featureNames: [],
                            features: [],
                            order: sets[j].order
                        }
                    }

                    var features = sets[j].featureData
                    var featureCount = features.length
                    for (
                        var k = 0;
                        k < featureCount && k <= MAX_FEATURE_ITERATIONS;
                        k++
                    ) {
                        var existingFeature = null

                        if (existingSet == null) {
                            existingFeature = propertySet.features.filter(
                                function (obj) {
                                    return (
                                        obj.name ===
                                        formatFeatureName(
                                            features[k].name,
                                            features[k].standardTestMethod
                                        )
                                    )
                                }
                            )[0]
                        } else {
                            existingFeature = existingSet.features.filter(
                                function (obj) {
                                    return (
                                        obj.name ===
                                        formatFeatureName(
                                            features[k].name,
                                            features[k].standardTestMethod
                                        )
                                    )
                                }
                            )[0]
                        }

                        var feature = {
                            name: '',
                            values: [],
                            parsedValues: []
                        }
                        if (existingFeature == null) {
                            feature = {
                                name: formatFeatureName(
                                    features[k].name,
                                    features[k].standardTestMethod
                                ),
                                isVisible: true,
                                values: [],
                                parsedValues: []
                            }
                        }

                        var featureValues = features[k].featureValues
                        var featureUnit = features[k].featureUnit
                        featureUnit =
                            featureUnit != null
                                ? featureUnit
                                : {
                                      name: '',
                                      symbol: '',
                                      unitType: ''
                                  }
                        var newValue = ''
                        var newParsedValue

                        var chartableFeature = chartableProduct.features.filter(
                            function (obj) {
                                return obj.name === features[k].name
                            }
                        )[0]

                        if (
                            chartableFeature == null &&
                            features[k].allowOnCharts
                        ) {
                            chartableProducts.forEach((element) => {
                                if (element != chartableProduct) {
                                    element.features.push({
                                        defaultForChartsSet:
                                            features[k].defaultForChartsSet,
                                        index: k,
                                        unit: featureUnit,
                                        values: [],
                                        name: features[k].name,
                                        propertySetName: propertySet.name
                                    })
                                }
                            })

                            chartableFeature = {
                                defaultForChartsSet:
                                    features[k].defaultForChartsSet,
                                index: k,
                                unit: featureUnit,
                                values: [],
                                name: features[k].name,
                                propertySetName: propertySet.name
                            }

                            chartableProduct.features.push(chartableFeature)
                        }

                        for (
                            var m = 0;
                            m < featureValues.length &&
                            m <= MAX_FEATURE_VALUE_ITERATIONS;
                            m++
                        ) {
                            if (m != 0) {
                                newValue += ', '
                            }
                            var featureValue = featureValues[m].value

                            newParsedValue = parseFloat(featureValue)
                            newParsedValue = isNaN(newParsedValue)
                                ? null
                                : newParsedValue

                            if (
                                features[k].allowOnCharts &&
                                chartableFeature != null &&
                                newParsedValue != NaN
                            ) {
                                chartableFeature.values.push(newParsedValue)
                            }

                            //append unit, if applicable
                            if (featureUnit != null) {
                                var unit = featureUnit.name
                                featureValue += ' ' + unit
                            }
                            newValue += formatFeatureName(
                                featureValue,
                                featureValues[m].alternateTestMethod
                            )
                        }
                        if (existingFeature == null) {
                            feature.values[i] = newValue

                            if (i >= feature.parsedValues.length) {
                                feature.parsedValues.push([newParsedValue])
                            } else {
                                feature.parsedValues[i].push(newParsedValue)
                            }
                        } else {
                            existingFeature.values[i] = newValue

                            if (i >= existingFeature.parsedValues.length) {
                                existingFeature.parsedValues.push([
                                    newParsedValue
                                ])
                            } else {
                                existingFeature.parsedValues[i].push(
                                    newParsedValue
                                )
                            }
                        }

                        if (existingSet == null) {
                            propertySet.featureNames.push(features[k].name)

                            if (feature != null && feature.name) {
                                propertySet.features.push(feature)
                            }
                        } else {
                            var existingFeatureName =
                                existingSet.featureNames.filter(function (fn) {
                                    return fn === features[k].name
                                })[0]

                            if (existingFeatureName == null) {
                                existingSet.featureNames.push(features[k].name)
                            }

                            if (feature != null && feature.name) {
                                existingSet.features.push(feature)
                            }
                        }
                    }
                    if (existingSet == null) {
                        propertySets.push(propertySet)
                    }
                }
            }
        }

        var propertySetsToCommit = []

        for (
            var i = 0;
            i < propertySets.length && i < MAX_SET_ITERATIONS;
            i++
        ) {
            var propSet = propertySets[i]
            var features = propertySets[i].features

            for (var j = 0; j < features.length; j++) {
                var feature = features[j]
                for (
                    var v = 0;
                    v < products.length && v <= MAX_PRODUCT_ITERATIONS;
                    v++
                ) {
                    if (feature.values[v] == null) {
                        feature.values[v] = '-'

                        if (feature.parsedValues[v] == null) {
                            feature.parsedValues[v] = []
                        }

                        feature.parsedValues[v].push(null)
                    }
                }
            }
            var propertySet = {
                name: propSet.name,
                featureNames: propSet.featureNames,
                features: propSet.features,
                order: propSet.order
            }
            propertySetsToCommit.push(propertySet)
        }

        var propertySetsToCommit = propertySetsToCommit.sort(function (a, b) {
            return a.order - b.order
        })
        commit('comparison/setPropertySets', propertySetsToCommit)
        commit('comparison/setChartableFeatures', chartableProducts)
    }

    function formatFeatureName(name, testMethodName) {
        var formattedFeatureName = name
        if (testMethodName != null) {
            formattedFeatureName += ' (' + testMethodName + ')'
        }
        return formattedFeatureName
    }
}
