import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const navBar = document.querySelector('.splitNavigationBar');
    let offset = navBar.offsetTop;
    window.onscroll = function () {
      if (window.scrollY - offset > 1) {
        navBar.classList.add('sticky');
      } else if (window.scrollY - offset < 1) {
        navBar.classList.remove('sticky');
      }
    };
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function (event) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          var navigationBar = document.querySelector('.splitNavigationBar');
          $('html, body').animate({
            scrollTop: target.offset().top - navigationBar.offsetHeight
          }, 500, function () {
            var $target = $(target);
            $target.focus();
            if ($target.is(':focus')) {
              return false;
            } else {
              $target.attr('tabindex', '-1');
              $target.focus();
            }
          });
        }
      }
    });
  }
};