import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Keyboard } from 'swiper';
import 'swiper/css';
export default {
  extends: CoreComponent,
  mounted() {
    new Swiper(this.cmpEl, {
      slidesPerView: 1,
      watchSlidesProgress: true,
      modules: [Keyboard, Pagination],
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      autoHeight: true,
      pagination: {
        el: this.cmpEl.querySelector('.cmp-microsite-quotes__pagination'),
        clickable: true,
        bulletElement: 'button',
        renderBullet: (index, className) => {
          return `<button class="${className}"><span></span></bullet>`;
        }
      },
      on: {
        afterInit: this.setTestimonialHeight,
        resize: this.setTestimonialHeight
      }
    });
  },
  methods: {
    setTestimonialHeight() {
      const testimonials = this.cmpEl.querySelectorAll('.cmp-microsite-quotes__testimonial');
      const maxHeight = Math.max(...Array.from(testimonials).map(t => t.offsetHeight));
      testimonials.forEach(t => t.style.minHeight = `${maxHeight}px`);
    }
  }
};