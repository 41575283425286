import CoreComponent from '../../core/CoreComponent.vue';
import store from '@/store';
import axios from 'axios';
export default {
  name: 'ListCard',
  extends: CoreComponent,
  store: store,
  mounted() {
    var listCardSingles = document.querySelectorAll('.cmp-list-card__single');
    var url = window.location.href;
    if (url.indexOf("regulatory-inquiry-i-know-what-im-looking-for") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'REACH_documentation' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("regulatory-inquiry-i-dont-know-what-im-looking-for") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'REACH_documentation' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("product-sales-pricing") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'Read_more_about_Order_Place' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("product-sales-how-to-buy") > 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'Read_more_about_Order_Place' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("technical-inquiry-formulation-questions") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'View_available_calculators' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("technical-inquiry-literature-request") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'Open_SDS_Finder' || id === 'Learn_how_to_access_RDS_Documents' || id === 'Quality_ISO_Certificates' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("general-inquiries-other") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'Register_for_Dow_account' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    } else if (url.indexOf("technical-inquiry-product-recomm") >= 0) {
      listCardSingles.forEach(function (single) {
        var id = single.querySelector('p').getAttribute('id');
        var ismanually = single.querySelector('p').getAttribute('ismanually');
        if (id === 'Guided_Product_Selector' || ismanually == 'true') {
          single.style.display = 'block';
        }
      });
    }
  },
  computed: {},
  method: {},
  watch: {
    inputValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        clearTimeout(this.qTimer);
        this.qTimer = setTimeout(() => {
          this.updateText(this.inputValue);
        }, 300);
      }
    }
  }
};