export default {
    namespaced: true,
    actions: {
        view: trackView,
        track: trackDelivery,
        buttonLoad: trackButtonLoad
    }
}

function trackView(context, data) {
    window.adobeDataLayer.push({
        event: 'delivery:view',
        delivery: {
            number: data.deliveryNum
        }
    })
}

function trackDelivery(context, data) {
    window.adobeDataLayer.push({
        event: 'delivery:track',
        delivery: {
            number: data.delivery.number,
            status: data.delivery.status,
            type: data.delivery.type
        }
    })
}

function trackButtonLoad(context, data) {
    window.adobeDataLayer.push({
        event: 'delivery:track:impression',
        delivery: {
            number: data.delivery.number,
            status: data.delivery.status,
            type: data.delivery.type
        }
    })
}
