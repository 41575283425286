import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  role: "list",
  class: "listGroup"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  "aria-hidden": "true"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowRightLarge = _resolveComponent("ArrowRightLarge");
  return _openBlock(), _createBlock(_Teleport, {
    to: "#crossLinkContainer"
  }, [_createElementVNode("div", null, [_createElementVNode("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.sortedCrossLinks, crosslink => {
    return _openBlock(), _createElementBlock("li", {
      key: crosslink.label,
      role: "listitem",
      tabindex: "0"
    }, [_createElementVNode("a", {
      href: crosslink.url,
      class: "listItem",
      target: "_self"
    }, _toDisplayString(crosslink.label), 9, _hoisted_2), _createElementVNode("span", _hoisted_3, [_createVNode(_component_ArrowRightLarge)])]);
  }), 128))])])]);
}