import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "cmp--header_language-modal-wrapper"
};
const _hoisted_2 = {
  class: "cmp--header_language-modal-mt"
};
const _hoisted_3 = {
  class: "cmp--header_language-modal-mt-menu"
};
const _hoisted_4 = {
  class: "cmp--header_language-modal-mt-title"
};
const _hoisted_5 = {
  class: "cmp--header_language-modal-mt-desc"
};
const _hoisted_6 = {
  class: "cmp--header_language-modal-mt-types"
};
const _hoisted_7 = {
  class: "cmp--header_language-modal_option-mt-machine"
};
const _hoisted_8 = {
  class: "cmp--header_language-modal_option-mt-machine-row"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "notranslate"
};
const _hoisted_11 = {
  key: 0,
  class: "robot-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SmallArrow = _resolveComponent("SmallArrow");
  const _component_RobotIcon = _resolveComponent("RobotIcon");
  const _component_ArrowIcon = _resolveComponent("ArrowIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("button", {
    class: "cmp--header_language-modal-mt-back",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toggleLanguageModal && $options.toggleLanguageModal(...args))
  }, [_createVNode(_component_SmallArrow, {
    class: "cmp--header_language-modal-mt-back-arrow"
  }), _createTextVNode(" " + _toDisplayString($options.getLabel('button.back.UPPER')), 1)], 512), [[_vShow, $props.mobileAndTabletCheck]]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_4, _toDisplayString($options.getLabel('language.dropdown.select.language')), 1), _createElementVNode("p", _hoisted_5, [_createElementVNode("span", null, [_createVNode(_component_RobotIcon)]), _createTextVNode(_toDisplayString($options.tagDescription), 1)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.combinedLanguageKeys, (key, index) => {
    return _openBlock(), _createElementBlock("a", {
      href: "#",
      class: "cmp--header_language-modal_option-mt-machine-link",
      key: key,
      onClick: $event => $options.switchLanguage(key, $options.combinedLanguageList[key].pageLink)
    }, [_createElementVNode("span", _hoisted_10, _toDisplayString($options.combinedLanguageList[key].notranslate), 1), _createTextVNode("-"), _createElementVNode("span", null, _toDisplayString($options.combinedLanguageList[key].translate), 1), $options.combinedLanguageList[key].type == 'machine' ? (_openBlock(), _createElementBlock("span", _hoisted_11, [_createVNode(_component_RobotIcon)])) : _createCommentVNode("", true), _createVNode(_component_ArrowIcon)], 8, _hoisted_9);
  }), 128))])])])])])]);
}