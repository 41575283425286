import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  key: 0,
  className: "search-box"
};
const _hoisted_2 = {
  class: "searchbox_container"
};
const _hoisted_3 = ["aria-label"];
const _hoisted_4 = {
  key: 1,
  class: "divider"
};
const _hoisted_5 = {
  key: 0,
  class: "search_result_list"
};
const _hoisted_6 = ["id", "onClick", "onKeydown", "innerHTML"];
const _hoisted_7 = {
  key: 1,
  class: "search_result_pdpurllist",
  style: {
    "display": "none"
  }
};
const _hoisted_8 = ["id", "onClick", "onKeydown", "innerHTML"];
const _hoisted_9 = ["id", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CloseIcon = _resolveComponent("CloseIcon");
  const _component_SearchIcon = _resolveComponent("SearchIcon");
  return _openBlock(), _createBlock(_Teleport, {
    to: "[aem-forms-search-box]"
  }, [$options.state ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("input", {
    maxlength: "190",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.inputValue = $event),
    onKeydown: [_cache[1] || (_cache[1] = _withKeys($event => $options.submit(), ["enter"])), _cache[2] || (_cache[2] = $event => $options.selectSearchResults($event))],
    onFocusout: _cache[3] || (_cache[3] = $event => $options.hideSearchResults($event)),
    class: "searchbox_input",
    "aria-label": $options.searchLabel,
    ref: "searchboxInputRef"
  }, null, 40, _hoisted_3), [[_vModelText, _ctx.inputValue]]), _ctx.inputValue.length ? (_openBlock(), _createBlock(_component_CloseIcon, {
    key: 0,
    class: "search__close",
    tabindex: "0",
    type: "button",
    onClick: $options.clearSearchTerm,
    "aria-label": "clear search"
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.inputValue.length ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true), _createVNode(_component_SearchIcon, {
    class: "search__icon",
    tabindex: "0",
    type: "button",
    "aria-label": "submit search",
    onClick: $options.submit,
    onKeydown: [_cache[4] || (_cache[4] = _withKeys($event => $options.submit(), ["enter"])), _cache[5] || (_cache[5] = $event => $options.selectSearchResults($event))]
  }, null, 8, ["onClick"])]), _ctx.searchResults && _ctx.searchResults.length > 0 ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (result, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      id: 'searchResult' + index,
      class: "suggestion",
      tabindex: "0",
      onClick: $event => $options.selectSearchValue(result.rawValue),
      onKeydown: _withKeys($event => $options.selectSearchValue(result.rawValue), ["enter"]),
      innerHTML: result.rawValue
    }, null, 40, _hoisted_6);
  }), 128))])) : _createCommentVNode("", true), _ctx.searchResultspdpurl && _ctx.searchResultspdpurl.length > 0 ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResultspdpurl, (result, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      id: 'searchResult' + index,
      class: "suggestion",
      tabindex: "0",
      onClick: $event => $options.selectSearchValue(result.rawValue),
      onKeydown: _withKeys($event => $options.selectSearchValue(result.rawValue), ["enter"]),
      innerHTML: result.rawValue
    }, null, 40, _hoisted_8);
  }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResultspdpurl, (result, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      id: 'searchResult' + index + 'pdpUrl',
      class: "suggestion",
      tabindex: "0",
      innerHTML: result.pdpUrl
    }, null, 8, _hoisted_9);
  }), 128))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}