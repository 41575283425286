import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data() {
    return {
      inputField: document.getElementsByClassName('cmp--utility-search-input')[0].value
    };
  },
  methods: {
    clearSearchInput(event) {
      event.preventDefault();
      const inputField = document.getElementsByClassName('cmp--utility-search-input')[0];
      const inputContainer = document.getElementsByClassName('cmp--utility-search-input-container')[0];
      const clearSearch = document.getElementsByClassName('cmp--utility-search-clear-button')[0];
      inputContainer.classList.remove('cmp--utility-search-input-container-typed');
      clearSearch.classList.add('cmp--utility-search-hidden');
      inputField.focus();
      inputField.value = '';
    },
    displayClearInput(event) {
      event.preventDefault();
      const input = event.target.value;
      const clearSearch = document.getElementsByClassName('cmp--utility-search-clear-button')[0];
      const inputContainer = document.getElementsByClassName('cmp--utility-search-input-container')[0];
      if (input === '') {
        clearSearch.classList.add('cmp--utility-search-hidden');
        inputContainer.classList.remove('cmp--utility-search-input-container-typed');
      } else {
        inputContainer.classList.add('cmp--utility-search-input-container-typed');
        clearSearch.classList.remove('cmp--utility-search-hidden');
      }
    }
  },
  mounted() {
    document.getElementsByClassName('cmp--utility-search-clear-button')[0].addEventListener('click', this.clearSearchInput);
    document.getElementsByClassName('cmp--utility-search-input')[0].addEventListener('keyup', this.displayClearInput);
  }
};