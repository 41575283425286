import ApiClient from '@/plugins/AxiosPlugin'
import AddressFilterHelper from '@/helpers/address-filter-helper'

export default {
    namespaced: true,
    state: {
        hasError: false,
        DELIVERY: {
            addresses: [],
            addressesFiltered: false
        },
        COMPANY: {
            addresses: [],
            addressesFiltered: false,
            displayMode: 'COMPANY'
        },
        PAYER: {
            addresses: [],
            addressesFiltered: false
        }
    },
    mutations: {
        setAddresses: function (state, payload) {
            const addressType = payload.addressType
            const addresses = payload.addresses
            state[addressType].addresses = addresses
        },
        setSubAddresses: function (state, payload) {
            const addressType = payload.addressType
            const parentId = payload.parentId
            const subAddresses = payload.subAddresses
            const parentAddress = state[addressType].addresses.find(
                (address) => address.addressId === parentId
            )
            if (parentAddress) {
                parentAddress.subAddresses = subAddresses
            } else {
                console.error("Parent Address doesn't exist")
            }
        },
        clearFilteredAddresses: function (state, payload) {
            state['hasError'] = false
            state[payload.addressType].addressesFiltered = false
            state[payload.addressType].addresses.forEach((address) => {
                address.displayed = true
                if (address.subAddresses) {
                    address.subAddresses.forEach(
                        (subAddress) => (subAddress.displayed = true)
                    )
                }
            })
        },
        setDisplayMode: function (state, payload) {
            state[payload.addressType].displayMode = payload.displayMode
        },
        clearUnappliedAddresses: function (state, payload) {
            state['hasError'] = false
            if(state[payload.addressType]) {
                state[payload.addressType].addressesFiltered = false
                state[payload.addressType].addresses.forEach((address) => {
                    address.displayed = false
                    if (address.subAddresses) {
                        address.subAddresses.forEach(
                            (subAddress) => (subAddress.displayed = false)
                        )
                    }
                })
            }
        }
    },
    getters: {
        addresses: function (state) {
            return function (addressType, appliedAddressId, callerComponent) {
                return state[addressType].addresses
                    .filter(
                        (address) =>
                            address.displayed ||
                            address.addressId == appliedAddressId
                    )
                    .sort((a, b) => {
                        if (callerComponent) {
                            if (a.addressId == appliedAddressId) return -1
                            if (b.addressId == appliedAddressId) return 1
                            return (a.companyName || '').localeCompare(
                                b.companyName || ''
                            )
                        }
                        return a.addressId == appliedAddressId ? 1 : 0
                    })
            }
        },
        addressesFiltered: function (state) {
            return function (addressType) {
                return state[addressType].addressesFiltered
            }
        },
        isParentAddressSelected: function (
            state,
            getters,
            rootState,
            rootGetters
        ) {
            return function (module, filterAttribute, addressId) {
                const selectedAddresses = rootGetters[
                    'myAccountItems/selectedValue'
                ](module, filterAttribute)

                return selectedAddresses[addressId] !== undefined
            }
        },
        isSubAddressSelected: function (
            state,
            getters,
            rootState,
            rootGetters
        ) {
            return function (module, filterAttribute, addressId, parentId) {
                const selectedAddresses = rootGetters[
                    'myAccountItems/selectedValue'
                ](module, filterAttribute)

                return addressValueHasSubAddress(
                    selectedAddresses,
                    addressId,
                    parentId
                )
            }
        },
        appliedAddresses: function (state, getters, rootState, rootGetters) {
            return function (addressType, module, filterAttribute) {
                const appliedAddressesIds =
                    rootGetters['myAccountItems/appliedValue'](
                        module,
                        filterAttribute
                    ) || {}
                const addresses = state[addressType].addresses
                    .sort((a, b) => (a.companyName < b.companyName ? -1 : 1))
                    .filter((address) =>
                        Object.keys(appliedAddressesIds).includes(
                            address.addressId.toString()
                        )
                    )

                addresses.forEach((address) => {
                    address.subAddresses = address.subAddresses.sort((a, b) => {
                        const isASelected = addressValueHasSubAddress(
                            appliedAddressesIds,
                            a.addressId,
                            address.addressId
                        )
                        const isBSelected = addressValueHasSubAddress(
                            appliedAddressesIds,
                            b.addressId,
                            address.addressId
                        )
                        return isBSelected - isASelected != 0
                            ? isBSelected - isASelected
                            : a.town == b.town
                            ? 0
                            : a.town < b.town
                            ? -1
                            : 1
                    })
                })

                return addresses
            }
        },
        listAddresses: function (state, getters, rootState, rootGetters) {
            return function (addressType, module, filterAttribute) {
                const appliedAddressesIds =
                    rootGetters['myAccountItems/appliedValue'](
                        module,
                        filterAttribute
                    ) || {}

                return state[addressType].addresses.filter(
                    (address) =>
                        !Object.keys(appliedAddressesIds).includes(
                            address.addressId.toString()
                        )
                )
            }
        },
        displayedListAddresses: function (
            state,
            getters,
            rootState,
            rootGetters
        ) {
            return function (addressType, module, filterAttribute) {
                const appliedAddressesIds =
                    rootGetters['myAccountItems/appliedValue'](
                        module,
                        filterAttribute
                    ) || {}
                const clonedParentAddresses = state[addressType].addresses
                    .filter((address) => address.displayed)
                    .map((address) => JSON.parse(JSON.stringify(address)))
                clonedParentAddresses.forEach((parentAddress) => {
                    parentAddress.subAddresses =
                        parentAddress.subAddresses.filter(
                            (address) => address.displayed
                        )
                })

                return clonedParentAddresses.filter(
                    (address) =>
                        !Object.keys(appliedAddressesIds).includes(
                            address.addressId.toString()
                        )
                )
            }
        }
    },
    actions: {
        refreshAddress: function (context, config) {
            refreshAddress(context, config.userId, config.selectedSoldTo)
        },
        updateAddress: function (context, deliveryAddress) {
            updateAddress(context, {
                data: deliveryAddress
            })
        },
        getParentAddresses: function (context, config) {
            getParentAddresses(
                context,
                config.addressType,
                config.isOrderBlocked
            )
        },
        getSubAddresses: function (context, config) {
            return getSubAddresses(context, config.addressType, config.parentId)
        },
        getFilteredAddresses: function (context, config) {
            return getFilteredAddresses(
                context,
                config.addressType,
                config.addressSearchType,
                config.filterAttributes,
                config.isOrderBlocked
            )
        },
        getFilteredDeliveryAddresses: function (context, config) {
            return getFilteredDeliveryAddresses(
                context,
                config.addressType,
                config.addressSearchType,
                config.filterAttributes,
                config.isOrderBlocked
            )
        },
        submitDelivery: function (context, config) {
            submitDelivery(
                context,
                config.userId,
                config.formattedData,
                config.selectedSoldTo
            )
        },
        clearDeliveryAddresses: function (context) {
            context.state.DELIVERY.addresses = []
            context.state.DELIVERY.filteredAddresses = null
        },
        setSelectedAddresses: function (context, payload) {
            const subModule = payload.subModule
            const attribute = payload.filterAttribute
            const value = payload.value

            const filter = {
                attribute,
                value
            }
            context.commit(
                'myAccountItems/setFilter',
                { subModule, filter },
                { root: true }
            )
        },
        selectParentAddress: function (context, payload) {
            const subModule = payload.subModule
            const filterAttribute = payload.filterAttribute
            const addressType = payload.addressType
            const address = payload.address

            const filterValue = context.rootGetters[
                'myAccountItems/selectedValue'
            ](subModule, filterAttribute)

            filterValue[address.addressId] = { subAddresses: [] }
            if (
                address.totalSubAddressCount !== address.subAddresses.length &&
                context.state[addressType].displayMode !== 'DELIVERY'
            ) {
                context
                    .dispatch('getSubAddresses', {
                        addressType,
                        parentId: address.addressId
                    })
                    .then((subAddresses) => {
                        filterValue[address.addressId].subAddresses =
                            subAddresses.map(
                                (subAddress) => subAddress.addressId
                            )
                        context.dispatch('setSelectedAddresses', {
                            value: filterValue,
                            subModule,
                            filterAttribute
                        })
                    })
            } else {
                const defaultSelectOnlyDisplayedSubAddresses =
                    context.state[addressType].displayMode === 'DELIVERY'
                filterValue[address.addressId].subAddresses =
                    address.subAddresses
                        .filter((subAddress) =>
                            defaultSelectOnlyDisplayedSubAddresses
                                ? subAddress.displayed
                                : true
                        )
                        .map((subAddress) => subAddress.addressId)
                context.dispatch('setSelectedAddresses', {
                    value: filterValue,
                    subModule,
                    filterAttribute
                })
            }
        },
        deselectParentAddress: function (context, payload) {
            const subModule = payload.subModule
            const filterAttribute = payload.filterAttribute
            const addressId = payload.addressId

            const filterValue = context.rootGetters[
                'myAccountItems/selectedValue'
            ](subModule, filterAttribute)

            delete filterValue[addressId]
            context.dispatch('setSelectedAddresses', {
                canApply: payload.canApply,
                value: filterValue,
                subModule,
                filterAttribute
            })
        },
        selectSubAddress: function (context, payload) {
            const subModule = payload.subModule
            const filterAttribute = payload.filterAttribute
            const addressId = payload.addressId
            const subAddressId = payload.subAddressId

            const filterValue = context.rootGetters[
                'myAccountItems/selectedValue'
            ](subModule, filterAttribute)

            if (
                context.getters.isParentAddressSelected(
                    subModule,
                    filterAttribute,
                    addressId
                )
            ) {
                filterValue[addressId].subAddresses.push(subAddressId)
            } else {
                filterValue[addressId] = { subAddresses: [subAddressId] }
            }

            context.dispatch('setSelectedAddresses', {
                value: filterValue,
                subModule,
                filterAttribute
            })
        },
        deselectSubAddress: function (context, payload) {
            const subModule = payload.subModule
            const filterAttribute = payload.filterAttribute
            const addressId = payload.addressId
            const subAddressId = payload.subAddressId

            const filterValue = context.rootGetters[
                'myAccountItems/selectedValue'
            ](subModule, filterAttribute)

            const subAddressIds = filterValue[addressId].subAddresses
            if (subAddressIds.length === 1) {
                delete filterValue[addressId]
            } else {
                filterValue[addressId].subAddresses = subAddressIds.filter(
                    (address) => address !== subAddressId
                )
            }

            context.dispatch('setSelectedAddresses', {
                value: filterValue,
                subModule,
                filterAttribute
            })
        }
    }
}

function getParentAddresses(context, addressType, isOrderBlocked = false) {
    context.commit('loading/setDrawerLoading', true, { root: true })
    context.state.hasError = false
    ApiClient.get(getTargetUrl({ addressType, isOrderBlocked }))
        .then(function (res) {
            let data = res.data
            if (data && data.b2bUnits) {
                const addresses = data.b2bUnits.map((i) =>
                    AddressFilterHelper.formatParentAddress(i)
                )
                context.state[addressType].addresses = addresses
                if (addresses[0].totalSubAddressCount > 1) {
                    getSubAddresses(
                        context,
                        addressType,
                        addresses[0].addressId
                    )
                }
            } else {
                console.log('AddressData does not have b2bUnits ->' + data)
            }
            context.commit('loading/setDrawerLoading', false, { root: true })
        })
        .catch(function (error) {
            context.state.hasError = true
            console.log('Failed to load Parent AddressData -> ' + error)
            context.commit('loading/setDrawerLoading', false, { root: true })
        })
}

function getSubAddresses(context, addressType, parentId) {
    let url =
        getTargetUrl({ addressType, sapCustomerId: parentId }) +
        '&sapCustomerId=' +
        parentId
    context.commit('loading/setDrawerLoading', true, { root: true })
    context.state.hasError = false
    return new Promise((resolve, reject) => {
        ApiClient.get(url)
            .then(function (res) {
                let data = res.data
                if (data && data.b2bUnits) {
                    const subAddresses = data.b2bUnits.map((i) =>
                        AddressFilterHelper.formatSubAddress(i)
                    )
                    subAddresses.sort((a, b) => (a.town < b.town ? -1 : 1))
                    context.commit('setSubAddresses', {
                        addressType,
                        parentId,
                        subAddresses
                    })

                    resolve(subAddresses)
                } else {
                    console.log(
                        'SubAddressData does not have b2bUnits ->' + data
                    )
                    reject()
                }
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
            })
            .catch(function (error) {
                console.log(
                    'Something went wrong retrieving subAddresses: ',
                    error
                )
                context.state.hasError = true
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
                reject()
            })
    })
}

function getFilteredAddresses(
    context,
    addressType,
    addressSearchType,
    filterAttributes,
    isOrderBlocked = false
) {
    const searchOption = {
        addressType: addressSearchType,
        filters: JSON.stringify(filterAttributes)
    }
    let formData = new FormData()
    for (let key in searchOption) {
        formData.append(key, searchOption[key])
    }
    context.commit('loading/setDrawerLoading', true, { root: true })
    context.state.hasError = false
    return new Promise((resolve, reject) => {
        ApiClient.post(
            getTargetUrl({ addressSearchType, isOrderBlocked }),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(function (res) {
                let data = res.data
                if (data && data.b2bUnits) {
                    let displayedAddresses
                    if (addressSearchType === 'DELIVERY') {
                        const addressGrouppedByParent = data.b2bUnits.reduce(
                            (acc, cur) => {
                                const parentAddress =
                                    AddressFilterHelper.formatParentAddressWithoutSubAddresses(
                                        cur.soldTos[0]
                                    )
                                const subAddress =
                                    AddressFilterHelper.formatSubAddress(cur)

                                if (!acc[parentAddress.addressId]) {
                                    acc[parentAddress.addressId] = parentAddress
                                    acc[parentAddress.addressId].subAddresses =
                                        []
                                }
                                acc[parentAddress.addressId].subAddresses.push(
                                    subAddress
                                )
                                acc[
                                    parentAddress.addressId
                                ].totalSubAddressCount =
                                    acc[parentAddress.addressId]
                                        .totalSubAddressCount + 1
                                return acc
                            },
                            {}
                        )
                        displayedAddresses = Object.values(
                            addressGrouppedByParent
                        )
                    } else {
                        displayedAddresses = data.b2bUnits.map((i) =>
                            AddressFilterHelper.formatParentAddress(i)
                        )
                        if (displayedAddresses[0].totalSubAddressCount > 1) {
                            getSubAddresses(
                                context,
                                addressType,
                                displayedAddresses[0].addressId
                            )
                        }
                    }
                    addAddressesToListAddresses(
                        context,
                        addressType,
                        displayedAddresses
                    )
                    context.commit('setDisplayMode', {
                        addressType,
                        displayMode: addressSearchType
                    })
                    setDisplayedAddresses(
                        context,
                        addressType,
                        displayedAddresses,
                        addressSearchType === addressType
                    )
                } else {
                    setDisplayedAddresses(context, addressType, [])
                }
                context.state[addressType].addressesFiltered = true
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
                resolve()
            })
            .catch(function (error) {
                console.log('Failed to load Search Filter Data -> ' + error)
                context.state.hasError = true
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
                reject()
            })
    })
}

function getFilteredDeliveryAddresses(
    context,
    addressType,
    addressSearchType,
    filterAttributes,
    isOrderBlocked = false
) {
    const searchOption = {
        addressType: addressSearchType,
        filters: JSON.stringify(filterAttributes)
    }
    let formData = new FormData()
    for (let key in searchOption) {
        formData.append(key, searchOption[key])
    }
    context.commit('loading/setDrawerLoading', true, { root: true })
    context.state.hasError = false
    return new Promise((resolve, reject) => {
        ApiClient.post(
            getTargetUrl({ addressSearchType, isOrderBlocked }),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
            .then(function (res) {
                let data = res.data
                if (data && data.b2bUnits) {
                    let displayedAddresses
                    const addressGrouppedByParent = data.b2bUnits.reduce(
                        (acc, cur) => {
                            const parentAddress =
                                AddressFilterHelper.formatParentAddressWithoutSubAddresses(
                                    cur
                                )
                            const subAddress =
                                AddressFilterHelper.formatSubAddress(
                                    cur.soldTos[0]
                                )

                            if (!acc[parentAddress.addressId]) {
                                acc[parentAddress.addressId] = parentAddress
                                acc[parentAddress.addressId].subAddresses = []
                            }
                            acc[parentAddress.addressId].subAddresses.push(
                                subAddress
                            )
                            acc[parentAddress.addressId].totalSubAddressCount =
                                acc[parentAddress.addressId]
                                    .totalSubAddressCount + 1
                            return acc
                        },
                        {}
                    )
                    displayedAddresses = Object.values(addressGrouppedByParent)

                    addAddressesToListAddresses(
                        context,
                        addressType,
                        displayedAddresses
                    )
                    context.commit('setDisplayMode', {
                        addressType,
                        displayMode: addressSearchType
                    })
                    setDisplayedAddresses(
                        context,
                        addressType,
                        displayedAddresses,
                        addressSearchType === addressType
                    )
                } else {
                    setDisplayedAddresses(context, addressType, [])
                }
                context.state[addressType].addressesFiltered = true
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
                resolve()
            })
            .catch(function (error) {
                console.log('Failed to load Search Filter Data -> ' + error)
                context.state.hasError = true
                context.commit('loading/setDrawerLoading', false, {
                    root: true
                })
                reject()
            })
    })
}

function addAddressesToListAddresses(context, addressType, newAddresses) {
    newAddresses.forEach((address) => {
        addParentAddressToListAddresses(context, addressType, address)
    })
}

function addParentAddressToListAddresses(context, addressType, parentAddress) {
    const addresses = context.state[addressType].addresses
    let currentAddress = addresses.find(
        (address) => address.addressId == parentAddress.addressId
    )
    if (!currentAddress) {
        context.state[addressType].addresses.push(parentAddress)
    } else if (currentAddress.subAddresses) {
        const currentSubAddressIds = currentAddress.subAddresses.map(
            (address) => address.addressId
        )
        const newSubAddresses = parentAddress.subAddresses.filter(
            (subAddress) => !currentSubAddressIds.includes(subAddress.addressId)
        )
        currentAddress.subAddresses =
            currentAddress.subAddresses.concat(newSubAddresses)
    }
}

function getAddressIdsWithSubAddresses(addresses) {
    return addresses.reduce((acc, cur) => {
        acc[cur.addressId] = cur.subAddresses.map(
            (subAddress) => subAddress.addressId
        )
        acc[cur.addressId].totalSubAddressCount = cur.totalSubAddressCount

        return acc
    }, {})
}

function setDisplayedAddresses(
    context,
    addressType,
    displayedAddresses,
    displayAllSubAddresses
) {
    const addresses = context.state[addressType].addresses

    if (addressType === 'PAYER') {
        setDisplayedAddressesForSingleAddressType(displayedAddresses, addresses)
    } else {
        setDisplayedAddressesForMultiAddressType(
            displayedAddresses,
            addresses,
            displayAllSubAddresses
        )
    }
}

function setDisplayedAddressesForSingleAddressType(
    displayedAddresses,
    addresses
) {
    const displayedAddressIds = displayedAddresses.map(
        (address) => address.addressId
    )

    addresses.forEach((address) => {
        address.displayed = displayedAddressIds.includes(address.addressId)
    })
}

function setDisplayedAddressesForMultiAddressType(
    displayedAddresses,
    addresses,
    displayAllSubAddresses
) {
    const displayedAddressIds =
        getAddressIdsWithSubAddresses(displayedAddresses)

    addresses.forEach((address) => {
        if (displayedAddressIds[address.addressId]) {
            const displayedSubAddressIds =
                displayedAddressIds[address.addressId]
            address.displayed = true

            address.totalSubAddressCount =
                displayedSubAddressIds.totalSubAddressCount

            address.subAddresses.forEach(
                (subAddress) =>
                    (subAddress.displayed =
                        displayedSubAddressIds.includes(subAddress.addressId) ||
                        displayAllSubAddresses)
            )
        } else {
            address.displayed = false
            address.subAddresses.forEach(
                (subAddress) => (subAddress.displayed = false)
            )
        }
    })
}

function getTargetUrl(params) {
    return `/.dow.user.address.json?${getFormattedParams(params)}`
}

function addressValueHasSubAddress(addressValue, addressId, parentId) {
    return addressValue[parentId]
        ? addressValue[parentId].subAddresses.includes(addressId)
        : false
}

function getFormattedParams(params) {
    return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')
}

function refreshAddress(context, uid, selectedSoldTo) {
    let deliveryRequest = {
        userId: uid,
        selectedSoldTo: selectedSoldTo
    }
    let formData = new FormData()
    for (let key in deliveryRequest) {
        formData.append(key, deliveryRequest[key])
    }
    return ApiClient.post('/.dow.delivery.address.json', deliveryRequest, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(function (result) {
            updateAddress(context, result)
            context.dispatch('user/updateDccUserInfo', {}, { root: true })
            context.commit('loading/setDrawerLoading', false, {
                root: true
            })
        })
        .catch(function (error) {
            console.log('Failed to init delivery truck -> ' + error)
            context.state.hasError = true
            context.commit('loading/setDrawerLoading', false, {
                root: true
            })
        })
}

function updateAddress(context, result) {
    const shortAddress = document.querySelectorAll('.userDeliveryAddress')
    const addressModal = document.querySelectorAll('.formattedAddress')
    if (result && result.data && result.data.country && result.data.town) {
        shortAddress.forEach((item) => {
            item.innerHTML = result.data.town + ', ' + result.data.country?.name
        })
        var output = ''
        if (result.data.sapID || result.data.selectedShipToId) {
            var company = result.data.companyName || ''
            var sapID = result.data.selectedShipToId || result.data.sapID
            output =
                sapID.replace('STD_', '') +
                ' ' +
                company +
                '<br><br>' +
                result.data.formattedAddress
            addressModal.forEach((item) => {
                item.innerHTML = output
            })
        } else {
            addressModal.forEach((item) => {
                item.innerHTML = output
            })
        }
    }

    document.querySelector('.userDeliveryAddress-skeleton')?.remove()
}

function submitDelivery(context, uid, formattedData, selectedSoldTo) {
    context.commit('loading/setDrawerLoading', true, { root: true })
    context.state.hasError = false

    ApiClient.post(
        '/dccstorefront/dcc/en/saveGlobalSelections',
        formattedData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
        .then(function () {
            if(localStorage.getItem('dowComCoveoToken'))
                localStorage.removeItem('dowComCoveoToken')
            return refreshAddress(context, uid, selectedSoldTo)
        })
        .then(() => location.reload())
        .catch(function (error) {
            console.log('Failed to submit delivery address -> ' + error)
            context.state.hasError = true
            context.commit('loading/setDrawerLoading', false, { root: true })
        })
}
