import AddressTypeHelper from './address-type-helper'
import i18nHelper from './i18n-helper'

export default class OrderManagementDTOService {
    static getOrderDTO(order) {
        return {
            orderNumber: order.orderNo,
            poNumber: order.purchaseOrderNo,
            orderDate: new Date(order.orderDate),
            documentCategory: order.documentCategory,
            orderStatus: order.orderStatus,
            changeable: order.changeable,
            cancellable: order.cancellable,
            soldToParty: order.soldToParty,
            shipToParty: order.shipToParty,
            salesOrganization: order.salesOrganization,
            distributionChannel: order.distributionChannel,
            division: order.division,
            total: order.total,
            formattedTotal: order.formattedTotal,
            currency: order.currency,
            items: order.items,
            deliveryAddress: order.deliveryAddress,
            companyAddress: order.companyAddress,
            documents: order.documents,
            orderSearchItems: order.orderSearchItems,
            companyAddressRowView: `${order.companyAddress.town}, ${order.companyAddress.country.isocode}`,
            deliveryAddressRowView: `${order.deliveryAddress.town}, ${order.deliveryAddress.country.isocode}`,
            isReorderRestricted: order.isReorderRestricted,
            shippingCondition: order.shippingCondition,
        }
    }

    static getOrderDetailsDTO(details) {
        details.orderDate = new Date(details.orderDate)
        details.addresses = details.addresses.map((address) =>
            AddressTypeHelper.getAddressFromPartnerAddress(address)
        )
        details.internationalAddress = details.internationalAddress
            ? AddressTypeHelper.getAddressFromInternationalAddress(
                  details.internationalAddress
              )
            : null
        details.sellableLineItems = details.sellableLineItems.map((item) =>
            OrderManagementDTOService.getOrderLineItemDTO(item)
        )

        return details
    }

    static getErrorOrderDetailsDTO(orderNumber) {
        return {
            orderNo: orderNumber,
            orderDate: '',
            addresses: [{
                addressType: 'AG',
                partnerNo: '',
                name: '',
                address: '',
                postalDetails: '',
                country: ''
            },
            {
                addressType: 'WE',
                partnerNo: '',
                name: '',
                address: '',
                postalDetails: '',
                country: ''
            },
            {
                addressType: 'CRR',
                name: ''
            }].map((address) =>
                AddressTypeHelper.getAddressFromPartnerAddress(address)),
            internationalAddress : null,
            sellableLineItems : [
                {   formatSubtotalAmount: '',
                    formatUnitPrice: '',
                    itemNo: '0',
                    purchaseOrderNo: '',
                    orderNo: '',
                    orderItemNo: '',
                    material: '',
                    materialDescription: '',
                    shippingQuantity: '',
                    shippingQuantityUnit: '',
                    orderQuantity: '',
                    orderQuantityUnit: '',
                    isShowCOA: false,
                    isShowEHSDocs: false,
                    baseProductNo: '',
                    url: ''
                }
            ],
            formatSubtotalAmount: '',
            formatTotalAmount: '',
            formatTaxAmount: '',
            currency: '',
            isOrderDetailsError : true
        }
    }

    static getOrderLineItemDTO(item) {
        item.requestedDeliveryDate = new Date(item.requestedDeliveryDate)
        item.endUser = item.endUser
            ? AddressTypeHelper.getAddressFromPartnerAddress(item.endUser)
            : {}
        item.pdpUrl = item.url
        return item
    }

    static getDeliveryDTO(delivery) {
        return {
            deliveryNumber: delivery.deliveryNo,
            shipmentNumber: delivery.shipmentNo
                ? delivery.shipmentNo
                : i18nHelper.getI18nMessage('pending.status'),
            soldToParty: delivery.soldToParty,
            shipToParty: delivery.shipToParty,
            poNumber: delivery.purchaseOrderNumbers,
            deliveryType: delivery.deliveryType,
            requestedDeliveryDate: new Date(delivery.requestedDeliveryDate),
            actualShipDate: new Date(delivery.actualShipDate),
            shippingCondition: delivery.shippingCondition,
            plannedShipDate: new Date(delivery.plannedShipDate),
            deliveryDate: new Date(
                delivery.deliveryStatus == 'DELIVERED'
                    ? delivery.actualDeliveryDate
                    : delivery.plannedDeliveryDate
            ),
            roadURL: delivery.roadURL,
            freightForwarder: delivery.freightForwarder,
            docPacketSentDate: delivery.docPacketSentDate,
            carrier: delivery.carrier,
            deliveryAddress: delivery.deliveryAddress,
            companyAddress: delivery.companyAddress,
            deliveryStatus: delivery.deliveryStatus,
            documents: delivery.documents,
            deliveryItems: delivery.deliveryItems,
            companyAddressRowView: `${delivery.companyAddress.town}, ${delivery.companyAddress.country.isocode}`,
            deliveryAddressRowView: `${delivery.deliveryAddress.town}, ${delivery.deliveryAddress.country.isocode}`,
            isShowExportDocument: delivery.isShowExportDocument
        }
    }

    static getErrorDeliveryDetailsDTO(deliveryNumber) {
        return {
            deliveryNumber: deliveryNumber,
            addresses: [
                {
                    addressType: 'AG',
                    partnerNo: '',
                    name: '',
                    address: '',
                    postalDetails: '',
                    country: ''
                },
                {
                    addressType: 'WE',
                    partnerNo: '',
                    name: '',
                    address: '',
                    postalDetails: '',
                    country: ''
                },
                {
                    addressType: 'CRR',
                    name: ''
                }
            ],
            deliveryType: '',
            plantLocation: '',
            shipment: '',
            actualShipDate: '',
            actualDeliveryDate: '',
            deliveryStatus: '',
            deliveryLineItems: [
                {
                    itemNo: '0',
                    purchaseOrderNo: '',
                    orderNo: '',
                    orderItemNo: '',
                    material: '',
                    materialDescription: '',
                    shippingQuantity: '',
                    shippingQuantityUnit: '',
                    orderQuantity: '',
                    orderQuantityUnit: '',
                    isShowCOA: false,
                    isShowEHSDocs: false,
                    baseProductNo: '',
                    url: ''
                }
            ],
            carrier: {
                trackingUrl: ''
            },
            documents: '',
            gvpRailLocationOutput: '',
            isDeliveryPickup: '',
            isDeliveryDetailsError: true
        }
    }

    static getDeliveryDetailsDTO(delivery) {
        return {
            deliveryNumber: delivery.deliveryNo,
            addresses: delivery.partners.map((address) =>
                AddressTypeHelper.getAddressFromPartnerAddress(address)
            ),
            deliveryType: delivery.deliveryType,
            plantLocation: delivery.plantLocation,
            shipment: delivery.shipment,
            actualShipDate: delivery.actualShipDate,
            actualDeliveryDate:
                delivery.deliveryStatus == 'DELIVERED'
                    ? delivery.actualDeliveryDate
                    : delivery.plannedDeliveryDate,
            deliveryStatus: delivery.deliveryStatus,
            deliveryLineItems: delivery.deliveryLineItems, //.map(item => this.getDeliveryLineItemDTO(item))
            carrier:
                Object.keys(delivery.carrier).length !== 0
                    ? AddressTypeHelper.getCarrierInfo(delivery.carrier)
                    : {},
            documents: delivery.documents,
            gvpRailLocationOutput: delivery.gvpRailLocationOutput,
            isDeliveryPickup:
                delivery.shippingCondition === '02' ||
                delivery.shippingCondition === '19',
            isDeliveryDetailsError: delivery.isDeliveryDetailsError,
            isShowExportDocument: delivery.isShowExportDocument
        }
    }

    static getOrderManagementFilterDTO(filters) {
        return {
            toDate: filters.dateRange?.[0],
            fromDate: filters.dateRange?.[1],
            addresses: this.formatAddressIds(filters.addresses),
            payerCustomerID: filters.payerAddress,
            documentCategory: filters.orderType,
            orderStatuses: filters.orderStatus,
            deliveryStatuses: filters.deliveryStatus,
            invoiceStatus: filters.invoiceStatus,
            caseTypes: filters.caseStatus,
            searchOptionKey: filters.searchOption?.attribute,
            searchOptionValue: filters.searchOption?.value,
            isSearchDropdownSelected: this.getSearchDropdownSelected(
                filters.searchOption?.attribute,
                filters.searchOption?.value,
                filters.fromSubModule
            ),
            contactEmail: filters.contactEmail
        }
    }

    static formatAddressIds(addresses) {
        const formatCompanyAddress = (parentId, subId) => `${subId}-${parentId}`

        if (addresses && Object.keys(addresses).length) {
            return Object.keys(addresses)
                .map((parentId) => {
                    return addresses[parentId].subAddresses.map((subId) =>
                        formatCompanyAddress(parentId, subId)
                    )
                })
                .flat()
                .join(',')
        } else {
            return undefined
        }
    }

    static getSearchDropdownSelected(searchOptionKey, searchOptionValue, subModule) {
        return (
            searchOptionKey !== '' &&
            searchOptionValue !== '' &&
            (searchOptionKey == 'purchaseOrderNo' ||
                searchOptionKey == 'orderNo' ||
                searchOptionKey == 'deliveryNo' || (subModule !== null && subModule == 'coa' && searchOptionKey == 'batchNo'))
        )
    }

    static getShipDetailsDTO(shipDetail) {
        return {
            shipItems: shipDetail.map(shipItem => OrderManagementDTOService.getshipItems(shipItem))
        }
    }

    static getErrorShipDetailsDTO() {
        return '["NULL Ships Returned"]'
    }

    static getshipItems(shipItem) {
        return {
            requestedDeliveryDate: new Date(shipItem.requestedDeliveryDate), // removed - retrieved from order
            plannedDeliveryDate: new Date(shipItem.plannedDeliveryDate),
            confirmedQuantity: shipItem.confirmedQuantity,
            quantityUnit: shipItem.quantityUnit,
            deliveries: shipItem.deliveries ? shipItem.deliveries.map(deliveryItem => OrderManagementDTOService.getDeliveryItems(deliveryItem)) : []
        }
    }

    static getDeliveryItems(deliveryItem) {
        return {
            deliveryNumber: deliveryItem.deliveryNo,
            deliveryDate: new Date(deliveryItem.deliveryDate),
            carrier: deliveryItem.carrier,
            actualQuantityDelivered: deliveryItem.actualQuantityDelivered,
            batches: deliveryItem.batches ? deliveryItem.batches : [],
            documents: deliveryItem.documents ? deliveryItem.documents : [],
            invoiceNo: deliveryItem.invoiceNo,
            deliveryStatus: deliveryItem.deliveryStatus,
            shipmentMode: deliveryItem.shipmentMode,
            archiveDataDoc: deliveryItem.archiveDataDoc ? deliveryItem.archiveDataDoc : [],
            archiveEHS: deliveryItem.archiveEHS ? deliveryItem.archiveEHS : []
        }
    }

    static getCoaRowData(coaRowDataItem) {
        return {
            itemNo: coaRowDataItem.itemNo,
            batchNo: coaRowDataItem.batchNo,
            deliveryDate: new Date(coaRowDataItem.deliveryDate),
            material:coaRowDataItem.material,
            materialDescription: coaRowDataItem.materialDescription,
            partner: AddressTypeHelper.getAddressFromPartnerAddress(coaRowDataItem.partner),
            deliveryNo: coaRowDataItem.deliveryNo,
            batchDocuments: coaRowDataItem.batchDocuments,
            customerMaterialNumber: coaRowDataItem.customerMaterialNumber,
            customerMaterialDescription: coaRowDataItem.customerMaterialDescription,
            formattedLanguages: coaRowDataItem.formattedLanguages,
            purchaseOrderNo: coaRowDataItem.purchaseOrderNo,
            materialShowInPage:
                coaRowDataItem.customerMaterialNumber?coaRowDataItem.customerMaterialNumber:coaRowDataItem.material,
            materialDescShowInPage:
                coaRowDataItem.customerMaterialDescription?coaRowDataItem.customerMaterialDescription:coaRowDataItem.materialDescription
        }
    }

    static getMyInfoWsDTO(myInfo) {
        return {
            myInfo: myInfo
        }
    }

    static getErrorMyInfoWsDTO() {
        return '["NULL MyInfo Returned"]'
    }

    static getMyPreferencesWsDTO(myPreferences) {
        return {
            myPreferences: myPreferences
        }
    }

    static getErrorMyPreferencesWsDTO() {
        return '["NULL MyPreferences Returned"]'
    }

}
