export default class LanguageHelper {
    static getLanguageFromLanguageCodeCookie() {
        const hasLanguage = document.cookie
            .split('; ')
            .find((row) => row.startsWith('languageCode'))
            ?.split('=')[1]
        return hasLanguage ? hasLanguage.split('-')[0] : 'en'
    }

    static getLanguageCodeFromCookie() {
        const language = document.cookie
            .split('; ')
            .find((row) => row.startsWith('languageCode'))
            ?.split('=')[1]
        return language || 'en-us'
    }
}
