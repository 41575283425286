import ApiClient from '@platform/plugins/AxiosPlugin'
import { CMP_LOADED } from '../dow-platform/components/core/ACDLHandler'
import {
    getParameter,
    getURLParameter,
    urlBelongsToApprovedDomains,
    extractDomainName,
    documentViewerRedirect
} from '../utils/url'
import { escapeHtml } from '../utils/html'
import { hsession_interval } from '../utils/index'

const TRACK_LOGIN = 'user:login'
const AMCV_ID = 'AMCV_988D095F54BD18520A4C98A5%40AdobeOrg'
const userCookie =
    document.cookie
        .split('; ')
        .find((row) => row.startsWith('uuid'))
        ?.split('=')[1] || null

const hasTimedOut = sessionStorage.getItem('hasTimedOut') === '1'

export default {
    namespaced: true,
    state: {
        dccUserInfo: {},
        anonSampleCartCount: 0,
        hasTimedOut: hasTimedOut,
        isAuthenticated: !!userCookie && !hasTimedOut,
        authErrors: [],
        termsAccepted: true,
        reauthorizationRequired: false,
        reloadRequired: true,
        username: '',
        isSSOLogin: false,
        loading: false,
        redirecting: false,
        guestEmail: null,
        privacy: [],
        isReloading: false,
        isVerificationError: false,
        reauthPostponed: localStorage.getItem('reauthPostponed'),
        userInfoFetched: false,
        reauthorizationRequiredAfterLogin: false,
        ecid: null,
        advancedAccountTypes: [
            "buyer",
            "accountbalanceinvoices",
            "deliverytracking",
            "invoicetracking",
            "ordertracking",
            "viewcatalog",
            "viewdistributorsrp",
            "viewpricingavailability",
            "viewallcases",
            "viewcontracts",
            "viewdeliveryvisibility",
            "multipopreview"
        ]
    },
    getters: {
        acceptedPerformanceCookies: (state) =>
            isPrivacyCategoryAccepted(state, 'C0002'),
        acceptedFunctionalCookies: (state) =>
            isPrivacyCategoryAccepted(state, 'C0003'),
        acceptedTargetingCookies: (state) =>
            isPrivacyCategoryAccepted(state, 'C0004'),
        acceptedSocialCookies: (state) =>
            isPrivacyCategoryAccepted(state, 'C0005'),
        isBasic: state => !!state.dccUserInfo.data?.roles
            ?.filter(({ uid }) => uid == 'basicuser').length
    },
    mutations: {
        dccUserInfo: setDccUserInfo,
        authErrors: setAuthErrors,
        termsAccepted: setTermsAccepted,
        isAuthenticated: setIsAuthenticated,
        guestEmail: setGuestEmail,
        reauthorizationRequired: setReauthorizationRequired,
        isSSOLogin: setIsSSOLogin,
        loading: setLoading,
        redirecting: setRedirecting,
        privacy: setPrivacyPreferences,
        isVerificationError: setIsVerificationError,
        reauthPostponed: setReauthPostponed,
        reloadRequired: setReloadRequired,
        setSampleCartCount,
        setAnonSampleCartCount,
        setECID
    },
    actions: {
        updateDccUserInfo,
        updateSampleCartCount,
        isLoggedIn,
        sessionTimedOut,
        loginUser,
        ssoLoginUser,
        logoutUser,
        verifyAccount,
        deleteAccount,
        postponeAccount,
        trackLogin,
        updateGuestEmail,
        loginRedirect,
        updatePrivacyPreferences,
        updateECID,
        reloadPage,
        [TRACK_LOGIN]: { root: true, handler() {} },
        [CMP_LOADED]: {
            root: true,
            handler({ dispatch }) {
                const queryString = window.location.search
                const urlParams = new URLSearchParams(queryString)
                let appId
                let isSSOLogin = false
                //read query param for application
                if (urlParams) {
                    appId = urlParams.get('a')
                }
                if (appId && appId != '') {
                    isSSOLogin = true
                }
                if (!isSSOLogin) {
                    dispatch('isLoggedIn')
                }
            }
        }
    }
}

function updatePrivacyPreferences({ state, commit, getters }, preferences) {
    commit('privacy', preferences)

    if (window.dtrum) {
        // Enable/disable dynatrace
        if (getters.acceptedPerformanceCookies) dtrum.enable()
        else dtrum.disable()
    }

    return state.privacy
}

function isPrivacyCategoryAccepted(state, category) {
    return state.privacy.includes(category)
}

function setPrivacyPreferences(state, categories) {
    if (typeof categories == 'string')
        categories = categories
            .split(',')
            .filter((category) => Boolean(category))
    if (categories instanceof Array) state.privacy = categories
}

async function loginUser(context, payload) {
    if (sessionStorage.getItem('hasTimedOut') === '1') {
        sessionStorage.setItem('hasTimedOut', '0')
    }

    localStorage.setItem('logout', 'false')

    if (localStorage.getItem('reauthPostponed') === 'true') {
        localStorage.setItem('reauthPostponed', 'false')
    }
    const locale = this.state.browser.cookies['languageCode'] || 'en-us'
    context.commit('loading', true)
    try {
        const response = await ApiClient.post(
            context.rootState.settings.login_servlet,
            payload.formData,
            {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }
        )
        if(localStorage.getItem('dowComCoveoToken'))
            localStorage.removeItem('dowComCoveoToken')

        if (response.data.reauthorizationRequired === 'true') {
            if(payload.awaitCallback !== undefined)
                await payload.awaitCallback()
            context.commit('reauthorizationRequired', true)
            context.state.userInfoFetched = false
            context.state.reauthorizationRequiredAfterLogin = true
        } else {
            if(payload.awaitCallback !== undefined)
                await payload.awaitCallback()
            context.dispatch('loginRedirect', response)
        }

        await context.dispatch('updateDccUserInfo', true)
        await context.dispatch('trackLogin', response.data)

        if (window.dtrum) {
            dtrum.identifyUser(context.state.dccUserInfo?.data?.email)
        }
        context.commit('loading', false)
    } catch (err) {
        context.commit('authErrors', err.response)
        context.commit('loading', false)
        return Promise.reject(err)
    }
}

async function ssoLoginUser(context, params) {
    if (sessionStorage.getItem('hasTimedOut') === '1') {
        sessionStorage.setItem('hasTimedOut', '0')
    }
    const url = '/.dow.commerce.sso.auth.json'
    context.commit('loading', true)
    try {
        let response = await ApiClient.post(url, params, {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        if(localStorage.getItem('dowComCoveoToken'))
            localStorage.removeItem('dowComCoveoToken')
        if(localStorage.getItem('sampleCart'))
            localStorage.removeItem('sampleCart')
        context.commit('setAnonSampleCartCount', 0)
        if (response.data.reauthorizationRequired === 'true') {
            context.commit('reauthorizationRequired', true)
            context.commit('isAuthenticated', true)
            response.data.email = params.get('j_username')
            context.commit('dccUserInfo', response)
        } else {
            window.location.href = response.data.targetUrl
            context.commit('isAuthenticated', true)
        }
        await context.dispatch('trackLogin', response.data)
        context.commit('loading', false)
    } catch (err) {
        context.commit('authErrors', err.response)
        context.commit('loading', false)
        context.commit('isAuthenticated', false)
        return Promise.reject(err)
    }
}

async function logoutUser(context) {
    var locale = this.state.browser.cookies['languageCode'] || 'en-us'
    try {
        const response = await ApiClient.post(
            context.rootState.settings.logout_servlet,
            new FormData(),
            {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }
        )
        setLoggedInStatusClass(false)

        if (sessionStorage.getItem('fetchedWebsiteUpdate')) {
            sessionStorage.removeItem('fetchedWebsiteUpdate')
        }

        if (localStorage.getItem('showUpdatesBanner')) {
            localStorage.removeItem('showUpdatesBanner')
        }

        context.commit('isAuthenticated', false)

        if (context.state.reauthorizationRequired)
            context.commit('reauthorizationRequired', false)

        context.commit('reauthPostponed', 'false')
        localStorage.setItem('logout', 'true')
        if(localStorage.getItem('dowComCoveoToken'))
            localStorage.removeItem('dowComCoveoToken')
            store.dispatch('browser/setLocation', {}).then(() => {
            if (context.state.isSSOLogin) {
                location.reload()
            } else {
                if (sessionStorage.getItem('hasTimedOut') === '1') {
                    location.href =
                        '/' +
                        locale +
                        context.rootState.settings.LOGIN_PAGE +
                        '?redirectUrl=' +
                        location.pathname + location.search+getAnchorFromURL(window.location.href)
                } else location.href = '/' + locale + '.html'
            }
        })
    } catch (err) {
        context.commit('authErrors', err.response)
        return Promise.reject(err)
    }
}

function setDccUserInfo(state, dccUserInfo) {
    state.dccUserInfo = dccUserInfo
}

async function updateDccUserInfo(context, dccUserInfo) {
    let isLogout = localStorage.getItem('logout') === 'false';
    context.dispatch('updateECID');
    if (
        !dccUserInfo ||
        !Object.getOwnPropertyNames(dccUserInfo).filter(
            (prop) => prop != '__ob__'
        ).length
    ) {
        if (dccUserInfo === true && isLogout) {
            const userInfoUrl = `/.dow.dcc.user.info.json`
            try {
                context.state.userInfoFetched = ApiClient.get(userInfoUrl)
                const data = await context.state.userInfoFetched
                context.commit('dccUserInfo', data)
                let isLoggedIn = context.state.dccUserInfo?.data?.email
                    ? true
                    : false
                if(isLoggedIn) {
                    context.dispatch('updateSampleCartCount')
                } else {
                    var sampleCartCount = 0;
                    if(localStorage.getItem('sampleCart')){
                        var sampleJson = JSON.parse(localStorage.getItem('sampleCart'))
                        sampleCartCount = sampleJson?.sampleCartCount || 0
                    }
                    context.commit('setAnonSampleCartCount', sampleCartCount || 0)
                }
                let reauthRequired = context.state.dccUserInfo?.data
                    ?.reauthorizationRequired
                    ? context.state.dccUserInfo?.data?.reauthorizationRequired
                    : false
                context.commit('isAuthenticated', isLoggedIn)
                context.commit('reauthorizationRequired', reauthRequired)
                setLoggedInStatusClass(isLoggedIn)
                setCanShowTruckStatusClass(
                    context.state.dccUserInfo.data?.canShowTruck
                )
                sessionStorage.setItem(
                    'cartGuid',
                    context.state.dccUserInfo?.data?.cartGuid
                )
                if (isLoggedIn) {
                    context.dispatch(
                        'browser/inactivityLogout',
                        {},
                        { root: true }
                    )
                    hsession_interval()
                }
                return isLoggedIn
            } catch (error) {
                console.log('Failed to load users data.')
            }
        } else {
            return context.state.userInfoFetched
        }

    } else {
        context.commit('dccUserInfo', dccUserInfo)
        let isLoggedIn = context.state.dccUserInfo.data?.email ? true : false
        context.commit('isAuthenticated', isLoggedIn)
        setLoggedInStatusClass(isLoggedIn)
        setCanShowTruckStatusClass(context.state.dccUserInfo.data?.canShowTruck)
        return Promise.resolve(isLoggedIn)
    }
}

function sessionTimedOut(context) {
    sessionStorage.setItem('hasTimedOut', '1')
    context.state.hasTimedOut = '1'
}

function isLoggedIn(context) {
    if (context.state.dccUserInfo?.data?.email) {
        return Promise.resolve(true)
    } else if (!context.state.dccUserInfo.data) {
        return context.dispatch('updateDccUserInfo')
    } else {
        return Promise.resolve(false)
    }
}

function setLoggedInStatusClass(isLoggedIn) {
    if (isLoggedIn) {
        document.body.classList.add('is-logged-in')
    } else {
        if (document.body.classList.contains('is-logged-in')) {
            document.body.classList.remove('is-logged-in')
        }
    }
}

function setCanShowTruckStatusClass(canShowTruck) {
    if (canShowTruck) {
        document.body.classList.add('can-show-truck')
    } else {
        if (document.body.classList.contains('can-show-truck')) {
            document.body.classList.remove('can-show-truck')
        }
    }
}

function setAuthErrors(context, payload) {
    if (payload?.data?.error_type !== 'TERMS_NOTACCEPTED') {
        if (payload?.data?.error_type === 'login.failed.Title') {
            payload.data.error_type =
                window.Granite.I18n.get('login.failed.Title')
            payload.data.message = window.Granite.I18n.get(
                'login.error.exception.message'
            )
        }

        context.authErrors.push(payload)
    } else {
        context.termsAccepted = false
        if (payload?.data?.username) {
            let userNameCamel = payload?.data?.username?.toLowerCase()
            userNameCamel = userNameCamel.replace(/\b\w+\b/g, function (word) {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
            context.username = userNameCamel
        } else {
            context.username = payload?.data?.userid
        }
    }
}

function setTermsAccepted(context, payload) {
    context.termsAccepted = payload
}

function setIsAuthenticated(context, payload) {
    context.isAuthenticated = payload
}

function setGuestEmail(context, payload) {
    context.guestEmail = payload
}

function updateGuestEmail(context, payload) {
    context.commit('guestEmail', payload)
}

function setReauthorizationRequired(context, payload) {
    context.reauthorizationRequired = payload
}

function setIsSSOLogin(context, payload) {
    context.isSSOLogin = payload
}

function setLoading(context, payload) {
    context.loading = payload
}

function setRedirecting(context, payload) {
    context.redirecting = payload
}

function setIsVerificationError(context, payload) {
    context.isVerificationError = payload
}

function setReauthPostponed(context, payload) {
    context.reauthPostponed = payload
    localStorage.setItem('reauthPostponed', payload.toString())
}

function setReloadRequired(context, payload) {
    context.reloadRequired = payload
}

function setECID(context, payload){
    context.ecid = payload
}

async function verifyAccount(context, payload) {
    let params = `cmd=${payload.cmd}`

    if (!context.state.dccUserInfo?.data?.isInternalUser) {
        params = `${params}&confirmationCode=${payload.confirmationCode}`
    }
    context.commit('loading', true)

    try {
        const response = await ApiClient.post(
            context.rootState.settings.reauth_servlet,
            params
        )
        if (payload.cmd !== 'resend') {
            context.commit('reauthorizationRequired', response.status !== 200)
            context.commit(
                'reauthPostponed',
                (response.status !== 200).toString()
            )
            if (context.state.isSSOLogin) {
                if (payload.cmd === 'verify') {
                    if (response.status != 200) {
                        context.commit('isVerificationError', true)
                        context.commit('loading', false)
                    } else {
                        if (context.state.dccUserInfo?.data?.targetUrl) {
                            window.location.href =
                                context.state.dccUserInfo.data.targetUrl
                        }
                    }
                } else {
                    if (context.state.dccUserInfo?.data?.targetUrl) {
                        window.location.href =
                            context.state.dccUserInfo.data.targetUrl
                    }
                }
            } else {
                context.dispatch('updateDccUserInfo')
                context.dispatch('loginRedirect', payload)
            }
        }
    } catch (err) {
        if (err.response.status != 200) {
            context.commit('isVerificationError', true)
            context.commit('loading', false)
        }
        context.commit('loading', false)
    }
    context.commit('loading', false)
}

async function deleteAccount(context, payload) {
    const params = `cmd=${payload.cmd}`

    context.commit('loading', true)
    const response = await ApiClient.post(
        context.rootState.settings.reauth_servlet,
        params
    )
    context.commit('reauthorizationRequired', response.status !== 'SUCCESS')
    context.commit('reauthPostponed', (response.status !== 200).toString())
    if (!context.state.isSSOLogin) {
        context.dispatch('updateDccUserInfo')
    }
    context.commit('loading', false)

    if (context.state.isSSOLogin) {
        location.reload()
    } else context.dispatch('logoutUser')
}

function postponeAccount(context, payload) {
    if (payload.cmd == 'postpone') {
        context.commit('reauthPostponed', 'true')
        if (context.state.isSSOLogin) {
            if (context.state.dccUserInfo?.data?.targetUrl) {
                window.location.href = context.state.dccUserInfo.data.targetUrl
            }
        } else {
            if(context.state.reauthorizationRequiredAfterLogin===true){
                context.state.reauthorizationRequiredAfterLogin=false
                context.dispatch('loginRedirect', payload)
            }else{
                window.location.reload()
            }
        }
    }
}

function trackLogin(context, data) {
    var userObj = context.state.dccUserInfo.data
    var ldapGuid = data?.LDAP_GUID || userObj?.ldapGuid || 'Undefined'
    var shipToId =
        data?.selectedShipTo || userObj?.selectedShipToId || 'Undefined'
    var soldToId =
        data?.selectedSoldTo || userObj?.selectedSoldToId || 'Undefined'
    var companyName = data?.defaultDeliveryAddress?.companyName || 'Undefined'
    const isBasicUser = !!data?.isBasic

    window.adobeDataLayer.push({
        event: 'user:login',
        user: {
            webAccountID: ldapGuid,
            webAccountDomain: data?.isInternal ? 'dow' : 'external',
            shipTo: isBasicUser ? 'Basic User' : `${shipToId}|${companyName}`,
            soldTo: isBasicUser ? 'Basic User' : `${soldToId}|${companyName}`,
            companyName: data?.companyName || 'Undefined'
        }
    })

    return new Promise((resolve) => setTimeout(resolve, 1000));
}


function getAnchorFromURL(url) {
    var Anchors = url.split('#');
    if (Anchors.length > 1) {
        return "#" + Anchors[1];
    } else {
        return "";
    }
}

function getLanguageCode(context){
    var languageCode = context.rootState.browser.cookies['languageCode']
    if (languageCode == null){
        languageCode = location.pathname.slice(1, 6)
    } 
    languageCode = languageCode.toLowerCase()
    return languageCode || 'en-us'
}

async function loginRedirect(context, payload) {
    while (!context.state.dccUserInfo?.data?.authToken) {
        await new Promise(resolve => setTimeout(resolve, 100));
    }
    var timeOutPageUrl = context.rootState.browser.cookies['timeOutPage']
    if (
        typeof timeOutPageUrl !== 'undefined' &&
        timeOutPageUrl !== null &&
        timeOutPageUrl !== ''
    ) {
        context.dispatch('browser/removeBrowserCookie', 'timeOutPage')
        $('.dcc_timeout_notification').addClass('hidden')
        window.location.href = timeOutPageUrl
    }

    //Sample Request Login
    var isSampleRequest = $('#isSampleRequest').val()
    if (isSampleRequest === 'isSampleRequest') {
        $('.loginFormModal .sample-request-user-exits').addClass('hidden')
        window.history.back()
    }

    //login page flag
    var isFromLoginPage = false
    var userId = payload?.data?.userid
    var baseUrl = window.location.origin
    var urlSearch = window.location.search ? window.location.search : ''
    var urlObj = getURLParameter(urlSearch)
    //extract redirect URL from main URL
    var redirectUrl = urlObj.get('redirectUrl')
    var redirectUrlObj = new URL(redirectUrl, baseUrl)

    if (window.location.href.indexOf('login.html') !== -1 || window.location.href.indexOf('simple-registration.html') !== -1) {
        isFromLoginPage = true
        if (
            redirectUrl &&
            redirectUrlObj.origin == baseUrl &&
            urlSearch.indexOf('document-viewer.html') > -1 &&
            urlSearch.indexOf('redirectUrl') > -1
        ) {
            documentViewerRedirect(
                redirectUrlObj.href,
                context.rootState.settings.LOGIN_REDIRECT_DOMAINS
            )
        } else {
            //only redirect if it belongs to an approved list of domains or starts with a relative path and an alphanumeric char
            var regex = /^\/([a-zA-Z0-9])/
            redirectUrl=redirectUrl + getAnchorFromURL(window.location.href)
            if (
                redirectUrl &&
                redirectUrlObj.origin == baseUrl &&
                (regex.test(redirectUrl) ||
                    urlBelongsToApprovedDomains(
                        redirectUrl,
                        context.rootState.settings.LOGIN_REDIRECT_DOMAINS
                    ))
            ) {
                context.commit('redirecting', true)
                window.location.href = escapeHtml(
                    decodeURIComponent(redirectUrl)
                )
            } else {
                var interestParam = getParameter('interest')
                if (interestParam != null && interestParam != '') {
                    var formattedInterestArray = []
                    var interestArray = interestParam.split(',')
                    $.each(interestArray, function (i, val) {
                        if (val.indexOf('/') > -1) {
                            var splitInterest = val.split('/')
                            formattedInterestArray.push(
                                splitInterest[splitInterest.length - 1]
                            )
                        } else {
                            formattedInterestArray.push(val)
                        }
                    })
                    updateInterests(context, userId, formattedInterestArray)
                } else {
                    context.commit('redirecting', true)
                    var extUrlRedirect = window.location.href.split('redirectUrl=').slice(-1)
                    var hasRedirectUrl = window.location.href.indexOf('redirectUrl');
                    if (extUrlRedirect != null && extUrlRedirect != '' && hasRedirectUrl!=-1) {
                        window.location.href = escapeHtml(
                            decodeURIComponent(extUrlRedirect)
                        )
                    } else {
                        window.location.href = window.location.href.replace(
                            context.rootState.settings.LOGIN_PAGE,
                            context.rootState.settings.DEFAULT_REDIRECT_URL
                        )
                    }
                }
            }
        }
    }

    //if not from login page and isReload element exists, reload page
    if (!isFromLoginPage) {
        context.state.isReloading = true
        let currentUrl =  window.location.href
        let stringsToCheck = ['pdp', 'crop-solver', 'search', 'sample-cart']; 
        let shouldNotRedirect = stringsToCheck.some(str => currentUrl.includes(str));
        
        if(!shouldNotRedirect){
            context.commit('redirecting', true)
            window.location.href="/"+ getLanguageCode(context)+"/my-account.html"
        }else{
            location.reload()
        }
    }

    if ($('#simpleRegConfirmId').text() != '') {
        var languageCode = getLanguageCode(context)
        var locale =
            context.rootState.settings.LANGUAGE_ARRAY.indexOf(languageCode) > -1
                ? languageCode
                : 'en-us'
        var urlSearch = window.location.search ? window.location.search : ''
        if (urlSearch.indexOf('redirectUrl') > -1) {
            var urlObj = getURLParameter(urlSearch)
            //extract redirect URL from main URL
            var redirectUrl = decodeURIComponent(urlObj.get('redirectUrl'))
            var extractDomain = extractDomainName(
                redirectUrl,
                context.rootState.settings.DEFAULT_REDIRECT_URL
            )
            if (
                extractDomain === '' &&
                redirectUrl.indexOf(context.rootState.settings.URL_BASE) !== 0
            ) {
                redirectUrl = context.rootState.settings.URL_BASE + redirectUrl
            }
            documentViewerRedirect(
                redirectUrl,
                context.rootState.settings.LOGIN_REDIRECT_DOMAINS
            )
        } else {
            window.location.href = '/' + locale + '.html'
        }
    }
}

function updateInterests(context, userId, interests) {
    const formdata = new FormData()
    formdata.set('email', userId)
    interests.forEach((interest) => {
        formdata.append('industry[]', interest)
    })
    ApiClient.post(context.rootState.settings.interest_update, formdata, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
        .then(function (response) {
            window.location.href = window.location.href.replace(
                context.rootState.settings.LOGIN_PAGE,
                context.rootState.settings.DEFAULT_REDIRECT_URL +
                    '#my-preferences'
            )
        })
        .catch(function (error) {
            console.log('Error updating interests', error)
        })
}

function reloadPage(context) {
    context.state.isReloading = true
    window.location.reload()
}

function updateSampleCartCount(context) {
    var _this = this
    var sampleGuid = null
    if(localStorage.getItem('sampleCart')){
        var sampleData = JSON.parse(localStorage.getItem('sampleCart'))
        sampleGuid = sampleData?.sampleCartGuid
    }
    if (
        sessionStorage.getItem('fetchedSampleCount') != 'true' ||
        (sampleGuid && sampleGuid != 'undefined')
    ) {
        sessionStorage.setItem('fetchedSampleCount', true)
        ApiClient.get(context.rootState.settings.sample_cart_count_servlet, {
            params: { sampleGuid: sampleGuid }
        })
            .then(function (response) {
                let isAuthenticated = store.state.user.isAuthenticated
                if(isAuthenticated) {
                    context.commit('setSampleCartCount', response.data)
                    localStorage.removeItem("sampleCart")
                    context.commit('setAnonSampleCartCount', 0)
                } else {
                    context.commit('setAnonSampleCartCount', response.data)
                }
            })
            .catch(function (error) {
                sessionStorage.setItem('fetchedSampleCount', null)
                console.log(error)
            })
    }
}
function setSampleCartCount(state, payload) {
    state.dccUserInfo.data.numberOfSampleCartItems = payload
}

function setAnonSampleCartCount(state, payload) {
    state.anonSampleCartCount = payload
}

function updateECID(context, payload){
    let amcvCookie = this.state.browser.cookies[AMCV_ID] 
    let ECID = amcvCookie?.split("|")[1] || null;
    context.commit('setECID', ECID);
}