import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Navigation, Keyboard, Thumbs, EffectFade } from 'swiper';
import 'swiper/css';
export default {
  extends: CoreComponent,
  mounted() {
    // Add microsite header class if XF contains header in microsite variant
    const headerXF = document.querySelector('.experiencefragment.main-header');
    if (headerXF && headerXF.querySelector('.microsite-light')) {
      headerXF.classList.add('microsite-header-XF');
    }
  }
};