import store from '@/store'
export class UserHelper {
    static store = store

    static getUserData() {
        const storedUserData = JSON.stringify(
            store.state.user.dccUserInfo?.data
        )
        return storedUserData ? JSON.parse(storedUserData) : undefined
    }

    static isInternalUser() {
        const userObj = this.getUserData()
        if (userObj) {
            return userObj['isInternalUser']
        } else {
            console.error('User not set')
        }
    }

    static hideTruckBanner() {
        const showTruck = !!store.state.user.dccUserInfo?.data?.canShowTruck
        return !showTruck
    }

    static isUserLoggedIn() {
        return this.getUserData()?.email !== undefined
    }

    static getUserId() {
        if (this.isUserLoggedIn()) {
            return this.getUserData().uid
        }
    }

    static getUserGuid() {
        if (this.isUserLoggedIn()) {
            return this.getUserData()?.ldapGuid
        }
    }

    static getDefaultDeliveryAddress() {
        if (this.isUserLoggedIn()) {
            return this.getUserData().defaultDeliveryAddress
        }
    }

    static hasDefaultDeliveryAddress() {
        const userObj = this.getUserData()
        if (userObj) {
            return (
                userObj.selectedShipToId !== null &&
                userObj.selectedShipToId !== undefined &&
                userObj.selectedShipToId !== ''
            )
        } else {
            return false
        }
    }

    static getSelectedSoldTo() {
        const userObj = this.getUserData()
        if (userObj) {
            return userObj['selectedSoldToId']
        } else {
            console.error('User not set')
        }
    }

    static isUserHasCartAccess() {
        const showCart = store.state.user.dccUserInfo?.data?.showCart
        return showCart ? showCart : false
    }
    static isUserHasCreateOrderAccess() {
        return !!store.state.user.dccUserInfo?.data?.baseRole?.includes(
            'createorder'
        )
    }
    static isUserHasMultiplePOAccess() {
        return !!store.state.user.dccUserInfo?.data?.baseRole?.includes(
            'multipo'
        )
    }
}
