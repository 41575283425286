import {
    CMP_LOADED,
    CMP_SHOW,
    CMP_CLICK,
    SEARCH_SUCCESS,
    SEARCH_FAILURE,
    CMP_HIDE
} from '../dow-platform/components/core/ACDLHandler'

export default {
    namespaced: true,
    state: {
        dataLayer: {}
    },
    getters: {
        dataLayer(state) {
            return (cmpId) => state.dataLayer[cmpId] || {}
        },
        dataLayerJSON(state, { dataLayer }) {
            return (cmpId) => JSON.stringify(dataLayer(cmpId))
        },
        items(state) {
            return (cmpId) =>
                Object.keys(state.dataLayer).filter(
                    (id) => state.dataLayer[id].parentId == cmpId
                )
        }
    },
    mutations: {
        update(state, components) {
            if (components) {
                Object.keys(components).forEach((id) => {
                    const dataLayerId = getSubcomponentId(id, components[id])
                    state.dataLayer[dataLayerId] = {
                        ...state.dataLayer[dataLayerId],
                        ...components[id]
                    }
                })
            }
        }
    },
    actions: {
        [CMP_SHOW]: { root: true, handler() {} },
        [CMP_CLICK]: { root: true, handler() {} },
        [CMP_HIDE]: { root: true, handler() {} },
        [CMP_LOADED]: {
            root: true,
            handler({ commit }) {
                commit('update', adobeDataLayer.getState().page)
            }
        },
        [SEARCH_SUCCESS]: { root: true, handler() {} },
        [SEARCH_FAILURE]: { root: true, handler() {} },
        updateDataLayer({ state, getters }, component) {
            let dataLayer = Object.keys(state.dataLayer).reduce(
                (dataLayer, cmpId) => {
                    if (cmpId in component)
                        dataLayer[cmpId] = {
                            ...getters.dataLayer(cmpId),
                            ...component[cmpId]
                        }
                    return dataLayer
                },
                {}
            )

            if (Object.keys(dataLayer).length)
                window.adobeDataLayer.push({ component: dataLayer })
        }
    }
}

function getSubcomponentId(id, { parentId, '@type': type }) {
    return id != parentId ? id : `${id}-${type.split('/').reverse()[0]}`
}
