import FilterDefaultValueHelper from './filter-default-value-helper'

export default class ContractDetailsDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            addresses: super.getDefaultAddress()
        }
    }

    static filterSearchOption(retArr, filterValues) {
        if (filterValues.attribute == 'purchaseOrderNo') {
            return retArr.filter((el) => 
                    el['contractName']?.toLowerCase().includes(filterValues.value.toLowerCase()))
        } else if (filterValues.attribute == 'contractNo') {
            return retArr.filter((el) => 
                el['contractNumber'].includes(filterValues.value))
        } else return null
    }
}