import FilterDefaultValueHelper from './filter-default-value-helper'

export default class AccountSummaryFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            dateRange: super.getDateRange(180),
            payerAddress: super.getDefaultPayerAddressId(),
            invoiceStatus: ['O', 'P']
        }
    }
}
