import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data: function () {
    return {
      displayDropdown: false
    };
  },
  components: {},
  methods: {
    toggleQuickLinks
  },
  mounted: function () {
    this.toggleQuickLinks();
  }
};
function toggleQuickLinks() {
  document.querySelector('.quick-links-dropdown-trigger:not(.fakeTrigger)').addEventListener('click', e => {
    e.stopPropagation();
    document.querySelector('.quick-links').classList.toggle('hidden');
  });
  let buttons = document.querySelectorAll('.quick-links .securedButton .quick_links_dropdown_link');
  buttons.forEach((button, index) => {
    button.addEventListener('click', e => {
      e.stopPropagation();
      document.querySelector('.quick-links').classList.toggle('hidden');
    });
    button.addEventListener('keydown', function (event) {
      const key = event.key;
      switch (key) {
        case 'ArrowUp':
          event.preventDefault();
          if (index - 1 >= 0) {
            buttons[index - 1].focus();
          } else {
            buttons[buttons.length - 1].focus();
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (index + 1 <= buttons.length - 1) {
            buttons[index + 1].focus();
          } else {
            buttons[0].focus();
          }
          break;
        case 'Tab':
          event.preventDefault();
          if (index + 1 <= buttons.length - 1) {
            buttons[index + 1].focus();
          } else {
            document.querySelector('.quick-links').classList.add('hidden');
            document.querySelector('.quick-links-dropdown-trigger').classList.remove('dd-open');
          }
          break;
      }
    });
  });
}