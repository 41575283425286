'use strict'

module.exports = (function () {
    var getBackToTopButton = function () {
        var ID = 'back-to-top-button'
        var existing = document.getElementById(ID)
        if (existing) {
            return existing
        }

        var txt = Granite.I18n.get('back.to.top')
        var a = document.createElement('a')
        a.href = '#back-to-top'
        a.id = ID
        a.classList.add(ID)
        a.innerHTML =
            '<svg id="arrow-up-circle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" svg> <circle cx="12" cy="12" r="10"> </circle> <polyline points="16 12 12 8 8 12"> </polyline> <line x1="12" y1="10" x2="12" y2="17" stroke="white" stroke-width="2"> <span>' +
            txt +
            '</span>'
        document.querySelector('body').appendChild(a)
        return a
    }

    var showOrHideButton = function (button, show) {
        if (show) {
            button.classList.add('visible')
        } else {
            button.classList.remove('visible')
        }
    }

    var init = function () {
        var button = getBackToTopButton()
        var globalHeader = document.querySelector('.header')
        if (!button || !globalHeader) {
            return
        }

        if ('IntersectionObserver' in window) {
            new IntersectionObserver((entries) => {
                Array.prototype.slice.call(entries).forEach(function (entry) {
                    showOrHideButton(button, entry.intersectionRatio <= 0)
                })
            }).observe(globalHeader)
        } else {
            window.addEventListener('scroll', function () {
                var headerRect = globalHeader.getBoundingClientRect()
                var isInViewport =
                    headerRect.top >= 0 &&
                    headerRect.left >= 0 &&
                    headerRect.bottom <=
                        (window.innerHeight ||
                            document.documentElement.clientHeight) &&
                    headerRect.right <=
                        (window.innerWidth ||
                            document.documentElement.clientWidth)
                showOrHideButton(button, !isInViewport)
            })
        }

        button.addEventListener('click', (e) => {
            e.preventDefault()
            document.body.scrollIntoView({ behavior: 'smooth' })
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        })
    }

    $(function () {
        init()
    })
})()
