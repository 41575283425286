import "core-js/modules/es.array.push.js";
import { buildSearchBox, buildResultList } from '@coveo/headless';
import { SearchIcon } from '../../commerce/blocks/svg-elements/search-icon-new';
import { CloseIcon } from '../../commerce/blocks/svg-elements/close-icon';
export default {
  data: function () {
    return {
      initEngine: true,
      controller: null,
      count: 0,
      inputValue: '',
      selectedSearchValue: '',
      resultController: null,
      qTimer: undefined,
      searchResults: [],
      searchResultspdpurl: [],
      searchResultIndex: -1
    };
  },
  components: {
    SearchIcon,
    CloseIcon
  },
  computed: {
    searchLabel() {
      return Granite.I18n.get('button.search.Title');
    },
    searchEngine() {
      return this.$store.state.search.engine;
    },
    state() {
      this.count;
      return this.controller?.state;
    },
    resultState() {
      this.count;
      return this.resultController?.state;
    }
  },
  methods: {
    updateText(inputValue) {
      let field = guideBridge.resolveNode('textbox-product');
      guideBridge.setProperty([field.somExpression], 'value', [inputValue]);
      if (inputValue != '') {
        $('#aem-forms-search-box-error')?.css('display', 'none');
      } else {
        $('#aem-forms-search-box-error')?.css('display', 'block');
      }
    },
    submit() {
      if (this.searchResultIndex != -1) {
        this.selectSearchValue(this.searchResults[this.searchResultIndex].rawValue);
      } else {
        this.controller.updateText(this.inputValue);
        this.controller.subscribe(() => this.count++);
        this.controller.submit();
      }
    },
    async selectSearchValue(selectedValue) {
      this.inputValue = selectedValue;
      this.selectedSearchValue = selectedValue;

      // Get the clicked result's data
      const selectedResult = this.searchResults.find(result => result.rawValue === selectedValue);
      if (selectedResult) {
        const productCode = selectedResult.productCode;
        await this.$store.dispatch('product/fetchProductAttributes', {
          productCodes: productCode,
          fields: 'categories,businesses,businessgroups'
        });
      }
    },
    clearSearchTerm() {
      this.inputValue = '';
      this.searchResults = [];
      this.searchResultspdpurl = [];
      this.controller.clear();
    },
    hideSearchResults(e) {
      setTimeout(() => {
        this.searchResults = [];
        this.searchResultIndex = -1;
      }, 300);
    },
    selectSearchResults(e) {
      if (e.which === 40) {
        this.$refs.searchboxInputRef.focus();
        if (this.searchResultIndex != -1) {
          var previousEl = document.getElementById('searchResult' + this.searchResultIndex);
          if (previousEl != null) {
            previousEl.classList.remove('suggestionHover');
            this.searchResultIndex++;
          }
        } else {
          this.searchResultIndex++;
        }
        var el = document.getElementById('searchResult' + this.searchResultIndex);
        if (el != null && this.searchResults[this.searchResultIndex]) {
          el.classList.add('suggestionHover');
        } else if (this.searchResults.length - 1 < this.searchResultIndex) {
          this.searchResultIndex = 0;
          el = document.getElementById('searchResult' + this.searchResultIndex);
          el.classList.add('suggestionHover');
        }
      } else if (e.which === 38) {
        this.$refs.searchboxInputRef.focus();
        if (this.searchResultIndex != -1) {
          var previousEl = document.getElementById('searchResult' + this.searchResultIndex);
          if (previousEl != null) {
            previousEl.classList.remove('suggestionHover');
            this.searchResultIndex--;
          }
        }
        var el = document.getElementById('searchResult' + this.searchResultIndex);
        if (el != null && this.searchResultIndex != -1) {
          el.classList.add('suggestionHover');
        } else if (this.searchResultIndex == -1) {
          this.searchResultIndex = this.searchResults.length - 1;
          el = document.getElementById('searchResult' + this.searchResultIndex);
          el.classList.add('suggestionHover');
        }
      }
    }
  },
  watch: {
    searchEngine(newVal, oldVal) {
      if (newVal) {
        this.resultController = buildResultList(this.searchEngine);
        this.resultController.subscribe(() => this.count++);
      }
    },
    resultState(newVal, oldVal) {
      if (oldVal && newVal.searchResponseId != oldVal.searchResponseId) {
        this.searchResults = [];
        this.resultState.results.forEach(element => {
          let searchResult = {
            highlightedValue: element.title,
            rawValue: element.title,
            pdpUrl: element.uri,
            productCode: element.raw?.permanentid
          };
          this.searchResults.push(searchResult);
          this.searchResultspdpurl = this.searchResults;
        });
      }
    },
    selectedSearchValue(newVal, oldVal) {
      this.hideSearchResults();
    },
    inputValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        clearTimeout(this.qTimer);
        this.qTimer = setTimeout(() => {
          this.updateText(this.inputValue);
        }, 300);
      }
    }
  },
  created() {
    if (this.initEngine) {
      var params = {
        hub: 'DowComMainSearch',
        customFilter: '@source=Product'
      };
      this.$store.dispatch('search/fetchSearchToken', params).then(result => {
        if (result === -1) {
          console.log('Error during building Coveo search engine: Failed to fetch search token');
        } else {
          this.$store.dispatch('search/updateEngine').then(function () {
            this.controller = buildSearchBox(this.searchEngine);
          }.bind(this));
        }
      });
    }
  }
};