import store from '../store'
import ApiClient from '@platform/plugins/AxiosPlugin'

import { getCIDFields } from '../utils/eloqua'
import { UserHelper } from '@/helpers/user-helper'
import DateHelper from '@/dow-platform/components/commerce/myAccount/helper/date-helper'
import crypto from 'crypto-js'

export default {
    namespaced: true,
    state: {
        requestInformation: {},
        sampleCartItems: [],
        sampleCartItemForConfirmation: [],
        recipientDetails: {
            additionalEmails: []
        },
        requestorDetails: {},
        requestDetails: {
            isOnBehalfOf: false
        },
        notification: false,
        summaryInformation: {},
        fileAttachment: {},
        activePage: 1,
        sampleProductFromSearch: {},
        displaySearchSampleOptions: false,
        sendRecipientEmail: false,
        isSubmitted: false,
        submitErrorMsg: null
    },
    getters: {
        getRequestInformation(state) {
            return state.requestInformation
        },
        getRecipientDetails(state) {
            return state.recipientDetails
        },
        getRequestorDetails(state) {
            return state.requestorDetails
        },
        getSampleCartItems: function (state) {
            return state.sampleCartItems
        },
        getSampleCartItemForConfirmation: function (state) {
            return state.sampleCartItemForConfirmation
        },
        getShowNotification: function (state) {
            return state.showNotification
        },
        getSummaryInformation: function (state) {
            return state.summaryInformation // details and email
        },
        getFileAttachment: function (state) {
            return state.fileAttachment
        },
        getNotification: function (state) {
            return state.notification
        },
        getActivePage: function (state) {
            return state.activePage
        },
        getIsSubmitted: function (state) {
            return state.isSubmitted
        },
        getRequestDetails: function (state) {
            return state.requestDetails
        },
        getSampleProductFromSearch: function (state) {
            return state.sampleProductFromSearch
        },
        getDisplaySearchSampleOptions: function (state) {
            return state.displaySearchSampleOptions
        }
    },
    mutations: {
        updateCartItems(state, pl) {
            state.sampleCartItems.splice(pl.indexItem, 1)
        },
        setCart: function (state, payload) {
            state.sampleCartItems = payload
        },
        setSampleCartItems: function (state, payload) {
            state.sampleCartItems = payload
        },
        setSampleCartItemForConfirmation: function (state, payload) {
            state.sampleCartItemForConfirmation = payload
        },
        setSummaryInformation: function (state, payload) {
            state.summaryInformation = payload
        },

        setFileAttachment: function (state, payload) {
            state.fileAttachment = payload
        },

        setSendRecipientEmail: function (state, payload) {
            state.sendRecipientEmail = payload
        },
        setIsOnBehalfOf: function (state, payload) {
            state.requestDetails.isOnBehalfOf = payload
        },
        setRegistrationData: function () {
            window.sessionStorage.setItem('firstName_mock', 'Dow')
            window.sessionStorage.setItem('lastName_mock', 'Chemicals')
            window.sessionStorage.setItem('companyName', 'DowChemicals')
            window.sessionStorage.setItem('email', 'ABC@dow.com')
            window.sessionStorage.setItem(
                'requestInformation',
                this.state.requestInformation
            )
            window.sessionStorage.setItem(
                'sampleCartItems',
                this.state.sampleCartItems
            )
            window.sessionStorage.setItem(
                'requestDetails',
                this.state.requestDetails
            )
        },
        addSampleCartItem: function (state, payload) {
            var _this = this
            var data = payload.data
            if(localStorage.getItem('sampleCart')){
                var sampleData = JSON.parse(localStorage.getItem('sampleCart'))
                payload.data.sampleCartGuid = sampleData?.sampleCartGuid
                payload.data.sampleCartCount = sampleData?.sampleCartCount || 0
            }
            let sampleCartHref =
                store.state.settings.sample_cart_servlet +
                '?' +
                new URLSearchParams(data).toString()
            ApiClient.post(sampleCartHref)
                .then(function (res) {
                    let isAuthenticated = store.state.user.isAuthenticated
                    var sampleData = JSON.parse(res.data)
                    if(!isAuthenticated) {
                        var sampleCartData = { sampleCartCount: parseInt(sampleData.sampleCartCount), sampleCartGuid: sampleData.sampleCartGuid, expires: Math.floor(new Date(new Date().getTime() + ( 3600 * 1000 * 24))) }
                        localStorage.setItem('sampleCart', JSON.stringify(sampleCartData))
                        _this.state.user.anonSampleCartCount = parseInt(sampleData.sampleCartCount)
                    } else {
                        _this.state.user.dccUserInfo.data.numberOfSampleCartItems =
                            parseInt(sampleData.sampleCartCount)
                    }
                    _this.dispatch('sampleCart/trackAddToCartSample', payload)
                })
                .catch(function (res) {})
        },

        updateSampleCartItem: function (state, payload) {
            var item = state.sampleCartItems.find(
                (i) => i.index == payload.index
            )

            var isMaterialToUpdate =
                item?.materialNumber != undefined &&
                payload.materialNumber != undefined &&
                item?.materialNumber == payload.materialNumber

            var isTradeProductToUpdate =
                item?.tradeProductNumber != undefined &&
                payload.tradeProductNumber != undefined &&
                item?.tradeProductNumber == payload.tradeProductNumber

            if (isMaterialToUpdate || isTradeProductToUpdate) {
                state.sampleCartItems[payload.index] = payload
            }
        },

        setRecipientDetails(state, payload) {
            state.recipientDetails = payload
        },

        setRequestorDetails(state, payload) {
            state.requestorDetails = payload
        },

        setRequestInformation(state, payload) {
            state.requestInformation = payload
        },

        setRequestDetails(state, payload) {
            state.requestDetails = payload
        },
        setRequestConsumptionValue(state, payload) {
            state.requestDetails.consumptionValue = payload
        },
        setRequestConsumptionUnit(state, payload) {
            state.requestDetails.consumptionUnit = payload
        },
        setNotification(state, payload) {
            state.notification = payload
        },
        setActivepage(state, payload) {
            state.activePage = payload
        },
        setIsSubmitted(state, payload) {
            state.isSubmitted = payload
        },
        setSampleProductFromSearch(state, payload) {
            state.sampleProductFromSearch = payload
        },
        setDisplaySearchSampleOptions(state, payload) {
            state.displaySearchSampleOptions = payload
        },
        setErrorMsg(state, payload) {
            state.submitErrorMsg = payload
        }
    },
    actions: {
        async trackAddToCartSample({ rootState, rootGetters }, payload) {
            const fetchPayload = {
                fields: 'categories,businesses,businessgroups,marketingStatus'
            }

            let productCode
            let productCodeType
            
            if (payload.data.material) {
                productCodeType = 'gmid'
                productCode = payload.data.material
                fetchPayload.materialCodes = payload.data.material
            } else if (payload.data.tradeProduct) {
                productCode = payload.data.tradeProduct
                productCodeType = 'tpid'
                fetchPayload.productCodes = rootState.sampleCart.sampleProductFromSearch.code ?? rootState.pdp.pdpData.productCode
            } else {
                productCodeType = 'unavailable'
                fetchPayload.productCodes = 'unavailable'
            }
            
            await store.dispatch('product/fetchProductAttributes', fetchPayload)

            let email = UserHelper.getUserData()?.email
            var sampleData = localStorage.getItem('sampleCart')
            var cartGuid = null;
            if(sampleData){
                var parsedData = JSON.parse(sampleData);
                cartGuid = parsedData.sampleCartGuid
            }
            let sampleCartGuid = cartGuid ||
                email ? 'sampleCart-' + email : 'unavailable'
            let sampleCartGuidHashed = crypto.SHA256(sampleCartGuid).toString(crypto.enc.Hex)

            let addMethod
            if (
                rootState.page.pageInfo.pageName == 'search' ||
                localStorage.getItem('sampleCartAddMethod') == 'sample-search'
            ) {
                addMethod = 'sample-search'
            } else if (
                document.querySelector(
                    '[data-cmp-is="cmp-curatedProductSearch"]'
                )
            ) {
                addMethod = 'sample-curated'
            } else {
                addMethod = 'sample-pdp'
            }

            const product = [
                {
                    SKU: productCode,
                    sampleCartGuid: sampleCartGuidHashed,
                    sampleCartAddTimestamp: DateHelper.getEpochSeconds(),
                    productAddMethod: addMethod,
                    productCategories:
                        rootGetters['product/getCategories'][productCode] || [],
                    business: rootGetters['product/getBusinesses'][productCode] || [],
                    businessGroup:
                        rootGetters['product/getBusinessGroups'][productCode] || [],
                    marketingStatus: rootState.product.productAttributes[0].marketingStatus
                }
            ]

            const sample = {
                market: payload.selectedMarket.text,
                subMarket: payload.selectedSubmarket.text,
                application: payload.selectedApplication.text
            }

            this.dispatch('pdp/addToSampleCartTracking', {
                step: 'step1',
                products: product,
                sample: sample,
                codeType: productCodeType
            })
            localStorage.removeItem('sampleCartAddMethod')
        },
        async trackCompleteSample({ rootState, rootGetters }, payload) {
            const materialCodes = payload
                .map(item => item.isMaterial && item.materialNumber)
                .join(',');
        
            const productCodes = rootState.sampleCart.sampleCartItems
                .map(product => product.webProductID)
                .join(',');
        
            let fetchPayload = {
                fields: 'businesses,businessgroups,categories,marketingStatus',
            };
        
            if (materialCodes != 'false') {
                fetchPayload.materialCodes = materialCodes;
            }
            
            if (productCodes) {
                fetchPayload.productCodes = productCodes;
            }
        
            if (fetchPayload.productCodes || fetchPayload.materialCodes) {
                await store.dispatch(
                    'product/fetchProductAttributes',
                    fetchPayload,
                    { root: true }
                );
            }

            let email = UserHelper.getUserData()?.email
            let sampleCartGuid =
                sessionStorage.getItem('sampleCartGuid') || email
                    ? 'sampleCart-' + email
                    : 'unavailable'
            let sampleCartGuidHashed = crypto
                .SHA256(sampleCartGuid)
                .toString(crypto.enc.Hex)

            let epochSeconds = DateHelper.getEpochSeconds()
            let products = []
            for (const product of payload) {
                let productId = product.isMaterial && product.materialNumber ||
                    product.isTradeProduct && product.tradeProductNumber ||
                    product.webProductID
                
                let productAttribute = rootState.product.productAttributes.find(pa => pa.productCode === product.webProductID);
                let marketingStatus = productAttribute?.marketingStatus ?? '';

                products.push({
                    SKU: productId,
                    sampleCartGuid: sampleCartGuidHashed,
                    sampleCartCompleteTimestamp: epochSeconds,
                    productCategories:
                        rootGetters['product/getCategories'][productId] || [],
                    business: rootGetters['product/getBusinesses'][productId] || [],
                    businessGroup:
                        rootGetters['product/getBusinessGroups'][productId] || [],
                    marketingStatus: marketingStatus
                })
            }

            window.adobeDataLayer.push({
                event: 'samplecart:complete',
                sampleCartGuid: sampleCartGuidHashed,
                sampleCartCompleteTimestamp: epochSeconds,
                productListItems: products
            })
        },
        removeSampleCartItem({ commit, dispatch, state, rootState }, payload) {
            var sampleGuid = null;
            var sampleCount = 0;
            if(localStorage.getItem('sampleCart')){
                var sampleData = JSON.parse(localStorage.getItem('sampleCart'))
                sampleGuid = sampleData?.sampleCartGuid
                sampleCount = sampleData?.sampleCartCount
            }
            var request = {
                material: payload.materialNumber,
                tradeProduct: payload.tradeProductNumber,
                market: payload.market,
                subMarket: payload.submarket,
                application: payload.application,
                indexItem: payload.indexItem,
                count: 2,
                sampleCartGuid: sampleGuid,
                sampleCartCount: sampleCount
            }

            var languageCode =
                rootState.browser.cookies['languageCode'] || 'en-us'
            let sampleCartHref =
                store.state.settings.sample_cart_remove_servlet +
                '?' +
                new URLSearchParams(request).toString()
            ApiClient.post(sampleCartHref)
                .then(function (res) {
                    commit('updateCartItems', request)
                    let isAuthenticated = store.state.user.isAuthenticated
                    var sampleData = JSON.parse(res.data)
                    if(!isAuthenticated) {
                       var sampleCartData = { sampleCartCount: parseInt(sampleData.sampleCartCount), sampleCartGuid: sampleData.sampleCartGuid, expires: Math.floor(new Date(new Date().getTime() + ( 3600 * 1000 * 24))) }
                       localStorage.setItem('sampleCart', JSON.stringify(sampleCartData))
                        store.state.user.anonSampleCartCount = parseInt(sampleData.sampleCartCount)
                    } else {
                        store.state.user.dccUserInfo.data.numberOfSampleCartItems =
                            parseInt(sampleData.sampleCartCount)
                    }
                    if (
                        state.sampleCartItems.length == 0 &&
                        !payload.lastItemRedirect
                    ) {
                        window.location.href =
                            '/' + languageCode + '/search.html'
                    }
                })
                .catch(function (res) {})
        },
        fetchSampleCartItems: function (context, payload) {
            var sampleData = localStorage.getItem('sampleCart')
            var cartGuid = null;
            if(sampleData){
                var parsedData = JSON.parse(sampleData);
                cartGuid = parsedData.sampleCartGuid
            }

            let sampleCartHref =
                store.state.settings.sample_cart_get_servlet +
                '?sampleCartGuid=' +
                cartGuid
            ApiClient.get(sampleCartHref)
                .then(function (res) {
                    var data = null
                    if (!res) {
                        context.commit('setCart', {})
                        return
                    } else if (res.data) {
                        data = JSON.parse(res.data)
                    }
                    var formattedData = data.entries.map((i) => {
                        let isTradeProduct = !!i.tradeProduct
                        return {
                            application: i.application,
                            favorite: i.product.isFavorite,
                            isTradeProduct: isTradeProduct,
                            isMaterial: !isTradeProduct,
                            market: i.market,
                            name: isTradeProduct
                                ? i.tradeProduct.name
                                : i.product.name,
                            materialNumber: i.product.code,
                            quantity: i.quantity,
                            submarket: i.subMarket,
                            tradeProductNumber: isTradeProduct
                                ? i.tradeProduct.code
                                : null,
                            tradeProductOverride: isTradeProduct
                                ? i.tradeProduct.tradeProductOverride
                                : null,
                            min: i.minQty,
                            mult: i.multQty,
                            max: i.maxQty,
                            intendedApplication: i.intendedApplication,
                            quantityUnit: i.quantityUnit,
                            pdpUrl: i.product.pdpUrl,
                            webProductID: i.product.baseProduct
                                ? i.product.baseProduct
                                : i.product.code
                        }
                    })
                    context.commit('setCart', formattedData)
                    console.log(res)
                })
                .catch(function (res) {
                    console.log(res)
                })
        },
        submitRequest(context, payload) {
            return new Promise((resolve, reject) => {
                var sendTo = 'CRM_CUST'
                var failMessage = null

                var formAnalyticsData = {
                    name: 'sample-request',
                    type: 'sample',
                    message: 'Invalid form',
                    step: ''
                }

                function Field(id, name, value) {
                    this.id = id
                    this.name = name || id
                    this.value = value || ''
                    this.sendTo = sendTo
                }

                var request = {
                    type: 'CIG',
                    form: [],
                    userInfo: [],
                    sendTo: sendTo,
                    attachments: [],
                    subject: {},
                    leadScoring: [],
                    recaptchaResponse: payload.recaptchaResponse,
                    recaptcha3Response: payload.recaptcha3Response,
                    productInfo: []
                }

                this.state.sampleCart.sampleCartItems.forEach(function (item) {
                    request.productInfo.push(
                        new Field(
                            item.isMaterial
                                ? item.materialNumber
                                : item.tradeProductNumber,
                            item.isMaterial ? 'material' : 'tradeProduct'
                        )
                    )
                })

                request.userInfo.push(
                    new Field(
                        'sLName',
                        Granite.I18n.get('last.name.Title'),
                        this.state.sampleCart.recipientDetails.lastName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sFName',
                        Granite.I18n.get('first.name.Title'),
                        this.state.sampleCart.recipientDetails.firstName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sEmail',
                        Granite.I18n.get('email.Title'),
                        this.state.sampleCart.recipientDetails.emailAddress
                            ? this.state.sampleCart.recipientDetails
                                  .emailAddress
                            : this.state.sampleCart.summaryInformation?.email
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sPhone',
                        Granite.I18n.get('phone'),
                        this.state.sampleCart.recipientDetails.phone
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sCompany',
                        Granite.I18n.get('company.title'),
                        this.state.sampleCart.recipientDetails.companyName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sCountry',
                        Granite.I18n.get('country.Title'),
                        this.state.sampleCart.recipientDetails.country.code
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sAddress',
                        Granite.I18n.get('address.Title'),
                        this.state.sampleCart.recipientDetails.address1
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sAddress2',
                        Granite.I18n.get('address.Title') +
                            ' ' +
                            Granite.I18n.get('line.found.results') +
                            ' 2',
                        this.state.sampleCart.recipientDetails.streetName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sCity',
                        Granite.I18n.get('city.Title'),
                        this.state.sampleCart.recipientDetails.city
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sProvince',
                        Granite.I18n.get('state.county.province'),
                        this.state.sampleCart.recipientDetails.region
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sPostal',
                        Granite.I18n.get('zip.postal.code'),
                        this.state.sampleCart.recipientDetails.postalCode
                    )
                )
                request.userInfo.push(
                    new Field(
                        'sEmailOptIn',
                        Granite.I18n.get(
                            'text.myPreferences.emailOptInToEmail'
                        ),
                        this.state.sampleCart.requestDetails.emailOptIn
                    )
                )

                request.userInfo.push(
                    new Field(
                        'Requestor_first_name',
                        Granite.I18n.get('first.name.Title'),
                        this.state.sampleCart.requestorDetails.firstName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'Requestor_last_name',
                        Granite.I18n.get('last.name.Title'),
                        this.state.sampleCart.requestorDetails.lastName
                    )
                )
                request.userInfo.push(
                    new Field('ecid', 'ECID', store.state.user.ecid)
                )
                if (this.state.sampleCart.requestDetails.isOnBehalfOf) {
                    request.userInfo.push(
                        new Field(
                            'Requestor_email',
                            Granite.I18n.get('email.Title'),
                            this.state.sampleCart.requestorDetails.emailAddress
                                ? this.state.sampleCart.requestorDetails
                                      .emailAddress
                                : this.state.sampleCart.summaryInformation
                                      ?.email
                        )
                    )
                } else {
                    request.userInfo.push(
                        new Field(
                            'Requestor_email',
                            Granite.I18n.get('email.Title'),
                            this.state.sampleCart.requestorDetails?.emailAddress
                        )
                    )
                }
                request.userInfo.push(
                    new Field(
                        'Requestor_company',
                        Granite.I18n.get('company.title'),
                        this.state.sampleCart.requestorDetails.companyName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'Requestor_address',
                        Granite.I18n.get('address.Title'),
                        this.state.sampleCart.requestorDetails.adress1
                    )
                )
                request.userInfo.push(
                    new Field(
                        'Requestor_address2',
                        Granite.I18n.get('address.Title') +
                            ' ' +
                            Granite.I18n.get('line.found.results') +
                            ' 2',
                        this.state.sampleCart.requestorDetails.streetName
                    )
                )
                request.userInfo.push(
                    new Field(
                        'Requestor_city',
                        Granite.I18n.get('city.Title'),
                        this.state.sampleCart.requestorDetails.city
                    )
                )
                request.userInfo.push(
                    new Field(
                        'Requestor_postal_code',
                        Granite.I18n.get('zip.postal.code'),
                        this.state.sampleCart.requestorDetails.postalCode
                    )
                )
                if (this.state.sampleCart.requestorDetails.country) {
                    request.userInfo.push(
                        new Field(
                            'Requestor_country',
                            Granite.I18n.get('country.Title'),
                            this.state.sampleCart.requestorDetails.country.code
                        )
                    )
                }

                var counter = 1
                var firstProdKey = null

                for (var i in this.state.sampleCart.sampleCartItems) {
                    var productPrefix = 'Product' + counter
                    var requestPrefix = 'Request1'
                    var prPrefix = productPrefix + '_' + requestPrefix
                    var key =
                        this.state.sampleCart.sampleCartItems[i].isMaterial ==
                        true
                            ? this.state.sampleCart.sampleCartItems[i]
                                  .materialNumber
                            : this.state.sampleCart.sampleCartItems[i]
                                  .tradeProductNumber
                    var data = this.state.leadScoring.items[key]

                    request.form.push(
                        new Field(
                            prPrefix + '_lead_market_code',
                            'Market',
                            data.selectedMarket.value
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_lead_market',
                            'Market',
                            data.selectedMarket.text
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_lead_sub_market_code',
                            'Sub Market',
                            data.selectedSubmarket.value
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_lead_sub_market',
                            'Sub Market',
                            data.selectedSubmarket.text
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_lead_application_code',
                            'Application',
                            data.selectedApplication.value
                        )
                    )
                    var businessAlignment =
                        data.selectedApplication.businessAlignment
                    if (!businessAlignment || businessAlignment === '') {
                        businessAlignment =
                            data.selectedSubmarket.businessAlignment
                    }
                    if (!businessAlignment || businessAlignment === '') {
                        businessAlignment =
                            data.selectedMarket.businessAlignment
                    }
                    request.form.push(
                        new Field(
                            prPrefix + '_businessAlignment',
                            'Business Alignment',
                            businessAlignment
                        )
                    )
                    if (data.selectedApplication.value == 'CANT_FIND_APP')
                        request.form.push(
                            new Field(
                                prPrefix + '_lead_application',
                                'Application',
                                data.selectedApplication.text.concat(
                                    '-',
                                    this.state.sampleCart.sampleCartItems[i]
                                        .intendedApplication
                                )
                            )
                        )
                    else
                        request.form.push(
                            new Field(
                                prPrefix + '_lead_application',
                                'Application',
                                data.selectedApplication.text
                            )
                        )

                    var product = this.state.sampleCart.sampleCartItems.find(
                        (i) =>
                            (i.isTradeProduct && i.tradeProductNumber == key) ||
                            (i.isMaterial && i.materialNumber == key)
                    )

                    if (product) {
                        request.form.push(
                            new Field(
                                productPrefix + '_sProduct',
                                'Product Name',
                                product.name
                            )
                        )
                        request.form.push(
                            new Field(
                                prPrefix + '_product-unit',
                                Granite.I18n.get('cig.sample.quantity.'),
                                product.quantity + ' ' + product.quantityUnit
                            )
                        )

                        if (product.isMaterial) {
                            request.form.push(
                                new Field(
                                    productPrefix + '_productGmid',
                                    Granite.I18n.get('cig.product.gmid'),
                                    product.materialNumber
                                )
                            )
                        } else {
                            request.form.push(
                                new Field(
                                    productPrefix + '_tradeProduct',
                                    Granite.I18n.get(
                                        'cig.product.tradeProduct'
                                    ),
                                    product.tradeProductNumber
                                )
                            )
                            request.form.push(
                                new Field(
                                    productPrefix + '_tradeProductOverride',
                                    'Trade Product Override',
                                    product.tradeProductOverride ? 'Yes' : 'No'
                                )
                            )
                        }

                        if (!firstProdKey) {
                            request.subject = new Field(
                                'subject',
                                'Subject',
                                'EXT-' +
                                    this.state.sampleCart.recipientDetails
                                        .country.code +
                                    '-{{loc}}-' +
                                    product.name +
                                    '-SMP'
                            )
                            firstProdKey = key
                        }
                    }

                    request.form.push(
                        new Field(
                            prPrefix + '_request-type',
                            'Request Type',
                            'Sample'
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-priority',
                            Granite.I18n.get('cig.sample.priority'),
                            this.state.sampleCart.requestInformation.priority
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-details',
                            Granite.I18n.get('cig.request.details'),
                            this.state.sampleCart.summaryInformation.details
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_product-volume',
                            Granite.I18n.get('cig.estimated.annual.volume'),
                            this.state.sampleCart.requestDetails
                                .consumptionValue +
                                ' ' +
                                this.state.sampleCart.requestDetails
                                    .consumptionUnit
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-courier-name',
                            Granite.I18n.get('cig.sample.courier.name'),
                            this.state.sampleCart.requestInformation.courier_name
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-courier-number',
                            Granite.I18n.get('cig.sample.courier.number'),
                            this.state.sampleCart.requestInformation.courier_number
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-delivery-date',
                            Granite.I18n.get('requested.delivery.date'),
                            this.state.sampleCart.requestInformation.requested_delivery_date
                        )
                    )
                    request.form.push(
                        new Field(
                            prPrefix + '_sample-vat',
                            Granite.I18n.get('cig.sample.vat.number'),
                            this.state.sampleCart.requestDetails.euVatNumber
                        )
                    )
                    counter += 1
                }

                request.form.push(
                    new Field(
                        'Fulfillment_Communications_Requestor',
                        'Fulfillment Communications Requestor',
                        'yes'
                    )
                )
                request.form.push(
                    new Field(
                        'Fulfillment_Communications_Recipient',
                        'Fulfillment Communications Recipient',
                        this.state.sampleCart.requestDetails.isOnBehalfOf &&
                        this.state.sampleCart.sendRecipientEmail
                            ? 'yes'
                            : 'no'
                    )
                )

                var additionalEmails =
                    this.state.sampleCart.recipientDetails.additionalEmails
                var additionalEmailValues = additionalEmails
                    .map((email) => email.value)
                    .join(',')
                request.form.push(
                    new Field(
                        'Fulfillment_Communications_Additional',
                        'Fulfillment_Communications_Additional',
                        additionalEmails?.length > 0
                            ? additionalEmailValues
                            : ''
                    )
                )
                var searchParams = new URLSearchParams(document.location.search)
                var fromCrop = searchParams.has(
                    'from',
                    'crop-solver/formulation-finder'
                )
                var referrer = Boolean(document.referrer)
                    ? document.referrer
                    : document.location.href
                let cidObjects = getCIDFields(fromCrop)
                request.form.push(
                    new Field('UrlReferrer', 'Url Referrer', referrer)
                )
                request.form.push(
                    new Field('Formname', 'Form Name', 'SampleCart')
                )
                request.form.push(
                    new Field('DescriptiveFormname', 'Descriptive Form Name', 'SampleCart')
                )
                request.form.push(
                    new Field('refUrl', 'Reference URL', cidObjects.refUrl)
                )
                request.form.push(new Field('cid', 'CID', cidObjects.cid))
                request.form.push(
                    new Field('crmid', 'CRM ID', cidObjects.crmid)
                )
                request.form.push(
                    new Field('channel', 'Channel', cidObjects.channel)
                )
                request.form.push(
                    new Field('source', 'Source', cidObjects.source)
                )
                request.form.push(new Field('sGroup', 'sGroup', ''))

                request.leadScoring.push(
                    new Field('Formname', 'Form Name', 'SampleCart')
                )
                request.leadScoring.push(
                    new Field(
                        'UrlReferrer',
                        'Url Referrer',
                        window.location.href
                    )
                )
                request.leadScoring.push(
                    new Field(
                        'lead_score_id',
                        this.state.leadScoring.questions.name,
                        this.state.leadScoring.questions.crm_scorecard_id
                    )
                )
                request.leadScoring.push(
                    new Field(
                        'Requestor_company',
                        Granite.I18n.get('company.title'),
                        this.state.sampleCart.requestDetails.company
                    )
                )

                if (firstProdKey) {
                    var data = this.state.leadScoring.items[key]
                    request.leadScoring.push(
                        new Field(
                            'lead_market_code',
                            'Market',
                            data.selectedMarket.value
                        )
                    )
                    request.leadScoring.push(
                        new Field(
                            'lead_market',
                            'Market',
                            data.selectedMarket.text
                        )
                    )
                    request.leadScoring.push(
                        new Field(
                            'lead_sub_market_code',
                            'Sub Market',
                            data.selectedSubmarket.value
                        )
                    )
                    request.leadScoring.push(
                        new Field(
                            'lead_sub_market',
                            'Sub Market',
                            data.selectedSubmarket.text
                        )
                    )
                    request.leadScoring.push(
                        new Field(
                            'lead_application_code',
                            'Application',
                            data.selectedApplication.value
                        )
                    )
                    if (data.selectedApplication.value == 'CANT_FIND_APP') {
                        request.leadScoring.push(
                            new Field(
                                'lead_application',
                                'Application',
                                data.selectedApplication.text.concat(
                                    '-',
                                    this.state.sampleCart.sampleCartItems[0]
                                        .intendedApplication
                                )
                            )
                        )
                    } else {
                        request.leadScoring.push(
                            new Field(
                                'lead_application',
                                'Application',
                                data.selectedApplication.text
                            )
                        )
                    }
                }

                counter = 1
                if (this.state.leadScoring.questions.questions) {
                    for (var q of this.state.leadScoring.questions.questions) {
                        var answer = this.state.sampleCart.requestDetails[q.uid]
                        var textCode = answer.value.split('|')
                        request.leadScoring.push(
                            new Field('Q_' + counter, answer.name, answer.name)
                        )
                        request.leadScoring.push(
                            new Field(
                                'Q_' + counter + '_ANSWER',
                                answer.name,
                                textCode[0]
                            )
                        )
                        request.leadScoring.push(
                            new Field(
                                'Q_' + counter + '_ANSWER_CODE',
                                answer.name,
                                textCode[1]
                            )
                        )
                        counter += 1
                    }
                }

                request.form = JSON.stringify(request.form)
                request.userInfo = JSON.stringify(request.userInfo)
                request.subject = JSON.stringify(request.subject)
                request.leadScoring = JSON.stringify(request.leadScoring)
                request.productInfo = JSON.stringify(request.productInfo)

                var attachment = this.state.sampleCart.fileAttachment
                var fileContents = new Promise(function (resolve) {
                    resolve(false)
                })

                if (attachment.name) {
                    fileContents = new Promise(function (resolve, reject) {
                        var reader = new FileReader()
                        reader.readAsDataURL(attachment)
                        reader.onload = function () {
                            resolve({
                                fileName: attachment.name,
                                mimeType: attachment.type,
                                fileData: reader.result.split(',')[1],
                                sendTo: 'CRM_CUST'
                            })
                        }.bind(this)
                        reader.onerror = function () {
                            reject(reader.error)
                        }
                    })
                }

                var self = this;

                fileContents.then(function (attachment) {
                    if (attachment)
                        request.attachments = JSON.stringify([attachment])
                    else request.attachments = null

                    Object.keys(request).forEach((key) => {
                        if (!request[key]) delete request[key]
                    })

                    return ApiClient.post(
                        context.rootState.settings.contact_us,
                        request,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
                        .then(function (response) {
                            context.dispatch('clearSampleCart')
                            resolve()
                        })
                        .catch(function (response) {
                            if (response.message === 'score_fail') {
                                reject()
                            }

                            var errorResponse = response.response

                            if (
                                errorResponse != null &&
                                errorResponse.data['invalidFields'] != null
                            ) {
                                var fields = errorResponse.data['invalidFields']
                                failMessage =
                                    'The following fields are too long and must be fixed: ' +
                                    fields.join(', ')
                            } else {
                                try {
                                    failMessage = Granite.I18n.get(
                                        JSON.parse(data.responseText)
                                    )
                                } catch (e) {
                                    // If error msg hasn't been set (in case of server error), use this one.
                                    failMessage = Granite.I18n.get(
                                        'esb.error.message.display'
                                    )
                                }
                            }
                            context.commit('setErrorMsg', failMessage)
                        })
                })
            })
        },
        fetchSampleOptionsFromSearch(context, payload) {
            context.commit('setSampleProductFromSearch', payload)
            context.commit('setDisplaySearchSampleOptions', true)
        },
        resetSampleOptionsFromSearch(context, payload) {
            context.commit('setSampleProductFromSearch', {})
            context.commit('setDisplaySearchSampleOptions', false)
        },
        clearSampleCart({ state, rootState, commit, dispatch }) {
            if (!state.sampleCartItems.length) return false
            commit(
                'setSampleCartItemForConfirmation',
                JSON.parse(JSON.stringify(state.sampleCartItems))
            )
            var cartGuid = null;
            if(localStorage.getItem('sampleCart')){
                var sampleData = JSON.parse(localStorage.getItem('sampleCart'))
                cartGuid = sampleData?.sampleCartGuid
            }
            let sampleCartHref =
                store.state.settings.sample_cart_delete_cart_servlet + '?sampleCartGuid=' + cartGuid
            ApiClient.post(sampleCartHref)
                .then(function (res) {
                    let isAuthenticated = store.state.user.isAuthenticated
                    if (!isAuthenticated) {
                        commit('user/setAnonSampleCartCount', 0, { root: true })
                        localStorage.removeItem('sampleCart')
                    } else {
                        commit('user/setSampleCartCount', 0, { root: true })
                    }
                })
                .catch(function (res) {})
        },
        expireSampleCart({ state, rootState, commit, dispatch }) {
            let sampleCartHref =
                store.state.settings.sample_cart_delete_cart_servlet
            ApiClient.post(sampleCartHref).then(function (){
                commit('user/setAnonSampleCartCount', 0, { root: true })
                localStorage.removeItem('sampleCart')
            })
            .catch(function (res) {})
        }
    }
}
