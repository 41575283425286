import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import CoreComponent from '../../core/CoreComponent.vue';
import i18nHelper from '../../commerce/myAccount/helper/i18n-helper.js';
export default {
  extends: CoreComponent,
  data() {
    return {
      CFData: [],
      regions: [],
      countries: [],
      selectedRegion: '',
      selectedCountry: '',
      isSwiping: false,
      showTable: false
    };
  },
  mounted() {
    const cmpDataLayer = this.cmpEl.getAttribute('data-region-data');
    const decodedDataLayer = decodeURIComponent(cmpDataLayer);
    this.CFData = JSON.parse(decodedDataLayer);
    if (this.CFData && this.CFData.length > 0) {
      this.regions = [...new Set(this.CFData.map(item => item.region))];
      this.selectedRegion = this.CFData[0].region;
      this.countries = [...new Set(this.CFData.map(item => item.countriesList).flat())];
      this.selectedCountry = this.CFData[0].countriesList[0];
    }
    const phoneContainer = document.querySelector('.phone-contact__general-info');
    if (!phoneContainer) return;
    const phoneElements = document.querySelectorAll('p');
    phoneElements.forEach(element => {
      if (!element.textContent) return;
      const phoneNumberMatch = element.textContent.match(/\+\d{1,2}\s\d{3}-\d{3}-\d{4}/);
      if (!phoneNumberMatch) return;
      const phoneNumber = phoneNumberMatch[0];

      // Create the link element with the tel: protocol
      const link = document.createElement('a');
      link.href = `tel:${phoneNumber.replace(/\s/g, '')}`;
      link.textContent = phoneNumber;

      // Replace the phone number text with the link element
      element.innerHTML = element.innerHTML.replace(phoneNumber, link.outerHTML);
    });
  },
  computed: {
    filteredCountries() {
      return [...new Set(this.CFData.filter(item => item.region === this.selectedRegion).map(item => item.countriesList).flat())];
    },
    filteredPhoneNumbers() {
      return this.CFData.filter(item => item.region === this.selectedRegion && item.countriesList.includes(this.selectedCountry));
    }
  },
  watch: {
    selectedRegion: function (newRegion) {
      // Update the selectedCountry based on the new selectedRegion
      if (this.CFData.length > 0) {
        const filteredCountriesForRegion = [...new Set(this.CFData.filter(item => item.region === newRegion).map(item => item.countriesList).flat())];
        if (filteredCountriesForRegion.length > 0) {
          this.selectedCountry = filteredCountriesForRegion[0];
        } else {
          this.selectedCountry = '';
        }
      } else {
        this.selectedCountry = '';
      }
    }
  },
  methods: {
    getLabel: function (key) {
      return i18nHelper.getI18nMessage(key);
    }
  }
};