import axios from 'axios'

export function getBrowserCookies() {
    return document.cookie.split(';').reduce((cookies, cookie) => {

        var splitIndex = cookie.trim().indexOf('=')

        const key = cookie.trim().slice(0,splitIndex)
        const val = cookie.trim().slice(splitIndex+1)

        try {
            cookies[key] = JSON.parse(decodeURIComponent(val))
        } catch (e) {
            cookies[key] = decodeURIComponent(val)
        }

        return cookies
    }, {})
}

export async function setBrowserCookie(key, value, attributes = {}) {
    var result

    let cookieServletHref = '/.dow.cookie.utils.servlet.json'
    await axios
        .get(cookieServletHref, {
            params: { cookieName: key }
        })
        .then((response) => {
            if (response.data) {
                let responseData = response.data.split('=')
                if (responseData.length > 1) {
                    if (responseData[1] === 'true') {
                        setCookie(key, value, attributes)
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function setCookie(key, value, attributes) {
    let expires = ''

    let maxAge =
        attributes?.maxAge !== undefined ? ';max-age=' + attributes.maxAge : ''

    if (attributes?.expires !== undefined) {
        try {
            let givenDate = new Date(attributes.expires)
            expires = ';expires=' + givenDate.toUTCString()
        } catch (e) {}
    }

    let path =
        attributes?.path !== undefined ? ';path=' + attributes.path : ';path=/'
    let sameSite =
        attributes?.sameSite !== undefined
            ? ';samesite=' + attributes.sameSite
            : ';samesite=strict'
    let domain =
        attributes?.domain !== undefined ? ';domain=' + attributes.domain : ''
    let secure =
        attributes?.secure !== undefined && attributes?.secure === false
            ? ''
            : ';secure'

    try {
        result = JSON.stringify(value)
        if (/^[\{\[]/.test(result)) {
            value = result
        }
    } catch (e) {}

    value = encodeURIComponent(String(value)).replace(
        /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
        decodeURIComponent
    )

    key = encodeURIComponent(String(key))
    key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
    key = key.replace(/[\(\)]/g, escape)

    let cookieContent = [
        key,
        '=',
        value,
        expires,
        maxAge,
        sameSite,
        path,
        domain,
        secure
    ].join('')
    document.cookie = cookieContent
}

export function getCookiebyName(name) {
    let cookie = document.cookie.split('; ').find((row) => row.startsWith(name))

    cookie = cookie?.substring(cookie.indexOf('=') + 1)

    return cookie
}

export function removeBrowserCookie(name) {
    document.cookie =
        name +
        '=; path=/; secure; sameSite=strict; expires=Thu, 01-Jan-70 00:00:01 GMT;'
}
