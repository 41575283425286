import I18nHelper from '../helper/i18n-helper'

export default class SupportCenterDTOService {
    static getCaseDTO(caseItem) {
        return {
            deliveryAddress:
                caseItem.shipToCity + ', ' + caseItem.shipToCountryCode,
            poNumber: caseItem.purchaseOrderNumber,
            orderNumber: caseItem.orderNo,
            caseNumber: caseItem.caseNumber,
            caseStatus:
                caseItem.status == '1' || caseItem.status == 'Resolved'
                    ? I18nHelper.getI18nMessage('closed.Title')
                    : I18nHelper.getI18nMessage('searchComponent.open'),
            dateSubmitted: caseItem.submittedDate
                ? new Date(caseItem.submittedDate)
                : null,
            materialName: caseItem.formattedMaterialName,
            createdBy: caseItem.formattedCreatedBy,
            contactEmail: caseItem.contactEmail,
            firstName: caseItem.contactFirstName,
            lastName: caseItem.contactLastName,
            complaintFlow: caseItem.complaintFlow,
            customerContact:
                caseItem.contactFirstName + ' ' + caseItem.contactLastName,
            deliveryStreetAddress: caseItem.shipToLine1,
            deliveryCity: caseItem.shipToCity,
            deliveryState: caseItem.shipToState,
            deliveryCountry: caseItem.shipToCountryCode,
            deliveryPostalCode: caseItem.shipToPostalCode,
            shipToCustomerId: caseItem.shipToCustomerId,
            shipToCustomerName: caseItem.formattedShipToName,
            basicDataText: caseItem.basicText,
            customerMaterialDescription: caseItem.customerDescription
        }
    }

    static getCaseDetailsDTO(caseDetails) {
        return {
            basicText: caseDetails.basicText,
            caseType: caseDetails.formattedCaseType,
            caseDetail: caseDetails.formattedCaseDetail,
            caseStatus: caseDetails.caseStatus,
            poNumber: caseDetails.purchaseOrderNumber,
            orderNumber: caseDetails.orderNo,
            deliveryNumber: caseDetails.deliveryNumber,
            deliveryAddress:
                caseDetails.shipToCity + ', ' + caseDetails.shipToCountryCode,
            deliveryStreetAddress: caseDetails.shipToLine1,
            deliveryCity: caseDetails.shipToCity,
            deliveryState: caseDetails.shipToState,
            deliveryPostalCode: caseDetails.shipToPostalCode,
            deliveryCountry: caseDetails.shipToCountryCode,
            shipToCustomerId: caseDetails.shipToCustomerId,
            shipToCustomerName: caseDetails.formattedShipToName,
            caseNumber: caseDetails.caseNumber,
            orderLineItemNumber: caseDetails.itemNo,
            createdBy: caseDetails.formattedCreatedBy,
            batchNumber: caseDetails.batchNumber,
            productName: caseDetails.productName,
            customerContact: caseDetails.dowCustomerContact,
            customerDescription: caseDetails.customerDescription,
            caseSubmissionNotes: caseDetails.description,
            caseCommunications: caseDetails.caseCommunications?.map(
                SupportCenterDTOService.getCaseCommunicationDTO
            ),
            caseSubmittedDate: caseDetails.caseSubmittedDate,
            caseAcknowledgementDate: caseDetails.caseAcknowledgementDate,
            caseResolutionPlannedDate: caseDetails.caseResolutionPlannedDate,
            caseResolutionExecutionDate:
                caseDetails.caseResolutionExecutionDate,
            caseInvestigationConcludedDate:
                caseDetails.caseInvestigationConcludedDate,
            caseConclusionDate: caseDetails.caseConclusionDate,
            complaintFlow: caseDetails.complaintFlow,
            fullComplaintActiveStage: caseDetails.fullComplaintActiveStage,
            fastComplaintActiveStage: caseDetails.fastComplaintActiveStage
        }
    }

    static getCaseCommunicationDTO(caseCommunication) {
        return {
            emailBody: caseCommunication.emailBody,
            emailSubject: caseCommunication.emailSubject,
            emailCreationDate: caseCommunication.emailCreationDate
                ? new Date(caseCommunication.emailCreationDate)
                : null,
            emailModifiedOnDate: caseCommunication.emailModifiedOnDate,
            attachmentCount: caseCommunication.attachmentCount,
            caseNumber: caseCommunication.caseNumber
        }
    }
}
