import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  data() {
    return {
      tabs: []
    };
  },
  mounted() {
    var tabs = this.cmpEl.querySelectorAll('.cmp-tab__smoothScroll');
    var tabPanels = this.cmpEl.querySelectorAll('.cmp-tabs__tabpanel');
    tabs.forEach(el => {
      el.addEventListener('click', async e => {
        e.preventDefault();
        tabPanels.forEach(panel => {
          panel.style.marginTop = tabHeader.offsetHeight + 10 + 'px';
        });
        await this.smoothScroll(el);
      });
    });
    var tabHeader = this.cmpEl.querySelector('.cmp-tabs__tabheader');
    var tabHeaderPosition = tabHeader.getBoundingClientRect().top;
    window.addEventListener('scroll', e => {
      let scrollPos = window.scrollY;
      if (scrollPos > tabHeaderPosition) {
        tabHeader.style.position = 'fixed';
        tabHeader.style.zIndex = '40';
      } else {
        tabHeader.style.position = 'sticky';
      }
    });
  },
  methods: {
    smoothScroll(el) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.cmpEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
          setTimeout(resolve, 1000);
        }, 0);
      });
    }
  }
};