import ApiClient from '@/plugins/AxiosPlugin'
const FAVORITE_TYPE_PRODUCTS = 'products'
const MAX_FAVORITE_ITERATIONS = 1000

export default {
    namespaced: true,
    state: {
        products: []
    },
    mutations: {
        setFavorite: function (state, payload) {
            state[payload.type] = payload.data
        }
    },
    actions: {
        fetchFavorite,
        setFavoriteItem,
        setFavoriteMaterial(context, payload) {
            const data = new FormData()
            data.append('materialCode', payload.data.materialCode)
            data.append('isFavorite', payload.data.isFavorite)
            return ApiClient.post(
                context.rootState.settings.material_favorite_servlet,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
        },
        loggedIn: { root: true, handler: loggedIn }
    },
    getters: {
        getFavorite: (state) => (payload) => {
            return state[payload.type] !== null ? state[payload.type] : []
        }
    }
}

function fetchFavorite(context, payload) {
    if (context.state.products.indexOf(payload) > -1) {
        return
    }

    if (payload.type === FAVORITE_TYPE_PRODUCTS) {
        return ApiClient.get(context.rootState.settings.favorite_search_servlet)
            .then(function (res) {
                var favorites = []
                if (res && res.data && res.data.products) {
                    for (
                        var j = 0;
                        j < res.data.products.length &&
                        j <= MAX_FAVORITE_ITERATIONS;
                        j++
                    ) {
                        favorites.push(res.data.products[j].code)
                    }
                }
                context.commit('setFavorite', {
                    type: payload.type,
                    data: favorites
                })
            })
            .catch(function (error) {
                console.log(error)
                context.commit('setFavorite', {
                    type: payload.type,
                    data: null
                })
            })
    }
}

function setFavoriteItem(context, payload) {
    return new Promise((resolve, reject) => {
        if (payload.type === FAVORITE_TYPE_PRODUCTS) {
            var data = new FormData()
            data.append('productCode', payload.data.itemId)
            data.append('isFavorite', payload.data.isFavorite)
            return ApiClient.post(
                context.rootState.settings.favorite_servlet,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(function (response) {
                    if (response.status == 200) {
                        if(payload.data.isFavorite) {
                            context.state.products.push(payload.data.itemId)
                        } else {
                            context.state.products = context.state.products.filter(function(product) {
                                return product != payload.data.itemId
                            })
                        }
                    }

                    resolve(true)
                })
                .catch(function (error) {
                    console.log(error)
                    reject(false)
                })
        }
    })
}

function loggedIn(context, payload) {
    fetchFavorite(context, { type: FAVORITE_TYPE_PRODUCTS })
}
