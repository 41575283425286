import MyProductsDTOService from './my-products-dto-service'
import MyPartNumbersFiltersDefaultValueHelper from './filter-helpers/my-part-numbers-filters-default-value-helper'

export const MyPartNumbersModuleHelper = {
    namespaced: true,
    state: {
        items: [],
        isFromCache: false,
        cartItems: [],
        item: {},
        itemsLoaded: false,
        filters: {},
        filterDefaultValueHelper: MyPartNumbersFiltersDefaultValueHelper,
        sortOptions: {
            attribute: 'partNumber',
            sortType: 'alphabetic',
            sortDirection: 'asc'
        },
        itemsUrl: 'get_part_numbers',
        itemUrl: '',
        requestType: 'POST',
        resourceName: 'cmir',
        resourceDto: MyProductsDTOService.getMyPartNumbersDTO,
        clientSideFilters: ['searchOption'],
        modeView: 'list',
        unfilteredResults: []
    }
}
