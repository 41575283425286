import CoreComponent from '../../core/CoreComponent.vue';
import Swiper, { Pagination, Keyboard } from 'swiper';
import 'swiper/css';
export default {
  extends: CoreComponent,
  mounted() {
    const bulletButtonAriaLabel = window.Granite.I18n.get('cardcontainer.carousel.pagination');
    const contentCarousel = this.cmpEl.querySelector('.cmp-contentcontainer-carousel__content');
    if (contentCarousel) {
      var contentSwiper = new Swiper(contentCarousel, {
        slidesPerView: 1,
        spaceBetween: 16,
        slideToClickedSlide: true,
        watchSlidesProgress: true,
        modules: [Keyboard, Pagination],
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        pagination: {
          el: this.cmpEl.querySelector('.cmp-contentcontainer--carousel-pagination'),
          clickable: true,
          bulletElement: 'button',
          renderBullet: function () {
            return `<button class="dot swiper-pagination-bullet" aria-label="${bulletButtonAriaLabel}"><span class="invisible">${bulletButtonAriaLabel}</span></button>`;
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2
          }
        }
      });
      const paginationContainers = this.cmpEl.querySelectorAll('.cmp-contentcontainer--carousel-pagination');
      paginationContainers.forEach(paginationContainer => {
        const paginationBullets = paginationContainer.querySelectorAll('.swiper-pagination-bullet');
        const bulletCount = paginationBullets.length;
        const nextButton = document.createElement('div');
        nextButton.classList.add('swiper-button-next');
        const prevButton = document.createElement('div');
        prevButton.classList.add('swiper-button-prev');
        prevButton.addEventListener('click', () => {
          contentSwiper.slidePrev();
        });
        nextButton.addEventListener('click', () => {
          contentSwiper.slideNext();
        });

        //check if closet parent swiper-button-prev is there
        var swiperInitializedElement = "";
        var prevButtonElement = "";
        var nextButtonElement = "";
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (paginationContainer.classList.contains('swiper-pagination-lock')) {
              prevButton.style.display = 'none';
              nextButton.style.display = 'none';
            } else {
              prevButton.style.display = 'block';
              nextButton.style.display = 'block';
              swiperInitializedElement = paginationContainer.closest('.swiper-initialized');
              prevButtonElement = swiperInitializedElement.querySelector('.swiper-button-prev');
              nextButtonElement = swiperInitializedElement.querySelector('.swiper-button-next');
              if (prevButtonElement == null && nextButtonElement == null) {
                paginationContainer.parentNode.insertBefore(prevButton, paginationContainer);
                paginationContainer.parentNode.insertBefore(nextButton, paginationContainer);
              }
            }
          });
        });
        const config = {
          attributes: true,
          childList: true,
          subtree: true
        };
        observer.observe(paginationContainer, config);
        window.addEventListener('resize', () => {
          //  MutationObserver
          observer.disconnect();
          observer.observe(paginationContainer, config);
          if (paginationContainer.classList.contains('swiper-pagination-lock')) {
            prevButton.style.display = 'none';
            nextButton.style.display = 'none';
          } else {
            prevButton.style.display = 'block';
            nextButton.style.display = 'block';
            swiperInitializedElement = paginationContainer.closest('.swiper-initialized');
            prevButtonElement = swiperInitializedElement.querySelector('.swiper-button-prev');
            nextButtonElement = swiperInitializedElement.querySelector('.swiper-button-next');
            if (prevButtonElement == null && nextButtonElement == null) {
              paginationContainer.parentNode.insertBefore(prevButton, paginationContainer);
              paginationContainer.parentNode.insertBefore(nextButton, paginationContainer);
            }
          }
        });
        if (bulletCount >= 2) {
          prevButton.style.display = 'display';
          nextButton.style.display = 'display';
          swiperInitializedElement = paginationContainer.closest('.swiper-initialized');
          prevButtonElement = swiperInitializedElement.querySelector('.swiper-button-prev');
          nextButtonElement = swiperInitializedElement.querySelector('.swiper-button-next');
          if (prevButtonElement == null && nextButtonElement == null) {
            paginationContainer.parentNode.insertBefore(prevButton, paginationContainer);
            paginationContainer.parentNode.insertBefore(nextButton, paginationContainer);
          }
        }
      });
    }
  }
};