import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import CoreComponent from '../../core/CoreComponent.vue';
import { urlBelongsToApprovedDomains } from '@/utils/url';
import ApiClient from '@/plugins/AxiosPlugin';
export default {
  extends: CoreComponent,
  mounted() {
    function extractMarketUrlParts(url) {
      // Check if the URL contains "/market/"
      if (url.includes('/market/')) {
        // Split the URL by slashes
        const parts = url.split('/');

        // Find the index of "market"
        const marketIndex = parts.indexOf('market');

        // Extract the parts after "market" and trim anything after like other query params or ".html"
        const mkt = parts[marketIndex + 1]?.split(/[?\.]/)[0];
        const sub = parts[marketIndex + 2]?.split(/[?\.]/)[0];
        const app = parts[marketIndex + 3]?.split(/[?\.]/)[0];
        return {
          mkt,
          sub,
          app
        };
      } else {
        // Return null if "/market/" is not in the URL
        return null;
      }
    }
    const contactUsButton = document.querySelector('.cmp-contactcards__button--contact');
    if (contactUsButton) {
      const contactAnchor = contactUsButton.querySelector('a');
      const marketUrlParts = extractMarketUrlParts(window.location.href);
      if (contactAnchor && marketUrlParts && marketUrlParts.mkt) {
        let queryParams = [];
        if (marketUrlParts.mkt) {
          queryParams.push('mkt=' + encodeURIComponent(marketUrlParts.mkt));
        }
        if (marketUrlParts.sub) {
          queryParams.push('sub=' + encodeURIComponent(marketUrlParts.sub));
        }
        if (marketUrlParts.app) {
          queryParams.push('app=' + encodeURIComponent(marketUrlParts.app));
        }
        let contactAnchorLink = contactAnchor.href;
        let contactAnchorURL = '';

        // Check if the current href already has other query parameters
        if (contactAnchorLink.includes('?')) {
          // Append with an ampersand
          contactAnchorLink += '&' + queryParams.join('&');
          contactAnchorURL = new URL(contactAnchorLink, window.location.origin);
          if (urlBelongsToApprovedDomains(contactAnchorURL.href, this.$store.state.settings.LOGIN_REDIRECT_DOMAINS)) {
            contactAnchor.href = contactAnchorURL.href;
          }
        } else {
          // Append with a question mark
          contactAnchorLink += '?' + queryParams.join('&');
          contactAnchorURL = new URL(contactAnchorLink, window.location.origin);
          if (urlBelongsToApprovedDomains(contactAnchorURL.href, this.$store.state.settings.LOGIN_REDIRECT_DOMAINS)) {
            contactAnchor.href = contactAnchorURL.href;
          }
        }
      }
    }

    //        hook subscribe/redirect logic
    const subscribeButton = document.querySelector('.cmp-contactcards__button--subscribe');
    if (subscribeButton) {
      const hrefLink = subscribeButton.children[0].href;
      subscribeButton.addEventListener('mouseover', () => {
        var userLoggedIn = this.$store.state.user.isAuthenticated;
        var subscribeButton = document.querySelector('.cmp-contactcards__button--subscribe');
        //                if logged in, disable right click event
        if (userLoggedIn) {
          subscribeButton.addEventListener('contextmenu', e => {
            e.preventDefault();
          });
        }
      });
      subscribeButton.addEventListener('click', e => {
        //                internal subscription
        var userLoggedIn = this.$store.state.user.isAuthenticated;
        //                for logged in visitor, submit subscribe request, redirect to 'My preference' page
        if (userLoggedIn) {
          var hrefLink = document.querySelector('.cmp-contactcards__button--subscribe').children[0].href;
          if (hrefLink && hrefLink.includes(this.$store.state.settings.SIMPLE_REGISTRATION)) {
            var interestList = this.getInterestList(hrefLink);
            if (interestList && interestList.length > 0) {
              //                            subscribe interests
              e.preventDefault();
              this.postEmailSubscription(interestList);
            }
          }
        }
      });
    }
  },
  methods: {
    openLoginModal: function () {
      document.getElementById('dc-login-modal')?.classList.add('active');
    },
    getInterestList: function (hrefLink) {
      var interestList = [];
      var interestString = hrefLink.split('?interest=')[1].split('&')[0];
      var interests = interestString.split(',');
      for (var i = 0; i < interests.length; i++) {
        if (interests[i].split('/').length > 1) {
          interestList.push(interests[i].split('/')[1]);
        }
      }
      return interestList;
    },
    async postEmailSubscription(interestList) {
      const emailSubscribe = '/.dow.bin.emailsignup.servlet.json';
      const formData = new FormData();
      formData.append('email', this.$store.state?.user?.dccUserInfo?.data?.email);
      formData.append('industry', interestList);
      formData.append('userid', this.$store.state?.user?.dccUserInfo?.data?.uid);
      try {
        const response = await ApiClient.post(emailSubscribe, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if (response.status === 200) {
          window.location.href = `/${this.$store.state.browser.languageCode}/my-account.html?#/my-preferences`;
        } else {
          $('#cmp-contactcards__button--subscribe--error').removeClass('hidden');
          console.log(response.data);
        }
      } catch (err) {
        $('#cmp-contactcards__button--subscribe--error').removeClass('hidden');
        console.log(err);
      }
    }
  }
};