import FilterDefaultValueHelper from './filter-default-value-helper'

export default class OrderFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            orderType: 'C',
            addresses: super.getDefaultAddress(),
            dateRange: super.getDateRange(30),
            orderStatus: []
        }
    }
}
