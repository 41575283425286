import MyProductsDTOService from './my-products-dto-service'
import FavoriteBuyingOptionsFiltersDefaultValueHelper from './filter-helpers/favorite-buying-options-filters-default-value-helper'

export const FavoriteBuyingOptionsModuleHelper = {
    namespaced: true,
    state: {
        cartItems: [],
        items: [],
        item: {},
        isFromCache: false,
        itemsLoaded: false,
        filters: {},
        filterDefaultValueHelper:
            FavoriteBuyingOptionsFiltersDefaultValueHelper,
        sortOptions: {
            attribute: 'materialName',
            sortType: 'alphabetic',
            sortDirection: 'asc'
        },
        itemsUrl: 'get_favorite_buying_options',
        itemUrl: '',
        requestType: 'POST',
        resourceName: 'favoriteBuyingOptions',
        resourceDto: MyProductsDTOService.getFavoriteBuyingOptionsDTO,
        clientSideFilters: ['searchOption'],
        modeView: 'list',
        unfilteredResults: []
    }
}
