export function trapFocusToModal(e, modal) {
    const focusableSelector =
        'button:not([disabled]):not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), a:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'

    let focusableContent

    if (modal) focusableContent = modal.querySelectorAll(focusableSelector)
    else {
        return
    }

    const firstFocusableElement = focusableContent[0]

    const lastFocusableElement = focusableContent[focusableContent.length - 1]

    let isTabPressed = e.key === 'Tab' || e.keyCode === 9

    if (!isTabPressed) {
        return
    }

    if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus()

            e.preventDefault()
        }
    } else {
        if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus()

            e.preventDefault()
        }
    }
}
