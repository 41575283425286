import MyProductsDTOService from './my-products-dto-service'
import FavoriteProductsFiltersDefaultValueHelper from './filter-helpers/favorite-products-filters-default-value-helper'

export const FavoriteProductsModuleHelper = {
    namespaced: true,
    state: {
        cartItems: [],
        items: [],
        item: {},
        itemsLoaded: false,
        filters: {},
        filterDefaultValueHelper: FavoriteProductsFiltersDefaultValueHelper,
        sortOptions: {
            attribute: 'productName',
            sortType: 'alphabetic',
            sortDirection: 'asc'
        },
        itemsUrl: 'get_favorite_products',
        itemUrl: '',
        requestType: 'POST',
        resourceName: 'products',
        resourceDto: MyProductsDTOService.getFavoriteProductsDTO,
        clientSideFilters: [],
        modeView: 'list'
    }
}
