import CoreComponent from '../../core/CoreComponent.vue';
const WebsiteUpdateSelector = 'div#my-resources-website-updates';
export default {
  extends: CoreComponent,
  mounted() {
    document.querySelector(WebsiteUpdateSelector).removeAttribute('hide-until-curated-ready');
    $(WebsiteUpdateSelector).detach().appendTo('[id*=myAccountMenu-]');
    let myAccountSubNavSelector = '.my-account-nav .subnav .right-content ';
    let quickLinkSelector = 'div#my-account-quickLink.quick-link-container';
    if (!document.querySelector(myAccountSubNavSelector + quickLinkSelector)) {
      $(quickLinkSelector).detach().appendTo(myAccountSubNavSelector);
      document.querySelector(quickLinkSelector).removeAttribute('hide-until-ready');
    }
  },
  unmounted() {
    document.querySelector(WebsiteUpdateSelector).setAttribute('hide-until-curated-ready', '');
  }
};