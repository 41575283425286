const ESC_MAP = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
}

export function escapeHtml(s, forAttribute) {
    return s.replace(forAttribute ? /[&<>'"]/g : /[<>]/g, function (c) {
        return ESC_MAP[c]
    })
}
