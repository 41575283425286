import FilterDefaultValueHelper from './filter-default-value-helper'
const featureFlags = require('../../../../../../store/feature-flag')

export default class DeliveryFiltersDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues
    static featureFlags = featureFlags

    static setDefaultValues() {
        this.defaultValues = {
            addresses: super.getDefaultAddress(),
            dateRange: super.getDateRange(30)
        }
    }

    static getDefaultDeliveryToDate() {
        //get configured default delivery toDate from FeatureFlag
        const defaultDeliveryToDate =
            DeliveryFiltersDefaultValueHelper.featureFlags.featureFlag.state
                .defaultDeliveryToDate
        return defaultDeliveryToDate
            ? new Date(defaultDeliveryToDate)
            : new Date()
    }
}
