import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import CoreComponent from '../../core/CoreComponent.vue';
export default {
  extends: CoreComponent,
  mounted() {
    const contactUsLinks = this.cmpEl.querySelectorAll('.cmp-utility-contact-us__card-links a');
    const lastQueryParam = window.location.search.split('?');
    if (lastQueryParam.length > 2) {
      window.location.search = lastQueryParam[1];
    }
    const queryParams = new URLSearchParams(window.location.search);
    const product = queryParams.get('product');
    const mkt = queryParams.get('mkt');
    const sub = queryParams.get('sub');
    const app = queryParams.get('app');
    const cid = queryParams.get('cid');
    const newQueryParams = [];
    if (product) {
      newQueryParams.push('product=' + product);
    }
    if (mkt) {
      newQueryParams.push('mkt=' + mkt);
    }
    if (sub) {
      newQueryParams.push('sub=' + sub);
    }
    if (app) {
      newQueryParams.push('app=' + app);
    }
    if (cid) {
      newQueryParams.push('cid=' + cid);
    }
    contactUsLinks.forEach(link => {
      // Check if the current href already has other query parameters
      if (link.href.includes('?')) {
        // Append with an ampersand
        link.href += '&' + newQueryParams.join('&');
      } else {
        // Append with a question mark
        link.href += '?' + newQueryParams.join('&');
      }
    });
  }
};