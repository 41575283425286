import { UserHelper } from '../../../../../../helpers/user-helper'
export default class FilterDefaultValueHelper {
    static defaultValues

    static getDefaultValue(attr) {
        if (!FilterDefaultValueHelper.defaultValues) {
            this.setDefaultValues()
        }
        return this.defaultValues[attr] || ''
    }

    static setDefaultValues() {
        this.defaultValues = {}
    }

    static getDateRange(selectedRange) {
        let dateRange
        const today = new Date()
        const prevDate = new Date(
            new Date().setDate(today.getDate() - selectedRange)
        )
        dateRange = [today, prevDate]
        return dateRange
    }

    static getDefaultAddress() {
        let soldTo = UserHelper.getUserData()?.selectedSoldToId || null
        let shipTo = UserHelper.getUserData()?.selectedShipToId || null

        let addressObject = {}
        if (soldTo && shipTo) {
            addressObject[soldTo] = { subAddresses: [+shipTo] }
        }

        return addressObject || {}
    }

    static getDefaultPayerAddressId() {
        const payerAddressId = UserHelper.getUserData()?.payerAddressId || null
        return +payerAddressId
    }
}
